import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Grid, CircularProgress, Box, Typography } from '@material-ui/core'

import { PaymentForm } from './PaymentForm'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { useStoreState, useStoreActions } from '../../store/store.hooks'
import { postNewRequest } from '../../services/request.service'
import { formatPrice, normalizeLanguage } from '../../util'
import { FEATURES, SPECIALIZATIONS } from '../../constants'

const useStyles = makeStyles((theme) => ({
  boxLabel: {
    color: '#6473AF',
    fontWeight: 600,
    fontSize: 15,
    textTransform: 'uppercase',
  },
  originalPrice: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  discountedPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#97D344',
  },
  oldPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#F01E5A',
    textDecoration: 'line-through',
    display: 'inline-block',
    marginRight: 20,
  },
  payment: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  headerContainer: {
    padding: '40px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  complaintsFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    position: 'relative',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      dispaly: 'felx',
      flexDirection: 'column',
    },

    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  complaintsFooterButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
    marginLeft: '3rem',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },

    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 280,
    },
  },
  loaderIcon: {
    margin: 5,
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  priceContainer: {
    position: 'absolute',
    textAlign: 'center',
    left: '10%',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      position: 'relative',
      left: 0,
    },
  },
}))

//
// Credit card data
//

// TODO implement credit card data fetch
// TODO create card data type
const creditCardMocks = [
  {
    id: 101,
    lastFourDigits: '0003',
    cardName: 'OTP private card',
  },
  {
    id: 102,
    lastFourDigits: '4741',
    cardName: 'K&H private card',
  },
]

// TODO fix after using real data
const getCardIdByIndex = (cardIndex: number) => {
  return creditCardMocks && creditCardMocks.length > 0
    ? creditCardMocks[cardIndex].id
    : -1
}

// TODO implement credit card edit function (fetch and redirect?)
const editCardData = (cardIndex: number) => {
  const cardId = getCardIdByIndex(cardIndex)
  console.log('cardIndex: ' + cardIndex, 'cardId: ' + cardId)
}

//
// Billing address data
//

// TODO implement billing address data fetch
// TODO create billing address type
const billingAddressMocks = [
  {
    id: 201,
    name: 'Otthon',
    fullAddress: '1234 Budapest, Andrássy Út 69.',
  },
  {
    id: 202,
    name: 'Melókahely',
    fullAddress: '1234 Budapest, Homeoffice utca 5.',
  },
]

const editBillingAddress = (addressIndex: number, addresses: any) => {
  const selectedAddress = addresses[addressIndex]
  navigate('/patient/billing-address', { state: { address: selectedAddress } })
}

type FormData = {
  tosCheckedPayment: boolean
}

const Payment = () => {
  const { i18n, t } = useTranslation('payment')
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>()

  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const initialCardIndex =
    creditCardMocks && creditCardMocks.length > 0 ? 0 : -1
  const [selectedCardIndex, setSelectedCardIndex] = useState(initialCardIndex)

  const initialAddressIndex =
    billingAddressMocks && billingAddressMocks.length > 0 ? 0 : -1
  const [selectedAddressIndex, setSelectedAddressIndex] =
    useState(initialAddressIndex)

  /* store state */
  const termsAndConditionsAccepted = useStoreState(
    (state) => state.request.termsAndConditionsAccepted
  )
  const payedAsOneOff = useStoreState((state) => state.request.payedAsOneOff)
  const discountedPrice = useStoreState((state) => state.coupon.discountedPrice)
  const couponCode = useStoreState((state) => state.coupon.couponCode)
  const cards = useStoreState((state) => state.profile.cards)
  const addresses = useStoreState(
    (state) => state.profile.profileContents.addresses
  )
  const specialization = useStoreState((state) => state.request.specialization)
  const {
    subscription: { featuresBySku },
  } = useStoreState((state) => state)

  const language = normalizeLanguage(i18n.language)
  const feature =
    specialization === SPECIALIZATIONS.PEDIATRICS
      ? FEATURES.ON_DEMAND_PEDIATRICIAN
      : language === 'hu'
      ? FEATURES.ON_DEMAND_MEDICAL_DOCTOR
      : FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL
  const featurePrice = feature && featuresBySku && featuresBySku[feature]
  const formattedPrice = featurePrice && featurePrice.formattedPrice

  /* store actions */
  const setNotification = useStoreActions(
    (actions) => actions.layout.setNotification
  )
  const layoutSetIsLoading = useStoreActions(
    (actions) => actions.layout.setIsLoading
  )
  const setRequestId = useStoreActions(
    (actions) => actions.request.setRequestId
  )
  const setPayedAsOneOff = useStoreActions(
    (actions) => actions.request.setPayedAsOneOff
  )
  const fetchCard = useStoreActions((actions) => actions.profile.fetchCard)
  const setCouponCode = useStoreActions(
    (actions) => actions.coupon.setCouponCode
  )
  const setDiscountedPrice = useStoreActions(
    (actions) => actions.coupon.setDiscountedPrice
  )

  const [hasFullDiscount, updateHasFullDiscount] = useState(false)
  const [isVoucherValidating, setIsVoucherValidating] = useState(false)

  const paymentSuccess = () => {
    const dataLayer = (window as any).dataLayer || []
    dataLayer.push({
      event: 'Fizetés',
      eventCategory: 'Fizetés',
      eventAction: 'Sikeres fizetés',
      eventLabel: 'Button',
    })

    navigate('/patient/successful-payment')
  }

  const paymentError = () => {
    setNotification({
      isOpen: true,
      type: 'error',
      title: t('error1Title'),
      description: t<string>('error1Description'),
    })
  }

  useEffect(() => {
    setPayedAsOneOff(false)
  }, [])

  // Since Simplepay / paymentgateway.hu is slow we need to handle if the user added the card but it's not showing yet for her
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    // "Success" must be capital case, otherwise it doesn't work with the mobile app
    const success = searchParams.get('Success')
    const oneOffPaymentSuccess = searchParams.get('oneOffPaymentSuccess')
    const prevCcCount = Number(searchParams.get('prevCcCount'))

    const isCardAdded = success === 'true' && prevCcCount === cards.length

    if (isCardAdded) {
      layoutSetIsLoading(true)
      const timeout = setTimeout(() => fetchCard({}), 2000)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      layoutSetIsLoading(false)
      if (!oneOffPaymentSuccess) {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )
      }
    }
  })

  useEffect(() => {
    const successfulPayment = window.location.search.includes(
      'oneOffPaymentSuccess=true'
    )
    const unsuccessfulPayment = window.location.search.includes(
      'oneOffPaymentSuccess=false'
    )
    if (successfulPayment) {
      setPayedAsOneOff(true)
      layoutSetIsLoading(true)
      paymentSuccess()
    } else if (unsuccessfulPayment) {
      navigate('/patient/payment')
      paymentError()
    }
  })

  useEffect(() => {
    setCouponCode(undefined)
    setDiscountedPrice(undefined)
  }, [setCouponCode, setDiscountedPrice])

  useEffect(() => {
    // If we have a valid coupon code applied, which guarantess a full 100%
    // discount, don't require a credit card or billing address validation

    const fullDiscountApplied = !!couponCode && discountedPrice === 0
    updateHasFullDiscount(fullDiscountApplied)
  }, [couponCode, discountedPrice])

  useEffect(() => {
    if (hasFullDiscount === true) {
      setPayedAsOneOff(false)
    }
  }, [hasFullDiscount, setPayedAsOneOff])

  const onSubmit = handleSubmit(async () => {
    if (!termsAndConditionsAccepted) return
    if (isLoading) return
    setIsLoading(true)

    if (!hasFullDiscount && addresses?.length === 0) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error2Title'),
        description: t<string>('error2Description'),
      })
      setIsLoading(false)
      return
    }
    try {
      const selectedCard = cards[selectedCardIndex]

      const request = await postNewRequest({
        payedAsOneOff: payedAsOneOff,
        language,
        paymentDeviceReferenceTransactionId:
          selectedCard?.referenceTransactionId,
        returnTo: 'payment',
      })

      setRequestId(request.requestId)
      localStorage.setItem('requestId', request.requestId.toString())

      if (payedAsOneOff) {
        navigate(request.url)
      } else {
        paymentSuccess()
      }
    } catch (error) {
      const title = !cards[0] ? t('error4Title') : t('error3Title')
      const description = !cards[0]
        ? t('error3Description')
        : t('error1Description')

      setIsLoading(false)
      setNotification({
        isOpen: true,
        type: 'error',
        title: title,
        description: description,
      })
    }
  })

  const validateForm = () => {
    if (!termsAndConditionsAccepted) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error4Title'),
        description: t<string>('error4Description'),
      })
    }
  }

  const handleVoucherValidating = (isVoucherValidating: boolean) => {
    setIsVoucherValidating(isVoucherValidating)
  }

  const creditCards = cards?.map((card) => {
    return {
      id: card.cardReferenceId,
      lastFourDigits: card.paymentDeviceNumber,
      cardName: card.cardType,
      referenceTransactionId: card.referenceTransactionId,
    }
  })

  const billingAddresses = addresses?.map((address) => {
    return {
      id: address.id,
      name: address.userGivenName,
      fullAddress: `${address.postalCode} ${address.city} ${address.streetAddress}`,
    }
  })

  const getPriceContent = () => {
    const formattedDiscountedPrice = formatPrice(
      discountedPrice,
      featurePrice?.currency
    )

    if (discountedPrice || discountedPrice === 0) {
      return (
        <Box display="flex">
          <Typography className={classes.oldPrice}>{formattedPrice}</Typography>
          <Typography className={classes.discountedPrice}>
            {formattedDiscountedPrice}
          </Typography>
        </Box>
      )
    }

    return (
      <Box>
        <Typography className={classes.originalPrice}>
          {formattedPrice}
        </Typography>
      </Box>
    )
  }

  return (
    <div className={classes.payment}>
      <NavigateBack />
      <Grid item xs={12} className={classes.headerContainer}>
        <Heading enableTrustLogo>{t<string>('heading')}</Heading>
      </Grid>
      <form onSubmit={onSubmit}>
        <PaymentForm
          control={control}
          creditCardList={creditCards}
          billingAddressList={billingAddresses}
          selectedCardIndex={selectedCardIndex}
          setSelectedCardIndex={setSelectedCardIndex}
          selectedAddressIndex={selectedAddressIndex}
          setSelectedAddressIndex={setSelectedAddressIndex}
          editBillingAddress={(addressIndex: number) =>
            editBillingAddress(addressIndex, addresses)
          }
          editCardData={(cardIndex: number) => editCardData(cardIndex)}
          disableBillingAddress={hasFullDiscount}
          disableCreditCards={hasFullDiscount}
          onVoucherValidating={handleVoucherValidating}
        />
        <Grid xs={12} container className={classes.complaintsFooter}>
          <Box className={classes.priceContainer}>
            <Typography className={classes.boxLabel}>
              {t<string>('payment')}
            </Typography>
            {getPriceContent()}
          </Box>
          <WhiteButton
            type={'submit'}
            className={classes.complaintsFooterButton}
            onClick={() => validateForm()}
            disabled={isVoucherValidating}
          >
            {isLoading ? (
              <CircularProgress
                className={classes.loaderIcon}
                color="inherit"
                size={24}
              />
            ) : (
              <div className={classes.buttonContent}>
                {t<string>('buttonText')}
                <ArrowForwardIosIcon />
              </div>
            )}
          </WhiteButton>
        </Grid>
      </form>
    </div>
  )
}

export { Payment }
