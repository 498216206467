/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { useTranslation } from 'react-i18next'
import { get } from '../../services/request'

const useStyles = makeStyles((theme) => ({
  articleBox: {
    padding: '0.2rem 0',
  },
  articleContainer: {
    padding: '0.3rem',
  },
  categoryHeader: {
    fontWeight: 'bold',
  },
  categoryList: {
    paddingLeft: '1rem',
  },
  categoryListItem: {
    border: '1px solid #f6f6f6',
    marginBottom: '0.1rem',
  },
  categoryListItemText: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  content: {
    whiteSpace: 'pre-line',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
  },
}))

const KnowledgeBase = (
  {
    /* Props come here */
  }
) => {
  const { i18n, t } = useTranslation('knowledge-base')
  const { language } = i18n
  const classes = useStyles()
  const [knowledgeBase, setKnowledgeBase] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const hasCategories =
    knowledgeBase &&
    Array.isArray(knowledgeBase.categories) &&
    knowledgeBase.categories.length > 0

  const hasArticles =
    selectedCategory &&
    Array.isArray(selectedCategory.articles) &&
    selectedCategory.articles.length > 0

  const handleCategorySelect = (category) => {
    setSelectedCategory(category)
  }

  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      try {
        setSelectedCategory(null)
        const kb = await get(`/knowledge-base?lng=${language}`)
        setKnowledgeBase(kb)

        // If we have a knowledge base, find the first category
        // with articles, and preselect it
        if (kb && Array.isArray(kb.categories) && kb.categories.length > 0) {
          for (const category of kb.categories) {
            if (
              Array.isArray(category.articles) &&
              category.articles.length > 0
            ) {
              setSelectedCategory(category)
              break
            }
          }
        }
      } catch (err) {
        console.error('Failed to fetch knowledge base', err)
      }
    }

    fetchKnowledgeBase()
  }, [])

  return (
    <Grid container className={classes.page}>
      <NavigateBack />
      <Grid item xs={12} className={classes.pageHeader}>
        <Heading>{t('pageHeader')}</Heading>
      </Grid>
      <Grid xs={12} sm={4} className={classes.categoryList}>
        <List component="nav">
          {!hasCategories && (
            <ListItem>
              <ListItemText primary={t('noCategories')} />
            </ListItem>
          )}
          {hasCategories &&
            knowledgeBase.categories.map((category) => (
              <ListItem
                button
                key={category.id}
                selected={selectedCategory === category}
                onClick={() => handleCategorySelect(category)}
                className={classes.categoryListItem}
              >
                <ListItemText
                  primary={category.text}
                  classes={{ primary: classes.categoryListItemText }}
                />
              </ListItem>
            ))}
        </List>
      </Grid>
      <Grid xs={12} sm={8} className={classes.articleContainer}>
        {!hasArticles && (
          <Box className={classes.placeholder}>
            <Typography>{t('noArticles')}</Typography>
          </Box>
        )}
        {hasArticles && (
          <Typography
            variant="h6"
            gutterBottom
            className={classes.categoryHeader}
          >
            {selectedCategory.text}
          </Typography>
        )}
        {hasArticles &&
          selectedCategory.articles.map((article) => (
            <Box key={article.id} className={classes.articleBox}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{article.header}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.content}>
                    {article.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
      </Grid>
    </Grid>
  )
}

KnowledgeBase.displayName = 'KnowledgeBase'

KnowledgeBase.propTypes = {}

KnowledgeBase.defaultProps = {}

export default KnowledgeBase
export { KnowledgeBase }
