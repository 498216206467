/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { GradientButton } from '../../components/Button'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: '2rem',
  },
  dialogText: {
    textAlign: 'center',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '0 5%',
    },
  },
  footerActionButton: {
    border: '2px solid #6473AF',
  },
  footerButton: {
    width: '50%',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.4)',
  },
  modalBody: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    padding: 15,
    color: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
}))

const ConfirmDialog = ({
  visible,
  message,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(visible)

  useEffect(() => {
    setOpen(visible)
  }, [visible])

  const closeDialog = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    if (onConfirm instanceof Function) {
      onConfirm()
    }
    closeDialog()
  }

  const handleCancel = () => {
    if (onCancel instanceof Function) {
      onCancel()
    }
    closeDialog()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={closeDialog}
      PaperProps={{
        style: { borderRadius: '10px' },
      }}
    >
      <div className={classes.modalBody + ' ' + classes.dialogText}>
        <DialogTitle>{message}</DialogTitle>
      </div>
      <DialogActions className={classes.dialogActions}>
        <WhiteButton
          className={classes.footerButton + ' ' + classes.footerActionButton}
          onClick={handleCancel}
        >
          {cancelText}
        </WhiteButton>
        <GradientButton
          className={classes.footerButton}
          onClick={handleConfirm}
        >
          {confirmText}
        </GradientButton>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog
export { ConfirmDialog }
