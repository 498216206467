import React, { FunctionComponent } from 'react'
import { RouteComponentProps, Redirect } from '@reach/router'

type Props = RouteComponentProps

// 404 for Unauthenticated routes
export const UnauthenticatedRedirectHandler: FunctionComponent<
  Props
> = ({}) => {
  return <Redirect to="/login" noThrow />
}
