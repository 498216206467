import React, { FunctionComponent } from 'react'
import { navigate } from '@reach/router'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

type Props = {
  className?: string
}

const useStyles = makeStyles(() => ({
  navigateBackContainer: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 0 20px',
    color: '#1E2864',
  },
  navigateBackLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  navigateBackLinkIcon: {
    marginRight: '5px',
  },
}))

const NavigateBack: FunctionComponent<Props> = ({ className }) => {
  const classes = useStyles()
  const { t } = useTranslation('navigateBack')
  const containerClass = className
    ? [classes.navigateBackContainer, className].join(' ')
    : classes.navigateBackContainer

  return (
    <Grid container className={containerClass}>
      <span onClick={() => navigate(-1)} className={classes.navigateBackLink}>
        <>
          <ArrowBackIcon className={classes.navigateBackLinkIcon} />
          {t('back')}
        </>
      </span>
    </Grid>
  )
}

export { NavigateBack }
