import { useEffect, useState } from 'react'
import { Room } from 'twilio-video'

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting'

export function useRoomState(room: Room) {
  const [state, setState] = useState<RoomStateType>('disconnected')

  useEffect(() => {
    const setRoomState = () =>
      setState((room.state || 'disconnected') as RoomStateType)
    setRoomState()
    room
      .on('disconnected', setRoomState)
      .on('reconnected', setRoomState)
      .on('reconnecting', setRoomState)
    return () => {
      room
        .off('disconnected', setRoomState)
        .off('reconnected', setRoomState)
        .off('reconnecting', setRoomState)
    }
  }, [room])

  return state
}
