/*
    Copyright © DokiApp, 2021
*/

import React from 'react'
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core'
import UAParser from 'ua-parser-js'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  paper: {
    padding: '1.5em 0',
    borderRadius: '0',
    boxShadow: 'none',
    width: '280px',
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const SupportedBrowser = () => {
  const classes = useStyles()
  const { t } = useTranslation('twilio-diagnostics')

  const userAgentParser = new UAParser()
  const userAgentInfo = userAgentParser.getResult()
  const { browser, os: operatingSystem } = userAgentInfo

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={5}>
          <Typography variant="h4" gutterBottom>
            {t('supportedBrowserFirstLine')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('supportedBrowserAllChecksCompleted')}
          </Typography>
        </Grid>

        <Grid item md={5} className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              <strong>{t('supportedBrowserBrowserName')} </strong>
              {browser.name} {browser.version}
            </Typography>
            ​
            <Typography variant="body1">
              <strong>{t('supportedBrowserOsName')} </strong>
              {operatingSystem.name} {operatingSystem.version}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

SupportedBrowser.displayName = 'SupportedBrowser'

SupportedBrowser.propTypes = {}

SupportedBrowser.defaultProps = {}

export default SupportedBrowser
export { SupportedBrowser }
