import React, { useEffect } from 'react'
import { navigate } from '@reach/router'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { CONSULTANCY_TYPES, FEATURES } from '../../constants'
import { ConsultancyOption } from './ConsultancyOption/'
import { Heading, SubHeading } from '../../components/Text'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { WhiteButton } from '../../components/Button/WhiteButton'

import { consultancyOptionsStyles } from './ConsultancyOption/styles'

import { constants } from './constants'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../../util'

const useStyles = makeStyles((theme) => ({
  dashBoardContainer: {
    padding: '60px 30px 90px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  helpOptions: {
    marginTop: '40px',
  },
  riskAnalysisCard: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '0 !important',
    },
  },
  coronaCard: {
    background:
      'linear-gradient(90deg, rgba(183,220,243,0) 0%, rgba(183,220,243,0.5) 100%)',
    backgroundColor: '#6473AF',
    marginTop: theme.spacing(6),
  },
  coronaCardContent: {
    display: 'flex',
    paddingBottom: '0!important',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  coronaCardContentEn: {
    textAlign: 'center',
    paddingBottom: '0!important',
  },
  coronaCardRight: {
    textAlign: 'left',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  coronaCardLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  coronaCardButton: {
    width: '100%',
  },
  coronaCardButtonLabel: {
    marginBottom: 10,
    padding: '0 3px',
    fontWeight: 200,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  coronaCardButtonLabelIcon: {
    margin: '0 3px -5px 3px',
  },
  textCenter: {
    textAlign: 'center',
  },
}))

export const Dashboard = () => {
  const classes = useStyles()
  const consultancyOptionsClasses = consultancyOptionsStyles()

  const {
    setProviderTypeId,
    setRequestTypeIds,
    setRequestDescription,
    fetchRequesterInfo,
    chooseCorona,
    setLanguage,
    setProductSku,
  } = useStoreActions((actions) => actions.request)
  const { setHelperModal } = useStoreActions((actions) => actions.layout)
  const { fetchSubscription } = useStoreActions(
    (actions) => actions.subscription
  )
  const {
    subscription: { featuresBySku },
  } = useStoreState((state) => state)

  const { i18n, t } = useTranslation('dashboard')
  const language = normalizeLanguage(i18n.language)

  const onDemandMdFeature =
    featuresBySku &&
    (language === 'hu'
      ? featuresBySku[FEATURES.ON_DEMAND_MEDICAL_DOCTOR]
      : featuresBySku[FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL])
  const onDemandMdFeaturePrice = onDemandMdFeature?.formattedPrice

  useEffect(() => {
    fetchRequesterInfo({})
  }, [fetchRequesterInfo])

  useEffect(() => {
    fetchSubscription()
  }, [fetchSubscription])

  useEffect(() => {
    // The guideline will be available in all languages
    if (localStorage.getItem('guideline') !== 'hide') {
      setHelperModal({
        type: 'guideline',
        isOpen: true,
      })
    }
  }, [])

  const handleClick = (consultancyType: number) => {
    setProviderTypeId(consultancyType)
    setRequestTypeIds([])
    setRequestDescription('')

    if (onDemandMdFeature && onDemandMdFeature.sku) {
      setProductSku(onDemandMdFeature?.sku)
    }

    navigate('/patient/complaints')
  }

  async function handleCoronita() {
    setRequestTypeIds([])
    setRequestDescription('')
    setLanguage(i18n.language)
    chooseCorona({})

    if (onDemandMdFeature && onDemandMdFeature.sku) {
      setProductSku(onDemandMdFeature?.sku)
    }

    navigate('/patient/complaint-detail')
  }

  const handleSurvey = () => {
    navigate('/patient/surveys/risk-analysis')
  }

  return (
    <>
      <Grid item xs={12} className={classes.dashBoardContainer}>
        <Heading>{t<string>('heading')}</Heading>
        <SubHeading>{t<string>('subHeading')}</SubHeading>
        <Grid container spacing={4} justifyContent="center">
          <>
            <Grid item xs={12} sm={6}>
              <ConsultancyOption
                title={t<string>('doctorConsultancyTitle')}
                description={t<string>('doctorConsultancyDescription')}
                price={onDemandMdFeaturePrice}
                notes={t<string>('freeLimit')}
                styles={constants.doctorConsultancy.styles}
                handleClick={() => handleClick(CONSULTANCY_TYPES.DOCTOR)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ConsultancyOption
                title={t<string>('coronaConsultancyTitle')}
                description={t<string>('coronaConsultancyDescription')}
                price={onDemandMdFeaturePrice}
                styles={constants.coronaConsultation.styles}
                handleClick={() => handleCoronita()}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Card
                className={[
                  consultancyOptionsClasses.root,
                  classes.coronaCard,
                  classes.riskAnalysisCard,
                ].join(' ')}
              >
                <CardContent className={classes.coronaCardContent}>
                  <div className={classes.coronaCardRight}>
                    <Typography variant="h5" component="h2">
                      {t<string>('riskAnalysis')}
                    </Typography>
                    <Typography className={consultancyOptionsClasses.pos}>
                      {t<string>('riskAnalysisExplanation')}
                    </Typography>
                  </div>

                  <div className={classes.coronaCardLeft}>
                    <WhiteButton
                      className={classes.coronaCardButton}
                      onClick={handleSurvey}
                    >
                      <>
                        {t<string>('riskAnalysisStartSurvey')}
                        <ArrowForwardIosIcon />
                      </>
                    </WhiteButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </>
        </Grid>
      </Grid>
    </>
  )
}
