/*
    Copyright © DokiApp, 2021
*/

import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CountryFlag } from '../CountryFlag'

const LanguageDisplay = ({ language }) => {
  const { t } = useTranslation('common')

  return (
    <>
      <CountryFlag countryCode={language} state="display" /> {t(language)}
    </>
  )
}

LanguageDisplay.displayName = 'LanguageDisplay'

LanguageDisplay.propTypes = {
  language: PropTypes.oneOf(['en', 'hu', 'ua']).isRequired,
}

LanguageDisplay.defaultProps = {
  language: 'hu',
}

export default LanguageDisplay
export { LanguageDisplay }
