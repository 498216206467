import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '60px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '15px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      padding: 12,
      paddingTop: 25,
      paddingBottom: 25,
    },
  },
  bodyContainer: {
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '0 12px 0 12px',
    },
  },
  subHeading: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 200,
    fontSize: '24px',
    lineHeight: '33px',
    textAlign: 'center',
    color: '#1E2864',
    marginBottom: 40,

    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  emailLoginButton: {
    height: '50px',
    marginTop: 15,
    marginBottom: 15,
  },
  registrationButton: {
    marginTop: 15,
    marginBottom: 15,
    border: '2px solid #A7AFD0',
    height: '50px',
    width: '100%',
  },
  login: {
    padding: 0,
  },
  separator: {
    color: '#6473AF',
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    textRransform: 'uppercase',
    position: 'relative',
    marginTop: 18,
    marginBottom: 18,

    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 10,
      height: '1px',
      width: '38%',
      background: '#6473AF',
    },

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 10,
      right: 0,
      height: '1px',
      width: '38%',
      background: '#6473AF',
    },

    [theme.breakpoints.down('xs')]: {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  facebookButton: {
    color: '#FFFFFF',
    background: '#4285F4',
    height: '50px',
    marginTop: 15,
    marginBottom: 15,

    '&:hover': {
      background: 'rgba(66,133,244,0.9)',
    },
  },
  googleButton: {
    color: '#FFFFFF',
    background: '#EA4335',
    height: '50px',
    marginTop: 15,
    marginBottom: 15,

    '&:hover': {
      background: 'rgba(234,67,53,0.9)',
    },
  },
  buttonIcon: {
    padding: '0 10px 0 10px',
  },
  bottomRegistrationBox: {
    display: 'flex',
    alignItems: 'center',
    background:
      'linear-gradient(90deg, rgba(183, 220, 243, 4e-05) 0%, rgba(183, 220, 243, 0.2) 100%), #EFF9FF',
    borderRadius: '15px',
    padding: '12px',
    margin: '30px 3% 0 3%',
    minHeight: '110px',

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: '25px 12px',
    },
  },
  registrationBoxText: {
    color: '#1E2864',
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '35px',
    flex: 5,
  },
  registrationBoxButton: {
    height: '50px',
    margin: '12px 20px',
    flex: 3,

    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
  },
}))

export { useStyles }
