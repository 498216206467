import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GradientButton, WhiteButton } from '../Button'
import {
  deleteReq as axiosDelete,
  post as axiosPost,
} from '../../services/request'
import { authService } from '../../services/authService'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'

const useStyles = makeStyles(() => ({
  cancelButton: {
    border: '2px solid #6473AF',
    width: 'auto',
  },
  confirmButton: {
    width: 'auto',
  },
  deleteButton: {
    background:
      'linear-gradient(90deg, rgb(235, 46, 103) 0%, rgb(229, 62, 116) 100%) rgb(239, 30, 91)',
    border: '2px solid #6473AF',
    width: '100%',
    color: 'white',
    fontWeight: 'bold',
    '&:hover': {
      color: '#FFF',
    },
  },
  description: {
    color: '#1E2864',
    margin: '1rem 0',
    fontSize: '0.8rem',
    textAlign: 'justify',
  },
  feedbackListContainer: {
    marginTop: '2rem',
  },
  header: {
    color: '#6473AF',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
  },
}))

const DeleteProfile = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('deleteProfile')
  const [open, setOpen] = useState(false)
  const [feedbackAnswer, setFeedbackAnswer] = useState(undefined)

  const handleDelete = () => {
    setOpen(true)
  }

  const handleConfirmedDelete = async () => {
    // If the user provided a reason why she's decided to leave us,
    // save it first
    if (feedbackAnswer) {
      await axiosPost('/user/profile-delete-feedback', {
        question: t('confirmDialogQuestion'),
        answer: feedbackAnswer,
      })
    }

    try {
      await axiosDelete('/user/profile')
    } finally {
      authService.logout()
      setOpen(false)
    }
  }

  const closeConfirmDialog = () => {
    setOpen(false)
  }

  const handleFeedbackChange = async (evt) => {
    setFeedbackAnswer(evt.target.value)
  }

  return (
    <>
      <form>
        <Grid item xs={12}>
          <Typography className={classes.header}>{t('header')}</Typography>
          <Typography className={classes.description}>
            {t('description')}
          </Typography>

          <Grid container>
            <Grid item xs={12}>
              <WhiteButton
                onClick={handleDelete}
                className={classes.deleteButton}
              >
                {t('deleteButtonText')}
              </WhiteButton>
            </Grid>
          </Grid>
        </Grid>

        <Dialog onClose={closeConfirmDialog} open={open}>
          <DialogTitle onClose={closeConfirmDialog}>
            {t('confirmDialogTitle')}
          </DialogTitle>
          <DialogContent dividers>
            <form>
              <Typography gutterBottom>{t('confirmDialogContent1')}</Typography>
              <Typography gutterBottom>{t('confirmDialogContent2')}</Typography>

              <Typography
                gutterBottom
                className={classes.feedbackListContainer}
              >
                {t('confirmDialogQuestion')}
              </Typography>
              <RadioGroup
                name="feedback"
                onChange={handleFeedbackChange}
                value={feedbackAnswer}
              >
                <FormControlLabel
                  value={t('confirmDialogAnswer1')}
                  control={<Radio />}
                  label={t('confirmDialogAnswer1')}
                />
                <FormControlLabel
                  value={t('confirmDialogAnswer2')}
                  control={<Radio />}
                  label={t('confirmDialogAnswer2')}
                />
                <FormControlLabel
                  value={t('confirmDialogAnswer3')}
                  control={<Radio />}
                  label={t('confirmDialogAnswer3')}
                />
                <FormControlLabel
                  value={t('confirmDialogAnswer4')}
                  control={<Radio />}
                  label={t('confirmDialogAnswer4')}
                />
              </RadioGroup>
            </form>
          </DialogContent>
          <DialogActions>
            <GradientButton
              onClick={handleConfirmedDelete}
              className={classes.confirmButton}
            >
              {t('confirmDialogYes')}
            </GradientButton>
            <WhiteButton
              onClick={closeConfirmDialog}
              className={classes.cancelButton}
            >
              {t('confirmDialogNo')}
            </WhiteButton>
          </DialogActions>
        </Dialog>
      </form>
    </>
  )
}

DeleteProfile.displayName = 'DeleteProfile'
export { DeleteProfile }
export default DeleteProfile
