import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as DokiCircle } from '../../assets/img/first-circle.svg'

const useStyles = makeStyles(() => ({
  backgroundLayover: {
    backgroundImage:
      '-webkit-linear-gradient( 45deg, rgb(63,167,240) 0%, rgb(240,30,90) 100%)',
    position: 'absolute',
    overflow: 'hidden',
    height: '50vh',
    width: '100%',
    '@media print': {
      display: 'none',
    },
  },
  dokiCircle: {
    position: 'absolute',
    right: '0',
    bottom: '-133%',
  },
}))

const BackgroundOverlay = () => {
  const classes = useStyles()

  return (
    <div className={classes.backgroundLayover}>
      <DokiCircle className={classes.dokiCircle} />
    </div>
  )
}

export { BackgroundOverlay }
