import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  notchedOutline: {
    border: '1px solid rgba(100,115,175,0.4)',
    borderRadius: '15px',
  },
  inputLabel: {
    color: '#6473AF',
  },
  customInput: {
    width: '100%',
  },
}))

const Input = (props: TextFieldProps) => {
  const { className, label, onChange, value, type } = props
  const classes = useStyles()

  const inputClasses = className
    ? [classes.customInput, className].join(' ')
    : classes.customInput

  return (
    <TextField
      {...props}
      className={inputClasses}
      InputLabelProps={{ classes: { root: classes.inputLabel } }}
      InputProps={{
        classes: {
          input: classes.input,
          notchedOutline: classes.notchedOutline,
        },
      }}
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      type={type}
      inputRef={props.inputRef}
    />
  )
}

export { Input }
