import React, { Fragment, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Heading } from '../../../components/Text'
import { WhiteButton } from '../../../components/Button'

import emptyAvatar from '../../../assets/img/icon/empty-avatar.png'
import { useStoreState, useStoreActions } from '../../../store/store.hooks'
import { navigate } from '@reach/router'
import { Typography } from '@material-ui/core'
import { useInterval } from '../../../helpers/useInterval'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme: any) => ({
  onlinePatientsContainer: {
    padding: '60px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EFF9FF',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  cancelButton: {
    marginTop: 35,
    maxWidth: 400,
    opacity: 0.6,
    backgroundColor: '#FFFFFF',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF',
  },
  avatarImgContainer: {
    height: 128,
    width: 128,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    borderRadius: '50%',
    margin: '70px 0',
    animation: '$pulse 2500ms infinite',
  },
  avatarImg: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '100%',
    background: '#FFFFFF',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(156, 169, 207, 0.7)',
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 100px rgba(114, 127, 197, 0)',
    },

    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(114, 127, 197, 0)',
    },
  },
}))

const PatientSearch = () => {
  const classes = useStyles()

  const { providerInfoContents } = useStoreState((state) => state.providerInfo)
  const { putAvailability, fetchProviderInfo } = useStoreActions(
    (actions) => actions.providerInfo
  )
  const { matched } = useStoreState((state) => state.incomingRequest)
  const { t } = useTranslation('patientSearch')
  useEffect(() => {
    fetchProviderInfo({})
  }, [fetchProviderInfo])

  useInterval(() => {
    fetchProviderInfo({})
  }, 5000)

  async function onSearchCancel() {
    await putAvailability(false)
  }

  useEffect(() => {
    if (providerInfoContents?.isAvailable === false && matched === false) {
      navigate('/professional/dashboard')
    }
  })

  return (
    <Fragment>
      <Grid item xs={12} className={classes.onlinePatientsContainer}>
        <Heading>{t<string>('heading')}</Heading>

        <div className={classes.avatarImgContainer}>
          <img
            src={emptyAvatar}
            alt="empty-avatar"
            className={classes.avatarImg}
          />
        </div>

        <Typography>
          {t<string>('onlinePatients')}:{' '}
          {providerInfoContents?.availableRequesters}
        </Typography>

        <WhiteButton className={classes.cancelButton} onClick={onSearchCancel}>
          {t<string>('cancel')}
        </WhiteButton>
      </Grid>
    </Fragment>
  )
}

export { PatientSearch }
