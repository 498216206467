/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'

const Scheduler = () => {
  const { relativeNavigate } = useRelativeNavigate()

  useEffect(() => {
    relativeNavigate('/services')
  }, [])

  return null
}

Scheduler.displayName = 'Scheduler'

Scheduler.propTypes = {}

Scheduler.defaultProps = {}

export default Scheduler
export { Scheduler }
