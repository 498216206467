import React, { Fragment, useEffect } from 'react'
import { navigate } from '@reach/router'
import { get } from '../../services/request'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { useStoreState, useStoreActions } from '../../store/store.hooks'

import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { ComplaintsList } from './ComplaintsList'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  complaintsContainer: {
    padding: '60px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  complaintsFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      height: 120,
      position: 'absolute',
      bottom: 0,
      zIndex: 8,
      background: '#E2F4FF',
    },
  },
  complaintsFooterButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
    marginLeft: '70px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 280,
    },
  },
  complaintsNumber: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    color: '#fff',
    fontSize: '16px',
    marginRight: '5px',
  },
  complaintsNumberText: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  complaints: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
}))

const Complaints = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation('complaints')
  const { providerTypeId, complaints, selectedComplaintIds } = useStoreState(
    (state) => state.request
  )
  const { setLanguage } = useStoreActions((action) => action.request)
  const { fetchComplaints } = useStoreActions((actions) => actions.request)
  const { setNotification } = useStoreActions((actions) => actions.layout)

  const providersNotAvailableWarning = () => {
    setNotification({
      isOpen: true,
      type: 'error',
      title: t('error1Title'),
      description: t<string>('error1Description'),
    })
  }

  const checkIfThereAreAvailableProviders = async () => {
    // TODO move this into a service
    try {
      const { isProviderAvailable } = await get<{
        isProviderAvailable: boolean
      }>(`/providerAvailability/${providerTypeId}`)

      if (!isProviderAvailable) {
        providersNotAvailableWarning()
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    checkIfThereAreAvailableProviders()
  }, [])

  useEffect(() => {
    setLanguage(i18n.language)
    fetchComplaints({})
  }, [providerTypeId, fetchComplaints, i18n.language])

  const runNavigationLogic = () => {
    let canNavigateForward = false

    if (selectedComplaintIds.length < 1) {
      const unspecifiedComplaint = complaints.find(
        (rt) =>
          rt.name &&
          (rt.name.toLowerCase() === 'other' ||
            rt.name.toLowerCase() === 'egyéb')
      )

      if (unspecifiedComplaint) {
        selectedComplaintIds.push(unspecifiedComplaint.id)
        canNavigateForward = true
      } else {
        console.warn(
          'The request type for "I can\'t find in the list" not found'
        )
      }
    } else {
      canNavigateForward = true
    }

    if (canNavigateForward) {
      navigate('/patient/complaint-detail')
      return true
    } else {
      if (selectedComplaintIds.length < 1) {
        setNotification({
          isOpen: true,
          type: 'success',
          title: t<string>('successTitle'),
          description: t<string>('successDescription'),
        })
      }
    }

    return false
  }

  const footerContent =
    selectedComplaintIds.length > 0 ? (
      <Fragment>
        <Chip
          label={selectedComplaintIds.length}
          className={classes.complaintsNumber}
        />
        <span className={classes.complaintsNumberText}>
          {t<string>('complaint')}
        </span>
        <WhiteButton
          onClick={runNavigationLogic}
          className={classes.complaintsFooterButton}
        >
          {t<string>('buttonText')}
          <ArrowForwardIosIcon />
        </WhiteButton>
      </Fragment>
    ) : (
      <WhiteButton
        onClick={runNavigationLogic}
        className={classes.complaintsFooterButton}
      >
        {t<string>('nextButtonText')}
        <ArrowForwardIosIcon />
      </WhiteButton>
    )

  return (
    <Grid container className={classes.complaints}>
      <NavigateBack />
      <Grid item xs={12} className={classes.complaintsContainer}>
        <Heading>
          {providerTypeId === 1
            ? t<string>('headingDoc')
            : t<string>('headingPsycho')}
        </Heading>
      </Grid>
      <ComplaintsList complaints={complaints} />
      <Grid xs={12} container className={classes.complaintsFooter}>
        {footerContent}
      </Grid>
    </Grid>
  )
}

export { Complaints }
