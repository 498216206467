import { useLocation, navigate } from '@reach/router'
export function useRelativeNavigate() {
  const location = useLocation()
  const pathParts = location.pathname.split('/')
  const basePath = pathParts[1] ? `/${pathParts[1]}` : ''
  const relativeNavigate = (to: string) => {
    const finalTo = `${basePath}${to}`
    console.log({ to, finalTo })
    navigate(finalTo)
  }
  return { relativeNavigate }
}
