import { get, put } from './request'
import { ProviderInfoContents } from '../store/providerInfo/providerInfo.interface'

export async function fetchProviderInfo() {
  const profileContents = await get<ProviderInfoContents>('/user/providerInfo')
  return profileContents
}

export async function putAvailability({
  isAvailable,
}: {
  isAvailable: boolean
}) {
  const responseData = await put<{ message: string }>('/user/availability', {
    isAvailable,
  })
  return responseData
}
