import { action, thunk } from 'easy-peasy'
import { ProviderInfoModel } from './providerInfo.interface'
import {
  fetchProviderInfo,
  putAvailability,
} from '../../services/providerInfo.service'

export const providerInfoModel: ProviderInfoModel = {
  providerInfoContents: undefined,

  setProviderInfo: action((state, payload) => {
    state.providerInfoContents = payload
  }),
  fetchProviderInfo: thunk(async (actions) => {
    const contents = await fetchProviderInfo()
    actions.setProviderInfo(contents)
  }),
  putAvailability: thunk(async (actions, payload) => {
    await putAvailability({ isAvailable: payload })
    await actions.fetchProviderInfo({})
  }),
  resetProviderState: action((state) => {
    state.providerInfoContents = undefined
  }),
}
