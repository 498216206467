import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

import { Tips } from '../../../components/Tips'
import { useStyles } from './styles'

import { navigate } from '@reach/router'
import { useStoreState, useStoreActions } from '../../../store/store.hooks'
import { playMatchSoundEffect } from '../../../lib/soundEffects'
import { PushTypes } from '../../../constants/pushTypes'
import { useAbly } from '../../../hooks/useAbly'
import { mixpanel } from '../../../util/mixpanel'
import { SPECIALIZATIONS } from '../../../constants'

const Match = () => {
  const { t } = useTranslation('match')
  const classes = useStyles()

  const {
    profile: { profileContents },
    incomingRequest: { incomingRequestContents },
    request: { providerTypeId, payedAsOneOff, specialization },
  } = useStoreState((state) => state)

  const {
    incomingRequest: { setTwilioCredentials },
  } = useStoreActions((actions) => actions)

  const { setNotification } = useStoreActions((actions) => actions.layout)

  const ably = useAbly()
  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)
    channel.subscribe(
      PushTypes.INCOMING_CALL,
      function (message: {
        data: { twilioRoomUniqueName: string; twilioToken: string }
      }) {
        console.log(message)
        mixpanel.track('Ably message', message)
        setTwilioCredentials(message.data)
        navigate('/patient/call-ring')
      }
    )
    return () => channel.unsubscribe(PushTypes.INCOMING_CALL)
  }, [profileContents.id, ably])

  const invalidateMatchAction = async (message: any) => {
    mixpanel.track('Ably message', message)
    if (payedAsOneOff) {
      setNotification({
        isOpen: true,
        type: 'success',
        title: t<string>('error1Title'),
      })
      navigate('/patient/payment')
    } else {
      setNotification({
        isOpen: true,
        type: 'success',
        title: t<string>('error2Title'),
      })
      navigate('/patient/rematch')
    }
  }

  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)
    channel.subscribe(PushTypes.INVALIDATE_MATCH, async function (message) {
      invalidateMatchAction(message)
      navigate('/patient/rematch')
    })
    return () => channel.unsubscribe(PushTypes.INVALIDATE_MATCH)
  }, [profileContents.id, ably])

  const provider = incomingRequestContents?.provider || null

  useEffect(() => {
    playMatchSoundEffect()
  }, [])

  return (
    <Fragment>
      <Grid container className={classes.successfulPaymentTop}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.successfulPaymentContainer}>
          <Grid item xs={12} className={classes.ComplaintDetailContainer}>
            <Avatar
              alt="username"
              className={classes.avatar}
              src={provider?.avatarUrl ?? null}
            />
            <Typography
              className={classes.paymentTitle}
              variant="h1"
              component="h2"
            >
              {`${provider?.namePrefix ?? ''} ${provider?.lastName ?? ''} ${
                provider?.firstName ?? ''
              }`}
            </Typography>
            <Typography className={classes.paymentAlert} component="p">
              {specialization === SPECIALIZATIONS.PEDIATRICS
                ? t<string>('titlePediatrician')
                : t<string>('titleDoc')}
            </Typography>
            <Typography
              className={classes.paymentTitle}
              variant="h1"
              component="h2"
              style={{ marginTop: 20 }}
            >
              {t<string>('matchTitleDoc')}
            </Typography>
            <Typography
              className={classes.paymentAlert}
              component="p"
              style={{ marginTop: 5, fontWeight: 400 }}
            >
              {t<string>('matchTextDoc')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.consultancyInfo}>
        <Typography className={classes.mainTitle} variant="h1" component="h2">
          {t<string>('heading')}
        </Typography>
        <Typography className={classes.subheading} component="p">
          {t<string>('subheading')}
        </Typography>
        <Tips />
      </Grid>
    </Fragment>
  )
}

export { Match }
