/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormInput } from '../../components/FormInput'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  bmi: {
    marginLeft: '2rem',
  },
  container: {
    justifyContent: 'center',
  },
  input: {
    marginBottom: '2rem',
  },
  numberInput: {
    margin: '0 2rem',
  },
}))

const BmiAnswer = ({ question, onAnswered, disabled }) => {
  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm()
  const watchBmiHeight = watch('bmiHeight')
  const watchBmiWeight = watch('bmiWeight')
  const [bmi, setBmi] = useState()

  const { t } = useTranslation('survey')
  const classes = useStyles()

  useEffect(() => {
    if (watchBmiHeight && watchBmiWeight) {
      const bmi = watchBmiWeight / Math.pow(watchBmiHeight / 100, 2)
      setBmi(bmi)
    }
  }, [watchBmiHeight, watchBmiWeight])

  const { ref: bmiHeightInputRef, ...bmiHeightInputProps } = register(
    'bmiHeight',
    {
      required: t('missingField'),
    }
  )

  const { ref: bmiWeightInputRef, ...bmiWeightInputProps } = register(
    'bmiWeight',
    {
      required: t('missingField'),
    }
  )

  useEffect(() => {
    // Find the matching answer for this BMI
    let selectedAnswer = null
    if (bmi > 0 && bmi < 25) {
      selectedAnswer = question.answers.find((a) => a.answer === '<25')
    } else if (bmi >= 25 && bmi < 30) {
      selectedAnswer = question.answers.find((a) => a.answer === '<30')
    } else if (bmi >= 30 && bmi < 40) {
      selectedAnswer = question.answers.find((a) => a.answer === '<40')
    } else if (bmi >= 40) {
      selectedAnswer = question.answers.find((a) => a.answer === '>40')
    }

    if (selectedAnswer) {
      if (onAnswered instanceof Function) {
        const answer = {
          question,
          answer: selectedAnswer,
        }

        onAnswered(answer)
      }
    }
  }, [bmi])

  const formatBmi = (bmi) => {
    if (!bmi || isNaN(bmi)) {
      return t('missingBmi')
    }

    return bmi && Math.round(bmi * 10) / 10
  }

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.numberInput}>
          <FormInput
            type="number"
            defaultValue={''}
            inputName="bmiHeight"
            error={!!errors.bmiHeight}
            value={watch('bmiHeight')}
            label={t('bmiHeightLabel')}
            clearError={() => {
              clearErrors(['bmiHeight'])
            }}
            placeholder={t('bmiHeightPlaceholder')}
            errorMessage={errors?.bmiHeight?.message ?? ''}
            className={classes.input}
            inputRef={bmiHeightInputRef}
            disabled={disabled}
            {...bmiHeightInputProps}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            type="number"
            defaultValue={''}
            inputName="bmiWeight"
            error={!!errors.bmiWeight}
            value={watch('bmiWeight')}
            label={t('bmiWeightLabel')}
            clearError={() => {
              clearErrors(['bmiWeight'])
            }}
            placeholder={t('bmiWeightPlaceholder')}
            errorMessage={errors?.bmiWeight?.message ?? ''}
            className={classes.input}
            inputRef={bmiWeightInputRef}
            disabled={disabled}
            {...bmiWeightInputProps}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.bmi}>
            {t('yourBmi')}: {formatBmi(bmi)}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

BmiAnswer.displayName = 'BmiAnswer'

BmiAnswer.propTypes = {}

BmiAnswer.defaultProps = {}

export default BmiAnswer
export { BmiAnswer }
