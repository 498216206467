import React, { Fragment, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  accordionHeading: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1E2864',
    padding: '5px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  accordionDescription: {
    fontSize: 20,
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  accordionDescriptionContainer: {
    backgroundColor: '#EFF9FF',
    borderTop: '1px solid #C0C0C0',
    padding: 20,
  },
}))

const Tips = () => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const { fetchTips, setLanguage } = useStoreActions((actions) => actions.tips)
  setLanguage(i18n.language)
  const { tips } = useStoreState((state) => state.tips)

  useEffect(() => {
    fetchTips([])
  }, [fetchTips, i18n.language])

  const tipsLayout = tips.map((tip) => {
    return (
      <ExpansionPanel key={tip.id}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.accordionHeading}>
            {tip.title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={classes.accordionDescriptionContainer}
        >
          <Typography className={classes.accordionDescription}>
            {tip.description}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  })

  return <Fragment>{tipsLayout}</Fragment>
}

export { Tips }
