/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const RadioGroupAnswer = ({ question, onAnswered, disabled, row = false }) => {
  const [value, setValue] = useState(null)

  const handleChange = (event) => {
    setValue(event.target.value)

    const selectedAnswer = question.answers.find(
      (a) => a.answer === event.target.value
    )
    if (onAnswered instanceof Function) {
      const answer = {
        question,
        answer: selectedAnswer,
      }
      onAnswered(answer)
    }
  }

  useEffect(() => {
    if (question && question.recordedAnswer) {
      const { answer } = question.recordedAnswer
      setValue(answer)
    }
  }, [question])

  const hasAnswers =
    question && Array.isArray(question.answers) && question.answers.length > 0
  const answers = hasAnswers
    ? question.answers.map((answer) => (
        <FormControlLabel
          key={answer.id}
          value={answer.answer}
          control={<Radio />}
          label={answer.answer}
        />
      ))
    : null

  return (
    <Grid container justifyContent={row ? 'center' : 'auto'}>
      <FormControl component="fieldset" disabled={disabled}>
        <RadioGroup
          name={`question-${question.id}-radio-group`}
          value={value}
          onChange={handleChange}
          row={row}
        >
          {answers}
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}

RadioGroupAnswer.displayName = 'RadioGroupAnswer'

RadioGroupAnswer.propTypes = {}

RadioGroupAnswer.defaultProps = {}

export default RadioGroupAnswer
export { RadioGroupAnswer }
