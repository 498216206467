/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { get } from '../../services/request'
import { SurveyForm } from './SurveyForm'
import { MissingSurvey } from './MissingSurvey'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Survey = ({ surveyId, children }) => {
  const [survey, setSurvey] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const {
    i18n: { language },
    t,
  } = useTranslation('survey')
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)

    const fetchSurvey = async () => {
      setLoading(true)
      try {
        setError(null)
        const survey = await get(`/surveys/${surveyId}?lng=${language}`)
        setSurvey(survey)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchSurvey()
  }, [])

  if (loading) {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (!surveyId || error) {
    return <MissingSurvey />
  }

  if (survey) {
    return <SurveyForm survey={survey}>{children}</SurveyForm>
  }

  return null
}

Survey.displayName = 'Survey'

Survey.propTypes = {}

Survey.defaultProps = {}

export default Survey
export { Survey }
