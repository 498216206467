/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect } from 'react'
import { SupportedBrowser } from './SupportedBrowser'
import { UnsupportedBrowser } from './UnsupportedBrowser'
import Video from 'twilio-video'

const CheckBrowser = ({ onSupportedBrowser, onUnsupportedBrowser }) => {
  useEffect(() => {
    if (Video.isSupported) {
      if (onSupportedBrowser instanceof Function) {
        onSupportedBrowser()
      }
    } else {
      if (onUnsupportedBrowser instanceof Function) {
        onUnsupportedBrowser()
      }
    }
  }, [])

  return Video.isSupported ? <SupportedBrowser /> : <UnsupportedBrowser />
}

CheckBrowser.displayName = 'CheckBrowser'

CheckBrowser.propTypes = {}

CheckBrowser.defaultProps = {}

export default CheckBrowser
export { CheckBrowser }
