import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import AskForVoucher from './AskForVoucher'
//import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  root: {},
}))

const VoucherGuard = ({ children }) => {
  const classes = useStyles()
  const { t } = useTranslation(
    'TODO: i18n JSON file name is missing in VoucherGuard'
  )

  const { activeVoucher } = useStoreState((state) => state.subscription)
  const { fetchSubscription } = useStoreActions(
    (actions) => actions.subscription
  )

  const handleVoucherUpdate = (associatedVoucher) => {
    fetchSubscription({})
  }

  if (!activeVoucher) {
    return <AskForVoucher onDone={handleVoucherUpdate} />
  }

  return children
}

VoucherGuard.displayName = 'VoucherGuard'
export { VoucherGuard }
export default VoucherGuard
