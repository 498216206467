import axios from 'axios'

import store from '../store'

const authService = {
  login: async ({ email, password }: any) => {
    try {
      const {
        data: { token },
      } = await axios.post('/api/platform/auth/login', {
        email,
        password,
      })

      store.dispatch.auth.setToken(token)
      localStorage.setItem('token', token)
    } catch (error) {
      // alert(error.response.data.message)
      throw error
    }
  },
  logout: () => {
    window.localStorage.removeItem('token')
    store.dispatch.layout.resetLayoutState()
    store.dispatch.profile.resetProfileState()
    store.dispatch.providerInfo.resetProviderState()
    store.dispatch.request.resetRequestState()
    // resetting the auth store will cause re-render in App.tsx so no navigation needed
    store.dispatch.auth.resetAuthState()
  },
}

export { authService }
