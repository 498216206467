/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton, WhiteButton } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useStoreState } from '../../store/store.hooks'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: '0 2rem',
  },
  centered: {
    textAlign: 'center',
  },
  greeting: {
    fontSize: '30px',
    marginBottom: '2rem',
    textAlign: 'center',
  },
  intro: {
    margin: '0 2rem 2rem',
    textAlign: 'justify',
  },
  introText: {
    fontSize: '1.1rem',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  previousResponsesButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '1px solid #6473AF',
  },
  startTestButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  timeNeeded: {
    margin: '2rem',
  },
}))

const SurveyForm = ({ survey }) => {
  const { t } = useTranslation('survey')
  const {
    profile: { profileContents },
  } = useStoreState((state) => state)
  const classes = useStyles()
  const { relativeNavigate } = useRelativeNavigate()

  const handleStartTestClick = () => {
    relativeNavigate(`/surveys/${survey.wellKnownKey}/questions`)
  }
  const handlePreviousResponsesClick = () => {
    let redirectPathSegment = survey && (survey.category || survey.wellKnownKey)
    relativeNavigate(`/surveys/${redirectPathSegment}/evaluation`)
  }

  return (
    <Grid container className={classes.page}>
      <NavigateBack />
      <Grid item xs={12} className={classes.pageHeader}>
        <Heading>{survey.title}</Heading>
      </Grid>
      <Grid item xs={12} className={classes.greeting}>
        <Typography variant="p">
          {t('greeting')} {profileContents?.name}!
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.intro}>
        <Typography className={classes.introText}>
          {survey.description}
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.buttonContainer}
      >
        <Grid item xs={6} className={classes.centered}>
          <GradientButton
            className={classes.startTestButton}
            onClick={handleStartTestClick}
          >
            {t('startTest')}
          </GradientButton>
        </Grid>
        <Grid item xs={6} className={classes.centered}>
          <WhiteButton
            className={classes.previousResponsesButton}
            onClick={handlePreviousResponsesClick}
          >
            {t('previousResponses')}
          </WhiteButton>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.timeNeeded}>
        <Typography>{t('timeNeeded')}</Typography>
      </Grid>
    </Grid>
  )
}

SurveyForm.displayName = 'SurveyForm'

SurveyForm.propTypes = {}

SurveyForm.defaultProps = {}

export default SurveyForm
export { SurveyForm }
