import React from 'react'

import step1 from '../../../../../assets/img/cam_tutorial.png'
import step2 from '../../../../../assets/img/cam_tutorial/chrome2.png'

const ChromeCamHelp = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: 30 }}>
      <p>
        1. Kattints az alábbi képen jobb alsó sarokban látható kamera ikonra, a
        böngészö keresö sávjában.{' '}
      </p>
      <div
        style={{
          backgroundImage: `url(${step1})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'right',
        }}
      ></div>
      <p>
        2. Kattints az alábbi képen lévö beállításra, hogy engedélyezve legyen a
        kamera.
      </p>
      <div
        style={{
          backgroundImage: `url(${step2})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'right',
          backgroundSize: '270%',
        }}
      ></div>
    </div>
  )
}

export { ChromeCamHelp }
