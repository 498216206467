/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { ProgressBar } from '../../ProgressBar'
import { Microphone, Speaker } from '../../icons'
import { AudioDevice } from './AudioDevice'
import { useAudioTest } from './useAudioTest'
import { GradientButton } from '../../../../components/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'inline-block',
    padding: '23px 0',
    borderRadius: '0',
    minHeight: '280px',
    width: '290px',
    boxShadow: 'none',
  },
  audioLevelContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5em',
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  playbackControls: {},
}))

const CheckAudio = ({ onNext }) => {
  const classes = useStyles()
  const { t } = useTranslation('twilio-diagnostics')

  const [inputDeviceId, setInputDeviceId] = useState('')
  const [outputDeviceId, setOutputDeviceId] = useState('')
  const previousInputDeviceIdRef = useRef('')

  const {
    error,
    setError,
    isRecording,
    isAudioInputTestRunning,
    isAudioOutputTestRunning,
    playAudio,
    playbackURI,
    readAudioInput,
    stopAudioTest,
    inputLevel,
    outputLevel,
  } = useAudioTest()

  const handleNext = () => {
    stopAudioTest()
    if (onNext instanceof Function) {
      onNext()
    }
  }

  const volumeLevel = isAudioOutputTestRunning ? outputLevel : inputLevel

  const disableAll =
    isRecording ||
    isAudioOutputTestRunning ||
    (!!error && error !== 'No audio detected')

  const handleRecordClick = () => {
    readAudioInput({ deviceId: inputDeviceId, enableRecording: true })
  }

  const handlePlayClick = () => {
    playAudio({ deviceId: outputDeviceId, testURI: playbackURI })
  }

  // Start audio test when deviceId changes
  useEffect(() => {
    const newInputDeviceSelected =
      previousInputDeviceIdRef.current !== inputDeviceId
    previousInputDeviceIdRef.current = inputDeviceId

    // Restarts the test to continuously capture audio input
    if (
      !error &&
      (newInputDeviceSelected || (!isRecording && !isAudioInputTestRunning))
    ) {
      readAudioInput({ deviceId: inputDeviceId })
    }

    if (error) {
      stopAudioTest()
    }
  }, [
    error,
    inputDeviceId,
    isRecording,
    isAudioInputTestRunning,
    readAudioInput,
    stopAudioTest,
  ])

  useEffect(() => {
    return () => {
      stopAudioTest()
    }
  }, [])

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={5}>
          <Typography variant="h4" gutterBottom>
            {t('checkAudioFirstLine')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t('checkAudioSecondLine')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <strong>{t('checkAudioQuestion')}</strong>
          </Typography>

          <GradientButton
            onClick={handleNext}
            disabled={!!error && error !== 'No audio detected'}
          >
            {t('checkAudioConfirmButton')}
          </GradientButton>
        </Grid>

        <Grid item md={5}>
          <Paper className={classes.paper}>
            <div className={classes.topLine}>
              <Typography variant="subtitle2">
                <strong>{t('checkAudioRecordingTitle')}</strong>
              </Typography>
              <div className={classes.playbackControls}>
                <Button
                  variant="outlined"
                  style={{ marginRight: '1em' }}
                  size="small"
                  disabled={disableAll}
                  onClick={handleRecordClick}
                >
                  {t('checkAudioRecordButton')}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={!playbackURI || disableAll}
                  onClick={handlePlayClick}
                >
                  {t('checkAudioPlayButton')}
                </Button>
              </div>
            </div>

            <AudioDevice
              disabled={disableAll}
              kind="audiooutput"
              onDeviceChange={setOutputDeviceId}
              setDeviceError={setError}
            />
            <AudioDevice
              disabled={disableAll}
              kind="audioinput"
              onDeviceChange={setInputDeviceId}
              setDeviceError={setError}
              error={error}
            />
            <div className={classes.audioLevelContainer}>
              <div
                style={{
                  width: '2em',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {isAudioOutputTestRunning ? <Speaker /> : <Microphone />}
              </div>
              <ProgressBar
                position={volumeLevel}
                duration={0.1}
                style={{ flex: '1' }}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

CheckAudio.displayName = 'CheckAudio'

CheckAudio.propTypes = {}

CheckAudio.defaultProps = {}

export default CheckAudio
export { CheckAudio }
