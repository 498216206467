/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({}))

const OpenEndedAnswer = ({ question, onAnswered, disabled }) => {
  const classes = useStyles()

  const handleBlur = (event) => {
    const selectedAnswer = question.answers[0]

    if (selectedAnswer && onAnswered instanceof Function) {
      const answer = {
        question,
        answer: { ...selectedAnswer, openEnded: event.target.value },
      }
      onAnswered(answer)
    }
  }

  return (
    <>
      <Grid container>
        <TextField
          multiline
          onBlur={handleBlur}
          rows={2}
          maxRows={4}
          fullWidth
        />
      </Grid>
    </>
  )
}

OpenEndedAnswer.displayName = 'OpenEndedAnswer'

export default OpenEndedAnswer
export { OpenEndedAnswer }
