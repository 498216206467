const textForm = [
  {
    type: 'header',
    text: 'Adatvédelmi nyilatkozat',
  },
  {
    type: 'subheader',
    text: 'Lorem ipsum dolor sit amet',
  },
  {
    type: 'text',
    text: `Consectetur adipiscing elit. Maecenas sem elit, sollicitudin non eros et, congue iaculis erat. Vestibulum sollicitudin congue risus, pulvinar dignissim massa sodales at. Curabitur ut ullamcorper dui. Vivamus pretium enim vitae elit lacinia hendrerit. Etiam at auctor urna, at posuere tellus. Suspendisse eros est, posuere ac nibh et, gravida aliquet elit. Etiam id mauris justo. Nulla sem augue, mattis in metus vitae, faucibus finibus dolor. Donec efficitur vulputate finibus. Donec consectetur vulputate hendrerit.`,
  },
  {
    type: 'subheader',
    text: 'Morbi varius fermentum hendrerit.',
  },
  {
    type: 'text',
    text: `Duis ultricies elementum risus ac aliquet. Etiam viverra ac odio vitae imperdiet. Aenean venenatis lacus at arcu pellentesque placerat. Etiam et malesuada lorem. In nec molestie felis. Nullam nec pulvinar erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus in consectetur est, convallis convallis nunc. Curabitur velit massa, maximus sed venenatis gravida, vehicula id lorem. Donec laoreet neque sit amet purus ullamcorper euismod. Donec vel tempus purus. Nam nisi turpis, hendrerit vel risus feugiat, viverra sodales lectus. Vivamus semper sapien eu molestie fringilla. Suspendisse lobortis massa vitae sapien pharetra, vel finibus enim venenatis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.`,
  },
]

export default textForm
