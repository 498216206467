/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { CheckCircleOutline } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { GradientButton } from '../../../../components/Button'
import { useTwilioStatus } from './useTwilioStatus'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2em 0',
    border: 'none',
    boxShadow: 'none',
  },
  successIcon: {
    color: '#6BBA70',
  },
}))

const CheckConnectivity = ({ onNext }) => {
  const classes = useStyles()
  const { t } = useTranslation('twilio-diagnostics')
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState(null)
  const [allOperational, setAllOperational] = useState(false)
  const { getTwilioStatus } = useTwilioStatus()

  const handleNext = () => {
    if (onNext instanceof Function) {
      onNext()
    }
  }

  useEffect(() => {
    getTwilioStatus().then((twilioStatus) => {
      console.debug('Twilio status received', twilioStatus)

      setStatus(twilioStatus)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (status) {
      let allSystemsOperational = true

      Object.keys(status).forEach((key) => {
        if (
          status[key] !== 'operational' &&
          status[key] !== 'degraded_performance'
        ) {
          allSystemsOperational = false
        }
      })

      setAllOperational(allSystemsOperational)
    }
  }, [status])

  return (
    <>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={5}>
            <Typography variant="h4" gutterBottom>
              {t('checkConnectivityHeader')}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {t('checkConnectivityExplanation')}
            </Typography>

            {loading && (
              <>
                <Typography variant="body1" gutterBottom>
                  <strong>{t('checkConnectivityHangTight')}</strong>
                </Typography>
                <CircularProgress />
              </>
            )}

            {!loading && allOperational && (
              <>
                <GradientButton onClick={handleNext}>
                  {t('checkConnectivityNextButton')}
                </GradientButton>
              </>
            )}
          </Grid>

          <Grid item md={5}>
            <Paper className={classes.paper}>
              <Grid container direction="column">
                {loading && <CircularProgress />}

                {!loading && allOperational && (
                  <>
                    <Typography
                      variant="body1"
                      gutterBottom
                      align="center"
                      className={classes.successIcon}
                    >
                      <CheckCircleOutline />
                    </Typography>
                    <Typography variant="body1" gutterBottom align="center">
                      <strong>{t('checkConnectivityAllOperational')}</strong>
                    </Typography>
                  </>
                )}

                {!loading && !allOperational && (
                  <>
                    <Alert severity="error">
                      {t('checkConnectivitySystemDown')}
                    </Alert>
                  </>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

CheckConnectivity.displayName = 'CheckConnectivity'

CheckConnectivity.propTypes = {}

CheckConnectivity.defaultProps = {}

export default CheckConnectivity
export { CheckConnectivity }
