import React from 'react'
import { navigate } from '@reach/router'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { WhiteButton } from '../../components/Button'
import { useStoreActions } from '../../store/store.hooks'
import successCircle from '../../assets/img/circle.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  successfulPaymentTop: {
    borderRadius: '15px',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  successfulPaymentContainer: {
    zIndex: 3,
  },
  title: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  paragraph: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  acceptCallIcon: {
    marginRight: 5,
  },
  continueButton: {
    padding: '5px 50px',
    width: 'unset',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.4);',
  },
}))

const ConsultancyProblemSent = () => {
  const classes = useStyles()
  const { resetRequestState } = useStoreActions((actions) => actions.request)
  const { t } = useTranslation('consultacyProblemSent')

  const backToMain = () => {
    resetRequestState()
    navigate('/')
  }

  return (
    <Grid container className={classes.successfulPaymentTop}>
      <div className={classes.bgLayover}></div>
      <Grid container className={classes.successfulPaymentContainer}>
        <Grid item xs={12} className={classes.ComplaintDetailContainer}>
          <img src={successCircle} alt="siker" />
          <Typography className={classes.title} variant="h1" component="h2">
            {t<string>('successfullySent')}
          </Typography>
          <Typography className={classes.paragraph} component="p">
            {t<string>('helpText')}
          </Typography>
          <WhiteButton
            onClick={() => backToMain()}
            className={classes.continueButton}
          >
            {t<string>('continue')}
            <ArrowForwardIosIcon />
          </WhiteButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { ConsultancyProblemSent }
