import { action } from 'easy-peasy'

import { CouponModel } from './coupon.interface'

export const couponModel: CouponModel = {
  couponCode: undefined,
  discountedPrice: undefined,
  setCouponCode: action((state, payload) => {
    state.couponCode = payload
  }),
  setDiscountedPrice: action((state, payload) => {
    state.discountedPrice = payload
  }),
}
