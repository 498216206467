/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Container,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useCameraTest } from './useCameraTest'
import { useAudioVideo, useDevices } from '../../../../hooks'
import { GradientButton, WhiteButton } from '../../../../components/Button'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2em 0',
    border: 'none',
    boxShadow: 'none',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  aspectRatioContainer: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    padding: '1em',
    margin: '1em 0',
    backgroundColor: '#333333',
    '&::after': {
      content: '""',
      paddingTop: '56.25%',
    },
    '& video': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    margin: '0.5em 0',
    '& svg': {
      marginRight: '0.3em',
    },
  },
  shortFormControl: {
    width: '80%',
  },
  problemButton: {
    marginTop: '0.5rem',
    width: '100%',
    textAlign: 'center',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF',
  },
  problemAlert: {
    marginTop: '1rem',
  },
}))

const CheckVideo = ({ onNext }) => {
  const { t } = useTranslation('twilio-diagnostics')

  const classes = useStyles()
  const {
    videoElementRef,
    startVideoTest,
    stopVideoTest,
    videoTest,
    videoTestError,
  } = useCameraTest()
  const { videoInputDevices } = useDevices()
  const prevVideoDeviceId = useRef('')
  const [videoInputDeviceId, setVideoInputDeviceId] = useState('')
  const [shouldMoveForward, setShouldMoveForward] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { setSelectedVideoDeviceId } = useAudioVideo()

  const handleNext = () => {
    setShouldMoveForward(true)
    stopVideoTest()
    prevVideoDeviceId.current = ''
    if (onNext instanceof Function) {
      onNext()
    }
  }

  const handleProblem = () => {
    setShowAlert(true)
  }

  const setDevice = (deviceId) => {
    setVideoInputDeviceId(deviceId)
    setSelectedVideoDeviceId(deviceId)
  }

  useEffect(() => {
    // If no device is select, set the first available device as the active device.
    const hasSelectedDevice = videoInputDevices.some(
      (device) => device.deviceId === videoInputDeviceId
    )

    if (videoInputDevices.length && !hasSelectedDevice) {
      setVideoInputDeviceId(videoInputDevices[0].deviceId)
      setSelectedVideoDeviceId(videoInputDevices[0].deviceId)
    }
  }, [videoInputDevices, videoInputDeviceId])

  useEffect(() => {
    if (!shouldMoveForward) {
      // Start the test when when the video input device ID changes
      const newDeviceSelected = prevVideoDeviceId.current !== videoInputDeviceId
      prevVideoDeviceId.current = videoInputDeviceId

      if (videoInputDeviceId && newDeviceSelected) {
        startVideoTest(videoInputDeviceId)
      }

      if (videoTestError) {
        stopVideoTest()
      }
    }
  }, [videoInputDeviceId, startVideoTest, stopVideoTest, videoTestError])

  useEffect(() => {
    if (shouldMoveForward) {
      stopVideoTest()
    }
  }, [shouldMoveForward])

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={5}>
          <Typography variant="h4" gutterBottom>
            {t('checkVideoFirstLine')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t('checkVideoSecondLine')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>{t('checkVideoQuestion')}</strong>
          </Typography>

          <GradientButton onClick={handleNext} disabled={showAlert}>
            {t('checkVideoConfirmButton')}
          </GradientButton>

          <WhiteButton
            onClick={handleProblem}
            className={classes.problemButton}
            disabled={showAlert}
          >
            {t('checkVideoProblemButton')}
          </WhiteButton>

          {showAlert && (
            <Alert
              severity="info"
              className={classes.problemAlert}
              onClick={handleNext}
              action={
                <Button color="inherit" variant="outlined" size="small">
                  {t('checkVideoProblemContinue')}
                </Button>
              }
            >
              {t('checkVideoProblemAlert')}
              <br />
              <br />
              {t('checkVideoProblemHelpText1')}
              <br />- {t('checkVideoProblemHelpText2')}
              <br />- {t('checkVideoProblemHelpText3')}
            </Alert>
          )}
        </Grid>

        <Grid item md={5}>
          <Paper className={classes.paper}>
            <Grid container direction="column">
              <Typography variant="subtitle2">
                <strong>{t('checkVideoVideoPreview')}</strong>
              </Typography>
              <div className={classes.aspectRatioContainer}>
                <video
                  ref={videoElementRef}
                  muted={true}
                  playsInline={true}
                  autoPlay={true}
                />
              </div>
            </Grid>
            <FormControl className={classes.shortFormControl}>
              <Typography variant="subtitle2">
                <strong>{t('checkVideoCameraLabel')}</strong>
              </Typography>
              <Select
                onChange={(e) => setDevice(e.target.value)}
                value={videoInputDeviceId}
                variant="outlined"
                disabled={!!videoTestError}
              >
                {videoInputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

CheckVideo.displayName = 'CheckVideo'

CheckVideo.propTypes = {}

CheckVideo.defaultProps = {}

export default CheckVideo
export { CheckVideo }
