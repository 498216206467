import { action, thunk } from 'easy-peasy'
import { ProfileModel } from './profile.interface'
import { fetchProfile, fetchCard } from '../../services/profile.service'
import mixpanel from 'mixpanel-browser'
import { getName } from '../../helpers/getName'
import * as Sentry from '@sentry/browser'

export const profileModel: ProfileModel = {
  profileContents: {},
  cards: [],
  isProfileLoading: true,
  setProfile: action((state, payload) => {
    state.profileContents = payload
    state.isProfileLoading = false
  }),
  fetchProfile: thunk(async (actions, payload, helpers) => {
    const helperActions: any = helpers.getStoreActions()
    helperActions.layout.setIsLoading(true)
    const profileContents = await fetchProfile()
    actions.setProfile(profileContents)
    mixpanel.identify(profileContents.id as any)

    const mixpanelObj = {
      $email: profileContents.email, // only reserved properties need the $
      'Sign up date': profileContents.createdAt, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
      USER_ID: profileContents.id, // use human-readable names
      name: getName(profileContents), // ...or numbers
      userType: profileContents.userTypeId === 2 ? 'Patient' : 'Professional',
      release: process.env.REACT_APP_SENTRY_RELEASE,
    }
    // Patient identification
    mixpanel.people.set(mixpanelObj)
    Sentry.configureScope(function (scope) {
      scope.setUser({ email: profileContents.email })
    })

    helperActions.layout.setIsLoading(false)
  }),
  setCard: action((state, payload) => {
    state.cards = payload
  }),
  fetchCard: thunk(async (actions) => {
    const contents = await fetchCard()
    actions.setCard(contents)
  }),
  resetProfileState: action((state) => {
    state.profileContents = {}
    state.cards = []
    state.isProfileLoading = true
  }),
}
