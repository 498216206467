import React from 'react'
import { navigate } from '@reach/router'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import { GradientButton, WhiteButton } from '../Button'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { putRequestStatus } from '../../services/request.service'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 15,
  },
  modalBody: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    padding: 15,
    color: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  dialogText: {
    textAlign: 'center',
    padding: '0 10%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 5%',
    },
  },
  dialogContentText: {
    color: '#fff',
    fontWeight: 200,
  },
  dialogFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFF9FF',
    padding: 25,
  },
  footerButton: {
    width: '50%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
  },
  footerActionButton: {
    border: '2px solid #6473AF',
  },
}))

const CancelPaymentModal = () => {
  const classes = useStyles()
  const { t }: { t: any } = useTranslation('cancelPayment')
  const {
    complaints: { resetComplaintState },
    layout: { setIsCancelPaymentModalOpen },
    request: { resetRequestState },
  } = useStoreActions((actions) => actions)

  const {
    layout: { isCancelPaymentModalOpen },
    request: { requestId },
  } = useStoreState((state) => state)

  const cancelPaymentProcess = async () => {
    resetComplaintState()
    const requestToUpdateId = requestId
      ? requestId
      : parseInt(localStorage.getItem('requestId') ?? '0')

    localStorage.removeItem('requestId')

    const body = {
      requestId: requestToUpdateId,
      status: 'done',
      shouldContinue: false,
      successful: false,
    }
    const message = putRequestStatus({ body })
    setIsCancelPaymentModalOpen(false)
    resetRequestState()
    console.log(message)
    navigate('/')
  }

  return (
    <Dialog
      open={isCancelPaymentModalOpen}
      onClose={() => setIsCancelPaymentModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      PaperProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <div className={classes.modalBody}>
        <div className={classes.dialogText}>
          <DialogTitle id="alert-dialog-title">{t('title')}</DialogTitle>
          <DialogContent>
            <DialogContentText
              className={classes.dialogContentText}
              id="alert-dialog-description"
            >
              {t('bodyText')}
            </DialogContentText>
          </DialogContent>
        </div>
      </div>
      <DialogActions className={classes.dialogFooter}>
        <WhiteButton
          className={[classes.footerButton, classes.footerActionButton].join(
            ' '
          )}
          onClick={cancelPaymentProcess}
        >
          {t('action')}
        </WhiteButton>
        <GradientButton
          className={classes.footerButton}
          onClick={() => setIsCancelPaymentModalOpen(false)}
        >
          {t('cancel')}
        </GradientButton>
      </DialogActions>
    </Dialog>
  )
}

export { CancelPaymentModal }
