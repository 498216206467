import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import dokiLogo from '../../../../../../assets/img/doki-logo-white.png'
import laptop from '../../../../../../assets/img/guideline_tutorial/laptop.png'
import apple from '../../../../../../assets/img/guideline_tutorial/apple.png'
import android from '../../../../../../assets/img/guideline_tutorial/android.png'
import applestoreAndPlaystore from '../../../../../../assets/img/guideline_tutorial/apple-and-play-store.png'
import chrome from '../../../../../../assets/img/guideline_tutorial/chrome.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  text: {
    textTransform: 'uppercase',
    fontSize: 26,
    lineHeight: 1.5,

    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      lineHeight: 1.2,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      lineHeight: 1.1,
    },
  },
  logo: {
    marginTop: '2%',

    [theme.breakpoints.down('xs')]: {
      marginTop: '55px',
    },
  },
  logoImage: {
    width: '34%',
    minWidth: '200px',
  },
  infoBlockImage: {
    width: '110px',
    marginRight: '20px',

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: '15px',
    },
  },
  infoBlock: {
    marginTop: '4%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '58%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  infoBlockImageSmall: {
    width: '54px',
    margin: '10px',
  },
  hideSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showMdUp: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const SecondPageTutorialContent = () => {
  const classes = useStyles()
  const { t } = useTranslation('secondPageTutorialContent')
  return (
    <Box
      display="flex"
      textAlign="left"
      alignItems="center"
      flexDirection="column"
    >
      <Box textAlign="center" className={classes.logo}>
        <img src={dokiLogo} className={classes.logoImage} />
      </Box>

      <Box className={classes.infoBlock}>
        <Box textAlign="center">
          <img src={laptop} className={classes.infoBlockImage} alt="" />
        </Box>

        <Typography variant="h5" component="p" className={classes.text}>
          {t<string>('text1')}
        </Typography>
      </Box>

      <Box className={classes.infoBlock}>
        <Box textAlign="center" className={classes.hideSmDown}>
          <img
            src={applestoreAndPlaystore}
            className={classes.infoBlockImage}
            alt=""
          />
        </Box>

        <Box textAlign="center" className={classes.showMdUp}>
          <img src={apple} className={classes.infoBlockImageSmall} />
          <img src={android} className={classes.infoBlockImageSmall} />
        </Box>

        <Typography variant="h5" component="p" className={classes.text}>
          {t<string>('text2')}
        </Typography>
      </Box>

      <Box className={classes.infoBlock}>
        <Box textAlign="center">
          <img src={chrome} className={classes.infoBlockImage} alt="" />
        </Box>

        <Typography variant="h5" component="p" className={classes.text}>
          {t<string>('text3')}
        </Typography>
      </Box>
    </Box>
  )
}

export { SecondPageTutorialContent }
