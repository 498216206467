import React, { useEffect } from 'react'
import { navigate } from '@reach/router'

import { useStoreActions, useStoreState } from '../../../store/store.hooks'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { GradientButton, WhiteButton } from '../../../components/Button'
import successCircle from '../../../assets/img/circle.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  doctorFeedbackSuccessContainer: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 560,
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  doctorFeedbackSuccess: {
    borderRadius: '15px',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  layover: {
    zIndex: 3,
  },
  title: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  paragraph: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  footerButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 80,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  footerButton: {
    width: 320,
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.4);',
    margin: '0 10px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  boldText: {
    fontWeight: 'bold',
  },
}))

const DoctorFeedbackSuccess = () => {
  const classes = useStyles()

  const { fetchProviderInfo, putAvailability } = useStoreActions(
    (actions) => actions.providerInfo
  )
  const { providerInfoContents } = useStoreState((state) => state.providerInfo)

  const { t } = useTranslation('doctorFeedbackSuccess')
  useEffect(() => {
    fetchProviderInfo({})
  }, [fetchProviderInfo])

  const findNewPatient = async () => {
    await putAvailability(true)
    navigate('/professional/patient-search')
  }

  return (
    <Grid container className={classes.doctorFeedbackSuccess}>
      <div className={classes.bgLayover}></div>
      <Grid container className={classes.layover}>
        <Grid item xs={12} className={classes.doctorFeedbackSuccessContainer}>
          <img src={successCircle} />
          <Typography className={classes.title} variant="h1" component="h2">
            {t<string>('title')}
          </Typography>
          <Typography className={classes.paragraph} component="p">
            {t<string>('subtitle')}
          </Typography>
          {providerInfoContents?.availableRequesters ? (
            <Typography className={classes.paragraph} component="p">
              {t<string>('onlinePatients1')}
              <span className={classes.boldText}>
                {providerInfoContents?.availableRequesters.toString()}
              </span>
              {t<string>('onlinePatients2')}
            </Typography>
          ) : (
            <Typography className={classes.paragraph} component="p">
              {t<string>('onlinePatients3')}
            </Typography>
          )}
          <div className={classes.footerButtons}>
            <GradientButton
              className={classes.footerButton}
              onClick={() => findNewPatient()}
            >
              {t<string>('nextPatient')}
            </GradientButton>
            <WhiteButton
              onClick={() => navigate('/professional')}
              className={classes.footerButton}
            >
              <>
                {t<string>('continue')}
                <ArrowForwardIosIcon />
              </>
            </WhiteButton>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { DoctorFeedbackSuccess }
