const isIosSafari = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
  const webkit = !!ua.match(/WebKit/i)
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i)

  return iOSSafari
}

const isStaging = () => {
  return (
    window.location.host.indexOf('teszt') > -1 ||
    window.location.host.indexOf('test') > -1
  )
}

const isLocal = () => {
  return (
    window.location.host.indexOf('local') > -1 ||
    window.location.host.indexOf('ngrok') > -1
  )
}

export { isIosSafari, isStaging, isLocal }
