import React, { ChangeEvent, useState, useEffect } from 'react'

import { navigate } from '@reach/router'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Grid, Container } from '@material-ui/core'

import { FormSelect } from '../../components/FormSelect'
import { Heading } from '../../components/Text'
import { FormInput } from '../../components/FormInput'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button/GradientButton'
import { post, put } from '../../services/request'
import _ from 'lodash'
import { useStoreActions } from '../../store/store.hooks'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  headerContainer: {
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  addressForm: {
    marginTop: '50px',
    paddingBottom: '50px',
  },
  submitButton: {
    height: '50px',
  },
  input: {
    marginBottom: '30px',
  },
  inputSmall: {
    width: '25%',

    [theme.breakpoints.down('xs')]: {
      width: '45%',
    },
  },
}))

type FormData = {
  zipCode: string
  city: string
  addressLine1: string
  addressLine2: string
  name: string
  country: string
}

export const BillingAddress = (props: any) => {
  const {
    watch,
    formState: { errors },
    register,
    clearErrors,
    handleSubmit,
    setValue,
  } = useForm<FormData>()
  const { t } = useTranslation('billingAddress')
  const countryOptions = [{ value: 'HU', label: 'Magyarország' }]

  const { fetchProfile } = useStoreActions((actions) => actions.profile)

  const editAddress = props.location.state?.address

  const { ref: addressLine1InputRef, ...addressLine1InputProps } = register(
    'addressLine1',
    {
      required: t<string>('error'),
    }
  )

  const { ref: addressLine2InputRef, ...addressLine2InputProps } =
    register('addressLine2')

  const { ref: cityInputRef, ...cityInputProps } = register('city', {
    required: t<string>('error'),
  })

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t<string>('error'),
  })

  const { ref: zipCodeInputRef, ...zipCodeInputProps } = register('zipCode', {
    required: t<string>('error'),
  })

  const onSubmit = handleSubmit(async (values) => {
    const countryName = _.find(countryOptions, {
      value: editAddress?.countryCode ?? values.country,
    })?.label

    const body = {
      city: values.city,
      misc: values.addressLine2,
      postalCode: Number(values.zipCode),
      countryCode: editAddress?.countryCode ?? values.country,
      countryName: countryName,
      streetAddress: values.addressLine1,
      userGivenName: values.name,
      id: editAddress?.id ?? null,
    }

    if (editAddress) {
      await put<{ message: string }>('/user/address', body)
    } else {
      await post<{ message: string }>('/user/address', body)
    }
    fetchProfile({})
    navigate(-1)
  })

  const classes = useStyles()

  const [countryIndex, setCountryIndex] = useState(
    editAddress?.countryCode ?? 0
  )

  const handleCountryChange = (event: ChangeEvent<{ value: unknown }>) => {
    setValue('country', event.target.value as string)
    setCountryIndex(event.target.value as number)
  }

  return (
    <div className={classes.container}>
      <NavigateBack />
      <Grid item xs={12} className={classes.headerContainer}>
        <Heading>
          {editAddress ? t<string>('heading2') : t<string>('heading')}
        </Heading>
      </Grid>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Grid>
          <Container maxWidth="sm" className={classes.addressForm}>
            <form onSubmit={onSubmit}>
              <FormSelect
                name="country"
                label={t<string>('countryLabel')}
                valueIndex={countryIndex}
                valueOptions={countryOptions}
                handleChange={(event: ChangeEvent<{ value: unknown }>) => {
                  handleCountryChange(event)
                }}
                register={() => {
                  register('country')
                }}
              />

              <FormInput
                inputName="zipCode"
                error={!!errors.zipCode}
                value={watch('zipCode')}
                defaultValue={editAddress?.postalCode ?? null}
                label={t<string>('zipCodeLabel')}
                clearError={() => clearErrors('zipCode')}
                placeholder={t<string>('zipCodePlaceholder')}
                className={classes.input + ' ' + classes.inputSmall}
                errorMessage={(errors?.zipCode?.message as string) || ''}
                inputRef={zipCodeInputRef}
                {...zipCodeInputProps}
              />
              <FormInput
                inputName="city"
                error={!!errors.city}
                value={watch('city')}
                defaultValue={editAddress?.city ?? null}
                label={t<string>('cityLabel')}
                clearError={() => clearErrors('city')}
                placeholder={t<string>('cityPlaceholder')}
                className={classes.input}
                errorMessage={(errors?.city?.message as string) || ''}
                inputRef={cityInputRef}
                {...cityInputProps}
              />
              <FormInput
                inputName="addressLine1"
                error={!!errors.addressLine1}
                value={watch('addressLine1')}
                defaultValue={editAddress?.streetAddress ?? null}
                label={t<string>('addressLine1Label')}
                clearError={() => clearErrors('addressLine1')}
                placeholder={t<string>('addressLine1Placeholder')}
                className={classes.input}
                errorMessage={(errors?.addressLine1?.message as string) || ''}
                inputRef={addressLine1InputRef}
                {...addressLine1InputProps}
              />
              <FormInput
                inputName="addressLine2"
                error={!!errors.addressLine2}
                value={watch('addressLine2')}
                defaultValue={editAddress?.misc ?? null}
                label={t<string>('addressLine2Label')}
                clearError={() => clearErrors('addressLine2')}
                placeholder={t<string>('addressLine2Placeholder')}
                className={classes.input}
                errorMessage={(errors?.addressLine2?.message as string) || ''}
                inputRef={addressLine2InputRef}
                {...addressLine2InputProps}
              />
              <FormInput
                inputName="name"
                error={!!errors.name}
                value={watch('name')}
                defaultValue={editAddress?.userGivenName ?? null}
                label={t<string>('nameLabel')}
                clearError={() => clearErrors('name')}
                placeholder={t<string>('namePlaceholder')}
                className={classes.input}
                errorMessage={(errors?.name?.message as string) || ''}
                inputRef={nameInputRef}
                {...nameInputProps}
              />
              <GradientButton
                type={'submit'}
                className={classes.submitButton}
                onClick={onSubmit}
              >
                {t<string>('submitButton')}
              </GradientButton>
            </form>
          </Container>
        </Grid>
      </Container>
    </div>
  )
}
