import React from 'react'

import firefox0 from '../../../../../assets/img/cam_tutorial/firefox0.png'
import firefox1 from '../../../../../assets/img/cam_tutorial/firefox1.png'

const FirefoxCamHelp = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: 30 }}>
      <p>1. Kattints az alábbi képen láthatö X ikonra </p>
      <div
        style={{
          backgroundImage: `url(${firefox0})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'left top',
          backgroundSize: '140%',
        }}
      ></div>
      <p>
        1. Kattints az alábbi képen látható 'Allow' vagy 'Engedélyezem gombra'{' '}
      </p>
      <div
        style={{
          backgroundImage: `url(${firefox1})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'left',
          backgroundSize: '120%',
        }}
      ></div>
    </div>
  )
}

export { FirefoxCamHelp }
