import { singletonHook } from 'react-singleton-hook'
import { useStoreState } from '../store/store.hooks'

import * as Ably from 'ably'
import { useEffect, useState } from 'react'

const init = undefined
export const useAblyImpl = () => {
  const { profileContents } = useStoreState((state) => state.profile)
  const [ably, setAbly] = useState(undefined as Ably.Realtime | undefined)
  useEffect(() => {
    if (!profileContents.id) return
    const ably = new Ably.Realtime({
      key: 'KDaOGg.iRYsbw:VNREb8YvbQ7qX2Jl',
      clientId: profileContents.id.toString(),
    })
    setAbly(ably)
  }, [profileContents.id])
  return ably
}

export const useAbly = singletonHook(init, useAblyImpl)
