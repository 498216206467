import { useStoreState } from '../../store/store.hooks'

const FeatureAwareComponent = ({ requires, children }) => {
  if (!requires) {
    console.error(
      '[FeatureAwareComponent] "requires" prop has an undefined value'
    )

    throw new Error(
      '[FeatureAwareComponent] "requires" prop has an undefined value'
    )
  }

  const { accountFeaturesBySku: features } = useStoreState(
    (state) => state.subscription
  )

  const canSee = (features && features[requires]) || false

  //console.log('[FeatureAwareComponent] can see ', requires, '? ->', canSee)

  if (canSee) return children

  return null
}

FeatureAwareComponent.displayName = 'FeatureAwareComponent'
export { FeatureAwareComponent }
export default FeatureAwareComponent
