import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { FormInput } from '../../components/FormInput'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formInputRow: {
    marginTop: '2rem',
  },
}))

const PediatricsSpecialization = ({ form }) => {
  const classes = useStyles()
  const { t } = useTranslation('complaintsDetail')

  const {
    register,
    formState: { errors },
    clearErrors,
  } = form

  const { ref: childNameInputRef, ...childNameInputProps } =
    register('childName')
  const { ref: childAgeInputRef, ...childAgeInputProps } = register('childAge')

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.formInputRow}>
          <FormInput
            placeholder={t('childName')}
            label={t('childName')}
            inputName="childName"
            error={!!errors.childName}
            errorMessage={t('emptyField')}
            clearError={() => {
              clearErrors('childName')
            }}
            inputRef={childNameInputRef}
            {...childNameInputProps}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={4} className={classes.formInputRow}>
          <FormInput
            type="number"
            placeholder={t('childAge')}
            label={t('childAge')}
            inputName="childAge"
            error={!!errors.childAge}
            errorMessage={t('emptyField')}
            clearError={() => {
              clearErrors('childAge')
            }}
            minNumericValue={0}
            maxNumericValue={18}
            defaultValue={''}
            inputRef={childAgeInputRef}
            {...childAgeInputProps}
          />
        </Grid>
      </Grid>
    </>
  )
}

PediatricsSpecialization.displayName = 'PediatricsSpecialization'
export { PediatricsSpecialization }
export default PediatricsSpecialization
