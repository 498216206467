import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { Heading, SubHeading } from '../../components/Text'
import { Step } from '../../components'

const useStyles = makeStyles((theme) => ({
  outageContainer: {
    padding: '60px 30px 90px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
}))

const Outage = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('outage')

  return (
    <>
      <Grid item xs={12} className={classes.outageContainer}>
        <Heading>{t('heading')}</Heading>
        <SubHeading>{t('subheading')}</SubHeading>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Step
              theme="md"
              title={t('descriptionLine1')}
              description={t('descriptionLine2')}
              onNavigate={null}
              nonInteractive={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

Outage.displayName = 'Outage'
export { Outage }
export default Outage
