import React, { useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import CallIcon from '@material-ui/icons/Call'

import { WhiteButton } from '../../../components/Button'
import { useStoreState, useStoreActions } from '../../../store/store.hooks'
import { navigate } from '@reach/router'

import { playRingingSoundEffect } from '../../../lib/soundEffects'
import { useInterval } from '../../../helpers/useInterval'
import { useTranslation } from 'react-i18next'
import { SPECIALIZATIONS } from '../../../constants'

const useStyles = makeStyles((theme) => ({
  callRing: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  gradientLayover: {
    borderRadius: 15,
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    minHeight: 560,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  callRingContainer: {
    zIndex: 3,
  },
  doctorName: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  doctorTitle: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  avatar: {
    width: 128,
    height: 128,
    marginBottom: '20px',
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
  },
  acceptCallIcon: {
    marginRight: 5,
  },
  acceptCallButton: {
    padding: '10px 0 ',
    width: 320,
  },
}))

const CallRing = () => {
  const classes = useStyles()
  const { t } = useTranslation('callRing')
  const {
    incomingRequest: { incomingRequestContents },
    request: { providerTypeId, specialization },
  } = useStoreState((state) => state)
  const { fetchIncomingRequest } = useStoreActions(
    (actions) => actions.incomingRequest
  )
  const { requestId } = useStoreState((store) => store.request)
  useEffect(() => {
    if (requestId) {
      fetchIncomingRequest(Number(requestId))
    }
  }, [])

  const { setNotification } = useStoreActions((actions) => actions.layout)
  useInterval(() => {
    playRingingSoundEffect()
    if (requestId) {
      fetchIncomingRequest(Number(requestId))
    }
    if (incomingRequestContents?.status === 'done' && fetch !== undefined) {
      setNotification({
        isOpen: true,
        type: 'success',
        title: t('error1Title'),
      })
      navigate('/patient/rematch')
    }
  }, 3000)
  return (
    <Grid container className={classes.gradientLayover}>
      <div className={classes.bgLayover}></div>
      <Grid container className={classes.callRingContainer}>
        <Grid item xs={12} className={classes.callRing}>
          <Avatar
            alt="doki-profile-picture"
            src={incomingRequestContents?.provider?.avatarUrl}
            className={classes.avatar}
          />
          <Typography
            className={classes.doctorName}
            variant="h1"
            component="h2"
          >
            {`${incomingRequestContents?.provider?.namePrefix ?? ''} ${
              incomingRequestContents?.provider?.lastName ?? ''
            } ${incomingRequestContents?.provider?.firstName ?? ''}`}
          </Typography>
          <Typography className={classes.doctorTitle} component="p">
            {specialization === SPECIALIZATIONS.PEDIATRICS
              ? t<string>('titlePediatrician')
              : t<string>('titleDoc')}
          </Typography>
          <WhiteButton
            className={classes.acceptCallButton}
            onClick={() => navigate('/patient/room')}
          >
            <>
              <CallIcon className={classes.acceptCallIcon} />
              {t<string>('acceptCall')}
            </>
          </WhiteButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { CallRing }
