import { makeStyles } from '@material-ui/core/styles'

const sideMenuStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  sideMenuStyles: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    width: '300px',
  },
  sideMenuContainer: {
    color: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
    height: '100vh',
  },
  closeSideMenu: {
    textAlign: 'right',
    padding: '20px',
    '@media (max-height: 680px)': {
      padding: 0,
    },
  },
  sideMenuUpper: {
    height: '30%',
  },
  avatar: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: '10px',
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    '@media (max-height: 680px)': {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userName: {
    fontSize: '1.2rem',
    fontWeight: 400,
    margin: '0.2rem 0',
    padding: '0.2rem',
    textAlign: 'center',
  },
  userEmail: {
    fontWeight: 300,
  },
  closeIcon: {
    color: '#fff',
  },
  sideMenu: {
    height: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 20px 20px 20px',
    '@media (max-height: 850px)': {
      paddingTop: '50px',
    },
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
  },
  mainLink: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '50px',
    color: '#fff',
    textDecoration: 'none',
    '@media (max-height: 680px)': {
      lineHeight: '40px',
    },
  },
  secondaryLink: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#fff',
    textDecoration: 'none',
    lineHeight: '40px',
    cursor: 'pointer',
    '@media (max-height: 680px)': {
      lineHeight: '30px',
    },
  },
  modal: {
    backgroundColor: 'rgba(30, 40, 100, 0.8)',
  },
  closeSideMenuMobilePadding: {
    padding: '5px',
  },
}))

export { sideMenuStyles }
