/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: '0 2rem 2rem 2rem',
  },
  centered: {
    textAlign: 'center',
  },
  consultationButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  description: {
    margin: '0 2rem 2rem',
    textAlign: 'justify',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
}))

const MissingSurvey = ({}) => {
  const { t } = useTranslation('survey')
  const classes = useStyles()
  const { relativeNavigate } = useRelativeNavigate()

  const handleConsultationClick = () => {
    relativeNavigate('/dashboard')
  }

  return (
    <Grid container className={classes.page}>
      <NavigateBack />
      <Grid item xs={12} className={classes.pageHeader}>
        <Heading>{t('missingSurveyHeader')}</Heading>
      </Grid>
      <Grid item xs={12} className={classes.description}>
        <Typography>{t('missingSurveyDescription')}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.buttonContainer}
      >
        <Grid item xs={6} className={classes.centered}>
          <GradientButton
            className={classes.consultationButton}
            onClick={handleConsultationClick}
          >
            {t('consultationButton')}
          </GradientButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

MissingSurvey.displayName = 'MissingSurvey'

MissingSurvey.propTypes = {}

MissingSurvey.defaultProps = {}

export default MissingSurvey
export { MissingSurvey }
