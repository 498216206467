import { makeStyles } from '@material-ui/core/styles'

const consultancyOptionsStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    minWidth: 275,
    borderRadius: '15px',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
    color: '#fff',
    margin: '25px',
    padding: '25px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      margin: '10px 20px',
    },
    height: '100%',
  },
  consultancyActions: {
    display: 'flex',
    flexDirection: 'column',
  },
  nextAppointment: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  nextAppointMentIcon: {
    margin: '0 3px',
  },
  pos: {
    marginBottom: 12,
    fontWeight: 200,
  },
  consultancyPrice: {
    margin: '40px 0 20px 0',
  },
  consultancyPriceMobile: {
    marginBottom: 0,
  },
  coronaCardButtonLabel: {
    marginBottom: 10,
    padding: '0 3px',
    fontWeight: 200,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  coronaCardButtonLabelIcon: {
    margin: '0 3px -5px 3px',
  },
  placeholder: {
    marginTop: theme.spacing(2),
  },
}))

export { consultancyOptionsStyles }
