import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

type Props = {
  className?: string
  onClick?: any
  type?: 'submit' | 'button'
  disabled?: boolean
  children: React.ReactNode
}

const useStyles = makeStyles(() => ({
  gradientButton: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    width: '100%',
    borderRadius: '10px',
    textAlign: 'center',
    color: '#fff',
    textTransform: 'unset',
    fontSize: '16px',
  },
  disabled: {
    color: '#fff !important',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    opacity: 0.6,
  },
}))

const GradientButton: FunctionComponent<Props> = ({
  className = '',
  children,
  onClick,
  type = 'button',
  disabled,
}) => {
  const classes = useStyles()
  const buttonClassName = [classes.gradientButton, className].join(' ')

  return (
    <Button
      type={type}
      className={buttonClassName}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      classes={{
        disabled: classes.disabled,
      }}
    >
      {children}
    </Button>
  )
}

export { GradientButton }
