import { useState, useCallback } from 'react'
import {
  AudioInputTest,
  AudioOutputTest,
  testAudioInputDevice,
  testAudioOutputDevice,
} from '@twilio/rtc-diagnostics'

import { getAudioLevelPercentage, getStandardDeviation } from '../../utils'
import { INPUT_TEST_DURATION, RECORD_DURATION } from '../../constants'

let audioInputTest
let audioOutputTest

const getErrorMessage = (error) => {
  let message = 'An unknown error has occurred'
  if (error) {
    message = error.domError ? error.domError.toString() : error.message
  }
  return message
}

const useAudioTest = () => {
  const [isRecording, setIsRecording] = useState(false)
  const [isAudioInputTestRunning, setIsAudioInputTestRunning] = useState(false)
  const [isAudioOutputTestRunning, setIsAudioOutputTestRunning] =
    useState(false)
  const [inputLevel, setInputLevel] = useState(0)
  const [outputLevel, setOutputLevel] = useState(0)
  const [playbackURI, setPlaybackURI] = useState('')
  const [error, setError] = useState('')

  const playAudio = useCallback((options) => {
    options = { doLoop: false, ...options }
    audioOutputTest = testAudioOutputDevice(options)
    setIsAudioOutputTestRunning(true)

    audioOutputTest.on(AudioOutputTest.Events.Volume, (value) => {
      setOutputLevel(getAudioLevelPercentage(value))
    })

    audioOutputTest.on(AudioOutputTest.Events.End, (report) => {
      setIsAudioOutputTestRunning(false)
      setOutputLevel(0)

      const stdDev = getStandardDeviation(report.values)
      if (stdDev === 0) {
        setError('No audio detected')
      }
    })

    audioOutputTest.on(AudioOutputTest.Events.Error, (diagnosticError) => {
      console.debug('error', diagnosticError)
      setError(getErrorMessage(diagnosticError))
    })
  }, [])

  const readAudioInput = useCallback(
    (options) => {
      if (audioInputTest) {
        audioInputTest.stop()
      }

      console.debug('AudioInputTest running')
      const duration = options.enableRecording
        ? RECORD_DURATION
        : INPUT_TEST_DURATION
      options = { duration, ...options }
      audioInputTest = testAudioInputDevice(options)

      setIsAudioInputTestRunning(true)
      if (options.enableRecording) {
        console.debug('Recording audio')
        setIsRecording(true)
      }

      audioInputTest.on(AudioInputTest.Events.Volume, (value: number) => {
        setInputLevel(getAudioLevelPercentage(value))
      })

      audioInputTest.on(AudioInputTest.Events.End, (report) => {
        if (playbackURI && report.recordingUrl) {
          URL.revokeObjectURL(playbackURI)
        }

        if (report.recordingUrl) {
          setPlaybackURI(report.recordingUrl)
        }

        setIsRecording(false)
        setIsAudioInputTestRunning(false)
      })

      audioInputTest.on(AudioInputTest.Events.Error, (diagnosticError) => {
        console.debug('error', diagnosticError)
        setError(getErrorMessage(diagnosticError))
      })
      audioInputTest.on(AudioInputTest.Events.Warning, (name) => {
        console.debug('warning', name)
      })
      audioInputTest.on(AudioInputTest.Events.WarningCleared, (name) => {
        console.debug('warning-cleared', name)
      })
    },
    [playbackURI]
  )

  const stopAudioTest = useCallback(() => {
    if (audioInputTest) {
      audioInputTest.stop()
      setInputLevel(0)
    }
    if (audioOutputTest) {
      audioOutputTest.stop()
      setOutputLevel(0)
    }
  }, [])

  return {
    error,
    setError,
    isRecording,
    isAudioInputTestRunning,
    isAudioOutputTestRunning,
    playAudio,
    playbackURI,
    readAudioInput,
    stopAudioTest,
    inputLevel,
    outputLevel,
  }
}

export default useAudioTest
export { useAudioTest }
