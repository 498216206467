import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerBlock: {
    paddingBottom: 5,
  },
  loginContainer: {
    display: 'flex',
    padding: '0 60px 80px 60px',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#fff',
    borderRadius: '15px',

    [theme.breakpoints.down('xs')]: {
      padding: '12px',
      paddingBottom: '50px',
    },
  },
  loginForm: {
    marginTop: '50px',
    padding: 0,
  },
  loginInput: {
    marginBottom: '30px',
  },
  loginButton: {
    height: '50px',
    marginTop: '15px',
  },
  login: {
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  forgotPassword: {
    cursor: 'pointer',
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#F1306E',
    position: 'absolute',
    right: '27%',
    marginTop: '-3px',

    [theme.breakpoints.down('xs')]: {
      right: '10%',
    },
  },
}))

export { useStyles }
