import React, { useEffect } from 'react'
import { navigate } from '@reach/router'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Box,
} from '@material-ui/core'

import { Heading, SubHeading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { WhiteButton } from '../../components/Button/WhiteButton'

import { FEATURES, SPECIALIZATIONS } from '../../constants'
import { useStoreState } from '../../store/store.hooks'
import { useStyles } from './styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../../util'

const ComplaintSum = () => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('complaintsSum')

  const providerTypeId = useStoreState((state) => state.request.providerTypeId)
  const selectedComplaintIds = useStoreState(
    (state) => state.request.selectedComplaintIds
  )
  const requestDescription = useStoreState(
    (state) => state.request.requestDescription
  )
  const complaints = useStoreState((state) => state.request.complaints)
  const requesterInfo = useStoreState((state) => state.request.requesterInfo)
  const specialization = useStoreState((state) => state.request.specialization)
  const subscription = useStoreState((state) => state.subscription)
  const { featuresBySku } = subscription

  const language = normalizeLanguage(i18n.language)
  const feature =
    specialization === SPECIALIZATIONS.PEDIATRICS
      ? FEATURES.ON_DEMAND_PEDIATRICIAN
      : language === 'hu'
      ? FEATURES.ON_DEMAND_MEDICAL_DOCTOR
      : FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL
  const featurePrice = feature && featuresBySku && featuresBySku[feature]
  const formattedPrice = featurePrice && featurePrice.formattedPrice

  useEffect(() => {
    if (!requesterInfo) navigate('/')
  }, [requesterInfo])

  const selectedComplaints = selectedComplaintIds
    .map((complaintId) => _.find(complaints, { id: complaintId })?.name)
    .join(', ')

  const getPriceContent = () => {
    return (
      <Box>
        <Typography className={classes.originalPrice}>
          {formattedPrice}
        </Typography>
      </Box>
    )
  }

  const getPricingLine = () => {
    if (!formattedPrice) {
      console.warn('Could not determine formatted price', featurePrice)
      return null
    }

    let line = t('pricingLineMd')
    if (specialization === SPECIALIZATIONS.PEDIATRICS) {
      line = t('pricingLinePediatrician')
    }

    return line.replace('{formattedPrice}', formattedPrice)
  }

  return (
    <Grid container className={classes.complaintsSum}>
      <NavigateBack />
      <Grid item xs={12} className={classes.complaintSumContainer}>
        <Heading enableTrustLogo>{t<string>('heading')}</Heading>
        <div className={classes.mobileMarginTop30}>
          <span className={classes.label}>{t<string>('label1')}</span>
        </div>
        <ExpansionPanel square expanded={true} className={classes.root}>
          <ExpansionPanelSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={classes.consultWithContainer}
          >
            <Typography className={classes.consultWith}>
              {specialization === SPECIALIZATIONS.PEDIATRICS
                ? t<string>('pediatricsConsultation')
                : t<string>('doctorConsultancy')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.complaintSum}>
            <span className={classes.label}>{t<string>('myComplaint')}</span>
            <Typography className={classes.consultWith}>
              {selectedComplaints}
            </Typography>
            <Typography className={classes.complaintDetail}>
              {requestDescription}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div className={classes.faqContainer}>
          <Heading>{t<string>('faqTitle')}</Heading>
          <SubHeading>{t<string>('subheading')}</SubHeading>
          {providerTypeId === 2 ? (
            <ul className={classes.faqList}>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine1')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine2')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine3')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine4')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine5')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('psychoListLine6')}
              </li>
            </ul>
          ) : (
            <ul className={classes.faqList}>
              <li className={classes.faqListItem}>
                <strong>{t<string>('doctorListLine1')}</strong>
              </li>
              <li className={classes.faqListItem}>
                {t<string>('doctorListLine2')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('doctorListLine3')}
              </li>
              <li className={classes.faqListItem}>{getPricingLine()}</li>
              <li className={classes.faqListItem}>
                {t<string>('doctorListLine5')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('doctorListLine6')}
              </li>
              <li className={classes.faqListItem}>
                {t<string>('doctorListLine7')}
              </li>
            </ul>
          )}
        </div>
      </Grid>
      <Grid xs={12} container className={classes.complaintDetailFooter}>
        <div className={classes.consultancyPriceContainer}>
          <div className={classes.pricaLayoutHelper}>
            <span className={classes.label}>
              {t<string>('consultancyPrice')}
            </span>
            {getPriceContent()}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <WhiteButton
            onClick={() => navigate('/patient/audio-video-test')}
            className={classes.complaintDetailFooterButton}
          >
            {t<string>('buttonText')}
            <ArrowForwardIosIcon />
          </WhiteButton>
        </div>
      </Grid>
    </Grid>
  )
}

export { ComplaintSum }
