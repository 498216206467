import React, { useEffect, useState } from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { GradientButton, WhiteButton } from '../Button'
import { FormInput } from '../FormInput'
import { useForm } from 'react-hook-form'
import { post, deleteReq as axiosDelete } from '../../services/request'
import { navigate } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  accountGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  changeButton: {
    border: '2px solid #6473AF',
    width: 'auto',
  },
  deleteButton: {
    border: '2px solid gray',
    width: 'auto',
  },
  description: {
    color: '#1E2864',
    margin: '1rem 0',
    fontSize: '0.8rem',
    textAlign: 'justify',
  },
  header: {
    color: '#6473AF',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginLeft: '15px',
    marginBottom: '10px',
    textTransform: 'uppercase',
  },
  navigateButton: {
    border: '2px solid #6473AF',
    width: '100%',
  },
  voucherCodeError: {
    margin: '1rem 0',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  voucherInput: {
    marginBottom: '0.5rem',
  },
}))

const AssociatedAccount = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('features')
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(null)

  const { account, activeVoucher } = useStoreState(
    (state) => state.subscription
  )
  const { fetchSubscription } = useStoreActions(
    (actions) => actions.subscription
  )

  const hasAssociatedAccount = account && account.key
  const hasGeneralPublicAccount =
    hasAssociatedAccount && account.key === 'general-public'

  const {
    watch,
    formState: { errors },
    register,
    clearErrors,
  } = useForm({})

  const { ref: voucherInputRef, ...voucherInputProps } = register('voucher', {
    required: t('missingField'),
  })

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleDelete = async () => {
    setError(null)

    try {
      const result = await axiosDelete('/vouchers/association')

      if (!result.error) {
        fetchSubscription()
      } else {
        setError(result.error)
      }
    } catch (err) {
      setError(err)
    } finally {
    }
  }

  const handleSave = async () => {
    setError(null)

    try {
      const result = await post('/vouchers/associate', {
        voucherCode: watch('voucher'),
      })

      if (!result.error) {
        fetchSubscription()
        setIsEditing(false)
      } else {
        setError(result.error)
      }
    } catch (err) {
      setError(err)
    } finally {
    }
  }

  const handleAccountNavigation = () => {
    navigate('/account')
  }

  return (
    <>
      <form>
        <Grid item xs={12}>
          <Typography className={classes.header}>
            {t('associatedAccountHeader')}
          </Typography>
          <Typography className={classes.description}>
            {t('associatedAccountDescription')}
          </Typography>

          {!isEditing && hasAssociatedAccount && (
            <Grid container justifyContent="space-between">
              <Grid item className={classes.accountGrid}>
                {account.fullName}
              </Grid>
              <Grid item>
                <WhiteButton
                  onClick={handleEdit}
                  className={classes.changeButton}
                >
                  {t('associatedAccountChangeButtonText')}
                </WhiteButton>
              </Grid>
              <Grid item>
                <WhiteButton
                  onClick={handleDelete}
                  className={classes.deleteButton}
                  disabled={hasGeneralPublicAccount}
                >
                  {t('deleteAccountButtonText')}
                </WhiteButton>
              </Grid>
            </Grid>
          )}
          {!isEditing && !hasAssociatedAccount && (
            <>
              <WhiteButton
                onClick={handleAccountNavigation}
                className={classes.navigateButton}
              >
                {t('navigateToAccountPageLinkText')}
              </WhiteButton>
            </>
          )}

          {isEditing && (
            <Grid container justifyContent="space-between">
              <Grid item className={classes.accountGrid} xs={12}>
                <FormInput
                  inputName="voucher"
                  error={!!errors.voucher}
                  value={watch('voucher')}
                  label={t('askForVoucherInputLabel')}
                  clearError={() => clearErrors('voucher')}
                  placeholder={t('askForVoucherPlaceholder')}
                  errorMessage={String(errors?.voucher?.message ?? '')}
                  className={classes.voucherInput}
                  inputRef={voucherInputRef}
                  {...voucherInputProps}
                />

                {error && (
                  <>
                    <Typography className={classes.voucherCodeError}>
                      {t('askForVoucherInvalidCode')}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item justifyContent="center" xs={12}>
                <GradientButton onClick={handleSave}>
                  {t('associatedAccountChangeButtonText')}
                </GradientButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  )
}

AssociatedAccount.displayName = 'AssociatedAccount'
export { AssociatedAccount }
export default AssociatedAccount
