/*
    Copyright © DokiApp, 2021
*/

import React from 'react'
import { Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GradientButton } from '../../../../components/Button'
import micAndCamAccessPopupImage from '../../../../assets/img/miccam-access-popup.png'

const useStyles = makeStyles((theme) => ({
  illustration: {
    height: '155px',
    width: '314px',
    boxShadow: '5px 5px 10px #666',
    [theme.breakpoints.down('md')]: {
      height: '124px',
      width: '251px',
      marginTop: '1rem',
    },
  },
}))

const CheckPermissions = ({ onNext }) => {
  const { t } = useTranslation('twilio-diagnostics')
  const classes = useStyles()
  const IGNORE_WEBCAM_FAILURE = true

  const savePermissionsAndHandleNext = () => {
    if (onNext instanceof Function) {
      sessionStorage.setItem('microphoneAndCameraAccess', 'granted')
      onNext()
    }
  }

  const handleClick = async () => {
    try {
      // get audio and video permissions then stop the tracks
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(async (mediaStream) => {
          mediaStream.getTracks().forEach((track) => {
            track.stop()
          })
        })
      // The devicechange event is not fired after permissions are granted, so we fire it
      // ourselves to update the useDevices hook. The 500 ms delay is needed so that device labels are available
      // when the useDevices hook updates.
      setTimeout(
        () => navigator.mediaDevices.dispatchEvent(new Event('devicechange')),
        500
      )

      savePermissionsAndHandleNext()
    } catch (error) {
      if (IGNORE_WEBCAM_FAILURE === true) {
        savePermissionsAndHandleNext()
      } else {
        console.warn('Video test failed', error)
      }
    }
  }

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={5}>
          <Typography variant="h4" gutterBottom>
            {t('checkPermissionsFirstLine')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t('checkPermissionsSecondLine')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>{t('checkPermissionsGrantAccess')}</strong>
          </Typography>

          <GradientButton onClick={handleClick}>
            {t('checkPermissionsGrantAccessButtonText')}
          </GradientButton>
        </Grid>

        <Grid item md={5}>
          <img
            src={micAndCamAccessPopupImage}
            alt={t('checkPermissionsIllustration')}
            className={classes.illustration}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

CheckPermissions.displayName = 'CheckPermissions'

CheckPermissions.propTypes = {}

CheckPermissions.defaultProps = {}

export default CheckPermissions
export { CheckPermissions }
