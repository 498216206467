import React, { Fragment } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions } from '../../../../../store/store.hooks'

import Container from '@material-ui/core/Container'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FirstPageTutorialContent } from './GuidelineContent/FirstPageTutorialContent'
import { SecondPageTutorialContent } from './GuidelineContent/SecondPageTutorialContent'
import { ThirdPageTutorialContent } from './GuidelineContent/ThirdPageTutorialContent'
import { FourthPageTutorialContent } from './GuidelineContent/FourthPageTutorialContent'
import { FifthPageTutorialContent } from './GuidelineContent/FifthPageTutorialContent'
import { SixthPageTutorialContent } from './GuidelineContent/SixthPageTutorialContent'

import arrowLeft from '../../../../../assets/img/guideline_tutorial/arrowLeft.png'
import arrowRight from '../../../../../assets/img/guideline_tutorial/arrowRight.png'
import cancel from '../../../../../assets/img/guideline_tutorial/cancel.png'

const useStyles = makeStyles((theme) => ({
  modalBody: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    fontFamily: 'Nunito, Arial, sans-serif',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      minHeight: '90vh',
    },

    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  step: {
    margin: 0,
    padding: 0,

    '& svg': {
      color: 'transparent',
      border: '1px solid white',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
    },
    '& svg.MuiStepIcon-active': {
      color: 'white',
      borderColor: 'transparent',
      width: '15px',
      height: '15px',
    },
  },
  continue: {
    fontSize: 26,
    color: '#AAE0FF',
    textTransform: 'none',
    fontWeight: 'bold',
    marginBottom: '8%',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
      lineHeight: 1.4,
    },
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cancelImage: {
    marginTop: '10px',
    width: '30px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
      width: '15px',
    },
  },
  directionArrowLeft: {
    '&:hover': {
      backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('xs')]: {
      left: '-16px',
    },
  },
  directionArrowRight: {
    '&:hover': {
      backgroundColor: 'transparent',
    },

    [theme.breakpoints.down('xs')]: {
      right: '-16px',
    },
  },
  directionArrowImage: {
    width: '35px',

    [theme.breakpoints.down('sm')]: {
      width: '15px',
    },
  },
  stepper: {
    marginTop: '8%',
    marginBottom: '4%',
    padding: '15px',
  },
}))

function getSteps() {
  return [
    'FirstPage',
    'SecondPage',
    'ThirdPage',
    'FourthPage',
    'FifthPage',
    'SixthPage',
  ]
}

const Guideline = () => {
  const classes = useStyles()
  const { setHelperModal } = useStoreActions((actions) => actions.layout)
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const { t } = useTranslation('guideline')

  const modalContent = () => {
    switch (activeStep) {
      case 0:
        return <FirstPageTutorialContent />
      case 1:
        return <SecondPageTutorialContent />
      case 2:
        return <ThirdPageTutorialContent />
      case 3:
        return <FourthPageTutorialContent />
      case 4:
        return <FifthPageTutorialContent />
      case 5:
        return <SixthPageTutorialContent />
      default:
        return <FirstPageTutorialContent />
    }
  }

  const closeModal = () => {
    setHelperModal({ isOpen: false, type: '' })
    localStorage.setItem('guideline', 'hide')
  }

  const totalSteps = () => {
    return steps.length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const handleNext = () => {
    if (isLastStep()) {
      return
    }
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const content = modalContent()

  const isFirstStep = activeStep === 0

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.modalBody}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          flexGrow={0.9}
        >
          {content}
        </Box>

        <Box alignSelf="center">
          {isFirstStep && (
            <Button
              onClick={handleStep(1)}
              variant="text"
              className={classes.continue}
            >
              <>
                {t<string>('startGuide')}
                <Box ml={2} clone>
                  <img src={arrowRight} width={16} alt="" />
                </Box>
              </>
            </Button>
          )}

          {!isFirstStep && (
            <Stepper
              nonLinear
              className={classes.stepper}
              activeStep={activeStep}
              connector={<Fragment />}
              style={{ backgroundColor: 'transparent' }}
            >
              {steps.map((label, index) => (
                <Step key={label} className={classes.step}>
                  <StepButton
                    className={classes.step}
                    disableRipple
                    icon=" "
                    onClick={handleStep(index)}
                    style={{ backgroundColor: 'transparent' }}
                  ></StepButton>
                </Step>
              ))}
            </Stepper>
          )}
        </Box>

        {!isFirstStep && (
          <>
            <Box
              left={'2%'}
              top={0}
              bottom={0}
              display="flex"
              position="absolute"
            >
              <Button
                disabled={isFirstStep}
                className={classes.directionArrowLeft}
                onClick={handleBack}
              >
                <img
                  src={arrowLeft}
                  className={classes.directionArrowImage}
                  alt=""
                />
              </Button>
            </Box>
            {activeStep !== steps.length - 1 && (
              <Box
                right={'2%'}
                top={0}
                bottom={0}
                display="flex"
                position="absolute"
              >
                <Button
                  disabled={activeStep === steps.length - 1}
                  className={classes.directionArrowRight}
                  onClick={handleNext}
                >
                  <img
                    src={arrowRight}
                    className={classes.directionArrowImage}
                    alt=""
                  />
                </Button>
              </Box>
            )}
          </>
        )}

        <Box position="absolute" right={10} display="flex">
          <Button onClick={closeModal} className={classes.cancelButton}>
            <img src={cancel} className={classes.cancelImage} alt="" />
          </Button>
        </Box>
      </Container>
    </Fragment>
  )
}

export { Guideline }
