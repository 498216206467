import { get, put } from './request'
import { ProfileContents } from '../store/profile/profile.interface'

export async function fetchProfile() {
  const profileContents = get<ProfileContents>('/user/profile')
  return profileContents
}

export async function fetchCard() {
  const contents = get<any>('/transaction/card')
  return contents
}

export async function updateProfile(updatedProfileContents: any) {
  const resp = await put<any>('/user/profile', updatedProfileContents)
  return resp
}
