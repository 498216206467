/*
    Copyright © DokiApp, 2021
*/

import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { GradientButton } from '../../../../components/Button'

const GetStarted = ({ onNext }) => {
  const { t } = useTranslation('twilio-diagnostics')

  const handleNext = () => {
    if (onNext instanceof Function) {
      onNext()
    }
  }

  return (
    <>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item md={10}>
            <Typography variant="h4" gutterBottom>
              {t('getStartedHeader')}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {t('getStartedFirstLine')}
            </Typography>

            <GradientButton onClick={handleNext}>
              {t('getStartedNextButton')}
            </GradientButton>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

GetStarted.displayName = 'GetStarted'

GetStarted.propTypes = {}

GetStarted.defaultProps = {}

export default GetStarted
export { GetStarted }
