import React, { useCallback } from 'react'
import axios from 'axios'

const BASE_URL = 'https://status.twilio.com/api/v2/components.json'

const useTwilioStatus = () => {
  const getTwilioStatus = useCallback(() => {
    return new Promise((resolve, reject) => {
      axios(BASE_URL)
        .then((response) => {
          const statusObj = {}

          const ALLOWED_COMPONENTS = [
            'Group Rooms',
            'Peer-to-Peer Rooms',
            'Compositions',
            'Recordings',
            'Network Traversal Service',
            'Go Rooms',
          ]

          response.data.components.forEach(({ name, status }) => {
            if (ALLOWED_COMPONENTS.includes(name)) {
              statusObj[name] = status
            }
          })

          resolve(statusObj)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }, [])

  return { getTwilioStatus }
}

export { useTwilioStatus }
export default useTwilioStatus
