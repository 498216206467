//import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../../util'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'

const LanguageAwareComponent = ({
  expectedLanguage = ['hu'],
  action = 'hide',
  replacement,
  children,
}) => {
  const {
    i18n: { language: lng },
  } = useTranslation()

  const language = normalizeLanguage(lng)
  const expectedLanguages =
    typeof expectedLanguage === 'string' ? [expectedLanguage] : expectedLanguage

  if (!Array.isArray(expectedLanguages) || expectedLanguages.length === 0) {
    throw new Error(
      'The prop expectedLanguage is either a string or an array of strings'
    )
  }

  for (const expected of expectedLanguages) {
    if (expected === language) {
      switch (action) {
        case 'hide':
          return null

        case 'replace':
          return replacement

        default:
          throw new Error(
            `The action '${action}' is not supported by the LanguageAwareComponent`
          )
      }
    }
  }

  return children
}

LanguageAwareComponent.displayName = 'LanguageAwareComponent'
LanguageAwareComponent.propTypes = {
  expectedLanguage: PropTypes.oneOf([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  action: PropTypes.oneOf(['hide', 'replace']),
  replacement: PropTypes.any,
}

export { LanguageAwareComponent }
export default LanguageAwareComponent
