import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Heading } from '../Text'
import { FormInput } from '../FormInput'
import { GradientButton } from '../Button'
import { useForm } from 'react-hook-form'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { post } from '../../services/request'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: '1rem',
  },
  grid: {},
  intro: {
    margin: '1rem 0',
    textAlign: 'center',
  },
  paper: {
    padding: '1rem',
  },
  root: {},
  warning: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginTop: '0.5rem',
  },
}))

const AskForVoucher = ({ onDone }) => {
  const classes = useStyles()
  const { t } = useTranslation('features')
  const [disabled, setDisabled] = useState(false)
  const [invalidCode, setInvalidCode] = useState(false)

  const {
    register,
    formState: { errors },
    clearErrors,
    watch,
  } = useForm()

  const { ref: voucherInputRef, ...voucherInputProps } = register('voucher', {
    required: t('missingField'),
  })

  const handleValidation = async () => {
    setInvalidCode(false)

    const voucher = watch('voucher')

    if (!voucher) return

    try {
      const result = await post('/vouchers/voucher-guard', {
        code: voucher,
      })

      if (result.error === 'invalid_code') {
        setInvalidCode(true)
      } else {
        // If validation succeeded, save the voucher with the user
        const associateVoucherResult = await post('/vouchers/associate', {
          accountId: result.accountId,
          voucherId: result.id,
        })

        if (typeof onDone === 'function') {
          onDone(associateVoucherResult)
        }
      }
    } catch (err) {
      console.error('Failed to validate voucher', err)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={3} className={classes.paper}>
          <Heading>{t('askForVoucherHeader')}</Heading>
          <Typography className={classes.intro}>
            {t('askForVoucherIntro')}
          </Typography>

          <Grid container justifyContent="center">
            <form>
              <FormControl>
                <FormLabel>{t('askForVoucherInputLabel')}</FormLabel>
                <FormInput
                  defaultValue={''}
                  inputName="voucher"
                  error={!!errors.voucher}
                  value={watch('voucher')}
                  clearError={() => {
                    clearErrors(['voucher'])
                  }}
                  placeholder={t('askForVoucherPlaceholder')}
                  errorMessage={errors?.voucher?.message ?? ''}
                  className={classes.input}
                  disabled={disabled}
                  inputRef={voucherInputRef}
                  {...voucherInputProps}
                  disableEndAdornment={true}
                />
                <GradientButton
                  className={classes.button}
                  onClick={handleValidation}
                >
                  {t('askForVoucherButtonText')}
                </GradientButton>

                {invalidCode && (
                  <FormHelperText error className={classes.warning}>
                    {t('askForVoucherInvalidCode')}
                  </FormHelperText>
                )}
              </FormControl>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}

AskForVoucher.displayName = 'AskForVoucher'
export { AskForVoucher }
export default AskForVoucher
