import { get } from './request'
import dayjs from 'dayjs'

export async function fetchCallData(requestId: string) {
  const callData = await get<any>(`/call/callByRequestId/${requestId}`)
  return callData
}

export function getTimerString({
  minutes,
  seconds,
}: {
  minutes: number
  seconds: number
}) {
  const minuteString = ('0' + minutes).slice(-2)
  const secondsString = ('0' + seconds).slice(-2)

  return minuteString + ':' + secondsString
}

export function calculateDuration(startTime: Date, endTime: Date) {
  const difference = dayjs(startTime).diff(endTime)
  const duration = {
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  }

  const durationString = getTimerString(duration)
  return durationString
}
