/*
    Copyright © DokiApp, 2021
*/

import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { GradientButton, WhiteButton } from '../../components/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { postNewRequest } from '../../services/request.service'
import { useStoreActions } from '../../store/store.hooks'

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  top: {
    borderRadius: '15px 15px 0 0',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: '15px 15px 0 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  container: {
    zIndex: 3,
  },
  title: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 5,
    fontFamily: 'Nunito, Arial, sans-serif',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  subheader: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'justify',
    padding: '0 10%',

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  question: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  warning: {
    fontSize: 20,
    fontWeight: '200',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  questionBlock: {
    backgroundColor: '#fff',
    padding: 40,
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
    },
  },
  tryAgainButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  dismissButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
  },
  centered: {
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: '2rem',
  },
  infoText: {
    marginTop: '0.5rem',
  },
}))

const Rematch = ({}) => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('rematch')
  const [isLoading, setIsLoading] = useState(false)

  const {
    request: { setRequestId },
  } = useStoreActions((actions) => actions)

  const createNewRequest = async () => {
    const request = await postNewRequest({
      payedAsOneOff: false,
      language: i18n.language,
    })
    setRequestId(request.requestId)
  }

  const handleRetry = async () => {
    setIsLoading(true)
    await createNewRequest()
    navigate('/patient/successful-payment')
    setIsLoading(false)
  }

  const handleDismiss = () => {
    setIsLoading(true)
    navigate('/')
    setIsLoading(false)
  }

  return (
    <>
      {' '}
      <Grid container className={classes.top}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.messageContainer}>
            <Typography className={classes.title} variant="h1" component="h2">
              {t('header')}
            </Typography>
            <Typography className={classes.subheader} component="p">
              {t('subheader1')}
            </Typography>
            <Typography className={classes.subheader} component="p">
              {t('subheader2')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.questionBlock}>
        <Typography className={classes.question} variant="h1" component="h2">
          {t('question')}
        </Typography>
        <Typography className={classes.warning} variant="h1" component="h2">
          {t('warning')}
        </Typography>

        <Grid
          container
          spacing={0}
          justifyContent="center"
          className={classes.buttonContainer}
        >
          <Grid item xs={6} className={classes.centered}>
            <GradientButton
              className={classes.tryAgainButton}
              onClick={handleRetry}
              disabled={isLoading}
            >
              {t('tryAgainButton')}
            </GradientButton>
          </Grid>
          <Grid item xs={6} className={classes.centered}>
            <WhiteButton
              onClick={handleDismiss}
              className={classes.dismissButton}
              disabled={isLoading}
            >
              {t('dismissButton')}
            </WhiteButton>
          </Grid>
          <Grid item xs={6} className={[classes.centered, classes.infoText]}>
            {t('tryAgainInfo')}
          </Grid>
          <Grid item xs={6} className={[classes.centered, classes.infoText]}>
            {t('dismissInfo')}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

Rematch.displayName = 'Rematch'

Rematch.propTypes = {}

Rematch.defaultProps = {}

export default Rematch
export { Rematch }
