import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  doctorCallFinish: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  doctorCallFinishTop: {
    borderRadius: '15px 15px 0 0',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: '15px 15px 0 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  doctorCallFinishContainer: {
    zIndex: 3,
  },
  title: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 5,
    fontFamily: 'Nunito, Arial, sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  duration: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 10%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  consultancyInfo: {
    backgroundColor: '#fff',
    padding: 40,
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
    },
  },
  subheading: {
    fontSize: 20,
    padding: '0 15%',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  buttonContainer: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 10%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  footerButtonProblem: {
    width: 280,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
  },
}))

export { useStyles }
