import React from 'react'
import { navigate } from '@reach/router'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { NavigateBack } from '../../components/Nav/NavigateBack'
import { Heading } from '../../components/Text'
import { Input } from '../../components/Input/Input'
import { WhiteButton } from '../../components/Button/WhiteButton'

import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { sendRequestFeedback } from '../../services/request.service'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const ConsultancyProblem = () => {
  const classes = useStyles()
  const { requestFeedbackDescription, requestId } = useStoreState(
    (state) => state.request
  )
  const {
    request: { setRequestFeedbackDescription },
    layout: { setNotification },
  } = useStoreActions((actions) => actions)
  const { t } = useTranslation('consultacyProblem')

  const setFeedback = async () => {
    const body = {
      requestId: requestId,
      description: requestFeedbackDescription,
      isSatisfied: false,
    }

    try {
      const message = await sendRequestFeedback({ body })

      navigate('/patient/consultancy-problem-sent')
      setNotification({
        isOpen: true,
        type: 'success',
        title: t<string>('successTitle'),
      })
      return message
    } catch (error) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error1Title'),
        description: t<string>('error1Description'),
      })
    }
  }

  return (
    <Grid container className={classes.complaintDetailBackground}>
      <NavigateBack />
      <Grid item xs={12} className={classes.ComplaintDetailContainer}>
        <Heading>{t<string>('heading')}</Heading>
        <div className={classes.complaintDetail}>
          <Typography component="h4" className={classes.subheading}>
            {t<string>('subheading')}
          </Typography>
          <Typography component="p" className={classes.paragraph}>
            {t<string>('paragraph')}
          </Typography>
          <Input
            rows="6"
            value={requestFeedbackDescription ?? ''}
            multiline
            className={classes.complaintDetailInput}
            label={t<string>('inputLabel')}
            onChange={(e) => setRequestFeedbackDescription(e.target.value)}
          />
        </div>
      </Grid>
      <Grid xs={12} container className={classes.complaintDetailFooter}>
        <WhiteButton
          onClick={() => setFeedback()}
          className={classes.complaintDetailFooterButton}
        >
          {t<string>('buttonText')}
          <ArrowForwardIosIcon />
        </WhiteButton>
      </Grid>
    </Grid>
  )
}

export { ConsultancyProblem }
