import { action, thunk } from 'easy-peasy'
import { TipsModel } from './tips.interface'
import { fetchTips } from '../../services/tips.service'

export const tipsModel: TipsModel = {
  tips: [],
  setLanguage: action((state, payload) => {
    state.language = payload
  }),
  fetchTips: thunk(async (actions, payload, helpers) => {
    const { language } = helpers.getState()
    const tips = await fetchTips({ language })
    actions.setTips(tips)
  }),
  setTips: action((state, payload) => {
    state.tips = payload
  }),
}
