import React, { useEffect } from 'react'
import { Grid, Icon, Paper, Typography, makeStyles } from '@material-ui/core'
import { SentimentDissatisfied as SadFaceIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
//import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme) => ({
  root: {},
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  header: {
    fontWeight: 'bold',
    color: '#6473AF',
    fontSize: '1rem',
  },
  paper: {
    padding: '1rem',
    textAlign: 'center',
  },
}))

const FeatureDisabled = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('features')

  return (
    <>
      <Grid xs={12} className={classes.grid}>
        <Paper elevation={3} className={classes.paper}>
          <SadFaceIcon />
          <Typography className={classes.header}>{t('header')}</Typography>
        </Paper>
      </Grid>
    </>
  )
}

FeatureDisabled.displayName = 'FeatureDisabled'
export { FeatureDisabled }
export default FeatureDisabled
