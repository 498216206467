import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  successfulPaymentTop: {
    borderRadius: '15px 15px 0 0',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: '15px 15px 0 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  successfulPaymentContainer: {
    zIndex: 3,
  },
  paymentTitle: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  paymentAlert: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  loading: {
    color: '#fff',
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  consultancyInfo: {
    backgroundColor: '#fff',
    padding: 40,
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
    },
  },
  subheading: {
    fontSize: 20,
    padding: '0 15%',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  avatar: {
    width: 128,
    height: 128,
    marginBottom: 20,
  },
}))

export { useStyles }
