import React, { FunctionComponent } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { useStoreState } from '../store/store.hooks'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../util'

type Props = RouteComponentProps

// We want to make sure that url segments are in place, because of tracking so we defined this default route
// This is a 404 handler
// After catching 404 urls we will redirect to the userType main page
export const RedirectHandler: FunctionComponent<Props> = ({}) => {
  const { profileContents } = useStoreState((store) => store.profile)

  if (!profileContents.userTypeId) {
    return <CircularProgress color="inherit" />
  }

  let targetUrl = undefined

  switch (profileContents.userTypeId) {
    case 1:
      targetUrl = '/professional/dashboard'
      break

    case 2:
    default:
      targetUrl = '/patient/services'
      break
  }

  navigate(targetUrl)

  return null
}
