import { action, thunk } from 'easy-peasy'
import { Account, AccountsModel } from './accounts.interface'
import * as accountsService from '../../services/accounts.service'

const accountsModel: AccountsModel = {
  accounts: [],

  fetchAccounts: thunk(async (actions) => {
    const accounts = await accountsService.fetchAccounts()
    actions.setAccounts(accounts)
  }),

  setAccounts: action((state, payload) => {
    state.accounts = payload

    const accountsByKey: Record<string, any> = {}
    for (const account of payload) {
      accountsByKey[account.key] = account
    }

    state.accountsByKey = accountsByKey
  }),
}

export { accountsModel }
