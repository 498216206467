const getCurrencySymbol = (currency) => {
  if (!currency) {
    return 'Ft'
  }

  switch (currency.toUpperCase()) {
    case 'HUF':
    default:
      return 'Ft'

    case 'EUR':
      return '€'
  }
}

const formatPrice = (price, currency) => {
  if (!price) {
    price = 0
  }

  if (!currency) {
    return price
  }

  return `${price} ${getCurrencySymbol(currency)}`
}

export default formatPrice
export { formatPrice }
