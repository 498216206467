import Axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { authService } from './authService'
import store from '../store'

export function getAxiosInstance() {
  const authToken = localStorage.getItem('token')

  const axios = Axios.create({
    baseURL: '/api/platform',
    timeout: 30000,
    headers: { Authorization: `Bearer ${authToken}` },
  })

  axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      // Catch 401 errors and wipe the store
      // 401 Errors will only be thrown if there is a problem with the authentication so this is safe
      if (error.response?.status === 401) {
        authService.logout()
        store.dispatch.profile.setProfile({})
      }

      return Promise.reject(error)
    }
  )

  return axios
}

export async function get<T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> {
  const axios = getAxiosInstance()
  const { data } = await axios.get<T>(url, config)
  return data
}

export async function post<T>(
  url: string,
  requestData?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  const axios = getAxiosInstance()
  try {
    const response = await axios.post<T>(url, requestData, config)
    console.log({ response })
    if (response && response.data) {
      return Promise.resolve(response.data)
    }
  } catch (err: any) {
    if (err.response && err.response.data) {
      return Promise.reject(err.response.data)
    }

    return Promise.reject(err)
  }

  return Promise.reject()
}

export async function put<T>(
  url: string,
  requestData?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  const axios = getAxiosInstance()
  const { data } = await axios.put<T>(url, requestData, config)
  return data
}

export async function deleteReq<T>(url: string, requestData?: any): Promise<T> {
  const axios = getAxiosInstance()
  const { data } = await axios.delete<T>(url, { data: requestData })
  return data
}
