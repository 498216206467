import React, { FunctionComponent } from 'react'
import dayjs from 'dayjs'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import CallEndIcon from '@material-ui/icons/CallEnd'
import { WhiteButton } from '../../../components/Button'

import { useStoreState } from '../../../store/store.hooks'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  doctorCallRing: {
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  gradientLayover: {
    borderRadius: 15,
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    minHeight: 500,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: 15,
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  doctorCallRingContainer: {
    zIndex: 3,
  },
  userName: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 200,
    fontFamily: 'Nunito, Arial, sans-serif',
    margin: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  avatarImgContainer: {
    height: 128,
    width: 128,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    borderRadius: '50%',
    margin: '50px 0',
    animation: '$pulse 2500ms infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(156, 169, 207, 0.7)',
    },

    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 100px rgba(114, 127, 197, 0)',
    },

    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(114, 127, 197, 0)',
    },
  },
  cancelCall: {
    padding: '10px 0 ',
    width: 320,
  },
  cancelCallIcon: {
    marginRight: 5,
  },
}))

type Props = {
  onCancelCall?: (twilioRoomUniqueName: string | undefined) => void
  cancelable?: boolean
}

const DoctorCallRing: FunctionComponent<Props> = ({
  onCancelCall,
  cancelable,
}) => {
  const classes = useStyles()

  const { t } = useTranslation('doctorCallRing')
  const { incomingRequestContents, twilioRoomUniqueName } = useStoreState(
    (state) => state.incomingRequest
  )

  // TODO: eliminate duplication, see PatientMatch and DoctorCallFeedback
  const patientProfilePictureUrl = incomingRequestContents?.requester?.avatarUrl
  const patientName = incomingRequestContents?.requester?.name || t('patient')
  const patientAge = dayjs().diff(
    incomingRequestContents?.requester?.birthday,
    'year'
  )
  const patientAgeString = patientAge ? `(${patientAge})` : ''

  const handleCancelCall = () => {
    if (onCancelCall instanceof Function) {
      onCancelCall(twilioRoomUniqueName)
    }
  }

  return (
    <Grid container className={classes.gradientLayover}>
      <div className={classes.bgLayover}></div>
      <Grid container className={classes.doctorCallRingContainer}>
        <Grid item xs={12} className={classes.doctorCallRing}>
          <Avatar
            alt="doki-profile-picture"
            src={patientProfilePictureUrl}
            className={classes.avatarImgContainer}
          />
          <Typography className={classes.userName} variant="h1" component="h2">
            <strong>{patientName}</strong> {patientAgeString}
          </Typography>

          <WhiteButton
            className={classes.cancelCall}
            onClick={handleCancelCall}
            disabled={!cancelable}
          >
            <>
              <CallEndIcon className={classes.cancelCallIcon} />
              {t<string>('cancelCall')}
            </>
          </WhiteButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { DoctorCallRing }
