/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const SurveyList = ({ children }) => {
  return (
    <>
      <h1>Not Found</h1>
      {children}
    </>
  )
}

SurveyList.displayName = 'SurveyList'

SurveyList.propTypes = {}

SurveyList.defaultProps = {}

export default SurveyList
export { SurveyList }
