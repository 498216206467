import React, { FunctionComponent } from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { CheckBox } from '../../components/CheckBox'
import { RequestTypeElement } from '../../store/request/request.interface'
import { useTranslation } from 'react-i18next'
import validateLocales from '../../helpers/languageFormatter'

type Props = {
  complaintsList?: RequestTypeElement[]
  complaints: any[]
}

const useStyles = makeStyles((theme) => ({
  complaintsListContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '55vh',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '70vh',
      paddingBottom: '150px',
    },
  },
  complaintsListItemContainer: {
    padding: '10px 50px',
  },
  complaintsListItem: {
    height: '60px',
    borderRadius: '10px',
    boxShadow: '0 2px 6px 0 rgba(100,115,175,0.2)',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  complaintText: {
    fontSize: '16px',
    color: '#1E2864',
  },
  selected: {
    backgroundColor: '#F1F8FD',
    fontWeight: 'bold',
  },
}))

const ComplaintsList: FunctionComponent<Props> = ({ complaints }) => {
  const classes = useStyles()

  const { selectedComplaintIds } = useStoreState((state) => state.request)
  const { setSelectedComplaintIds } = useStoreActions((state) => state.request)
  const { i18n } = useTranslation()

  const selectComplaint = (complaint: any) => {
    let complaints

    if (selectedComplaintIds.length < 1) {
      complaints = [complaint.id]
    } else {
      complaints = [...selectedComplaintIds]
      complaints.push(complaint.id)
    }

    setSelectedComplaintIds(complaints)
  }

  const deselectComplaint = (complaint: any) => {
    const complaints = selectedComplaintIds.filter(
      (selectedComplaint) => selectedComplaint !== complaint.id
    )
    setSelectedComplaintIds(complaints)
  }

  const handleComplaintClick = (complaint: any) => {
    const isSelected = selectedComplaintIds.includes(complaint.id)

    isSelected ? deselectComplaint(complaint) : selectComplaint(complaint)
  }

  const { providerTypeId } = useStoreState((state) => state.request)

  if (providerTypeId === 1 && validateLocales(i18n.language) === 'en') {
    complaints.sort((a, b) => (a.title < b.title ? -1 : 1))
  }
  const list = complaints.map((complaint, index) => {
    const isSelected = selectedComplaintIds.includes(complaint.id)
    const listItemClassnames = isSelected
      ? [classes.complaintsListItem, classes.selected].join(' ')
      : classes.complaintsListItem

    return (
      <Grid
        xs={12}
        sm={6}
        className={classes.complaintsListItemContainer}
        key={index}
      >
        <Paper
          className={listItemClassnames}
          onClick={() => handleComplaintClick(complaint)}
        >
          <CheckBox checked={isSelected} />
          <span className={classes.complaintText}>{complaint.name}</span>
        </Paper>
      </Grid>
    )
  })

  return (
    <Grid xs={12} className={classes.complaintsListContainer}>
      {list}
    </Grid>
  )
}

export { ComplaintsList }
