import { useEffect, useState } from 'react'
import { RemoteParticipant, Room } from 'twilio-video'

export default function useParticipants(room: Room) {
  const participantList = room.participants ? room.participants.values() : []
  const [participants, setParticipants] = useState(Array.from(participantList))

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant])
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      )
    room.on('participantConnected', participantConnected)
    room.on('participantDisconnected', participantDisconnected)
    return () => {
      room.off('participantConnected', participantConnected)
      room.off('participantDisconnected', participantDisconnected)
    }
  }, [room])

  return participants
}
