import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import { useStoreActions, useStoreState } from '../../store/store.hooks'
import logoMobile from '../../assets/img/doki-logo-white.png'
import { LanguageSelector } from '../LanguageSelector'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    color: '#fff',
    boxShadow: 'none',
    maxHeight: '60px',
    backgroundImage:
      '-webkit-linear-gradient( 45deg, rgb(63,167,240) 0%, rgb(240,30,90) 100%)',
  },
  customToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    maxHeight: '30px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  menuIcon: {
    color: '#fff',
  },
}))

const MobileAppBar = () => {
  const classes = useStyles()
  const { setIsSideMenuOpen } = useStoreActions((actions) => actions.layout)
  const { token } = useStoreState((state) => state.auth)

  const onLanguageChanged = (lng: any, url: string) => {
    if (url) {
      window.location.href = url
    }
  }

  const content = token ? (
    <Fragment>
      <img src={logoMobile} className={classes.logo} alt="doki-mobile-logo" />
      <Typography
        variant="h3"
        component="h3"
        className={classes.title}
      ></Typography>
      <div>
        <LanguageSelector onChange={onLanguageChanged} />
        <IconButton
          aria-label="open-menu"
          onClick={() => setIsSideMenuOpen(true)}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
      </div>
    </Fragment>
  ) : (
    <img src={logoMobile} className={classes.logo} alt="doki-mobile-logo" />
  )

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar className={classes.customToolBar}>{content}</Toolbar>
      </AppBar>
    </div>
  )
}

export { MobileAppBar }
