const ROUTES = {
  GENERAL_TERMS_AND_CONDITIONS: 'https://dokiapp.hu/aszf',
  INTL_GENERAL_TERMS_AND_CONDITIONS: 'https://dokiapp.hu/gtc',
  PRIVACY_POLICY: 'https://dokiapp.hu/privacy-general',
  PRIVACY_POLICY_FOR_WEB_APPLICATION:
    'https://dokiapp.hu/privacy-webapplication',
  INTL_PRIVACY_POLICY: 'https://dokiapp.hu/privacy-en',
  INTL_PRIVACY_POLICY_FOR_WEB_APPLICATION:
    'https://dokiapp.hu/privacy-webapplication-en',
}

export { ROUTES }
