import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { VoucherGuard } from '../../components/VoucherGuard'

const SymptomCheck = ({}) => {
  return (
    <>
      <VoucherGuard>
        <Grid item xs={12}>
          <img src="https://placehold.co/600x400/png" />
        </Grid>
      </VoucherGuard>
    </>
  )
}

SymptomCheck.displayName = 'SymptomCheck'
export { SymptomCheck }
export default SymptomCheck
