import { testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics'
import { useState, useRef, useCallback } from 'react'

const useCameraTest = () => {
  const [videoTest, setVideoTest] = useState()
  const videoElementRef = useRef(null)
  const [videoTestError, setVideoTestError] = useState()

  const stopVideoTest = useCallback(() => {
    setVideoTest(undefined)
    videoTest && videoTest.stop()
  }, [videoTest])

  const startVideoTest = useCallback(
    (deviceId) => {
      stopVideoTest()
      const test = testVideoInputDevice({
        element: videoElementRef.current,
        deviceId,
      })
      setVideoTest(test)
      test.on(VideoInputTest.Events.Error, (err) => setVideoTestError(err))
      test.on(VideoInputTest.Events.End, (report) =>
        console.log('Video test ended', report)
      )
    },
    [stopVideoTest]
  )

  return {
    startVideoTest,
    stopVideoTest,
    videoElementRef,
    videoTest,
    videoTestError,
  }
}

export default useCameraTest
export { useCameraTest }
