import React, { ChangeEvent, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import CreditCard from '@material-ui/icons/CreditCard'
import Delete from '@material-ui/icons/Delete'
import { WhiteButton } from '../../../components/Button/WhiteButton'
import { CheckboxSelector } from '../../../components/CheckboxSelector'
import { ConfirmDialog } from '../../../components'

import { useTranslation } from 'react-i18next'

type CardData = {
  id: string
  lastFourDigits: string
  cardName: string
}

type Props = {
  title?: string
  disabled?: boolean
  selectedCardIndex?: number
  creditCardList?: CardData[]
  selectActiveCard?: (event: ChangeEvent<{}>, index: number, card: any) => void
  editCardData?: (cardIndex: number) => void
  createNewCard: () => Promise<void>
  isProfilePageVersion?: boolean
  onDeleteCard?: (card: any) => void
}

const useStyles = makeStyles(() => ({
  boxLabel: {
    color: '#6473AF',
    marginLeft: 15,
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 12,
  },
  button: {
    width: '100%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
  },
  dataSelectButtonContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: 20,
  },
  dataSelectButton: {
    border: '1px solid #6473AF;',
    justifyContent: 'start',
    marginBottom: 0,
    padding: 0,
    paddingLeft: 10,
  },
  dataContainer: {
    marginBottom: '40px',
  },
  dataBlock: {
    padding: 10,
    paddingLeft: 5,
  },
  dataTitle: {
    color: '#1E2864',
    fontSize: 16,
  },
  cardIcon: {
    marginRight: 5,
  },
  cardNumber: {
    color: '#6473AF',
    fontWeight: 500,
    fontSize: 16,
  },
  editButton: {
    top: 0,
    right: 0,
    width: 60,
    zIndex: 100,
    height: '94%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  deleteIcon: {
    top: 20,
    right: 0,
    width: 60,
    zIndex: 100,
    position: 'absolute',
    color: '#6473AF',
    cursor: 'pointer',
  },
  paymentDeleteIcon: {
    top: 10,
    right: 0,
    width: 60,
    zIndex: 100,
    position: 'absolute',
    color: '#6473AF',
    cursor: 'pointer',
  },
  dataRow: {
    width: '100%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '1px solid #6473AF;',
    borderRadius: 10,
    paddingLeft: 10,
    color: '#6473AF',
    minHeight: 70,
  },
}))

const hiddenCardNumberDigits = '**** **** **** '

const CreditCardBlock = (props: Props) => {
  const {
    creditCardList,
    selectActiveCard,
    selectedCardIndex,
    isProfilePageVersion,
    createNewCard,
    disabled = false,
    title,
    onDeleteCard,
  } = props

  const classes = useStyles()
  const [dialogShown, setDialogShown] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)

  const { t } = useTranslation('creditCardBlock')

  const renderCreditCardRow = (cardData: CardData) => {
    return (
      <Box
        display="flex"
        alignItems="start"
        flexDirection="column"
        className={classes.dataBlock}
      >
        <Typography className={classes.dataTitle}>
          {cardData.cardName}
        </Typography>
        <Box display="flex">
          <CreditCard className={classes.cardIcon} />
          <Typography className={classes.cardNumber}>
            {hiddenCardNumberDigits + cardData.lastFourDigits}
          </Typography>
        </Box>
      </Box>
    )
  }

  const titleToShow = title ?? t<string>('title')

  const handleDeleteCard = (card: any) => {
    if (onDeleteCard instanceof Function) {
      onDeleteCard(card)
    }
  }

  return (
    <div className={classes.dataContainer}>
      <Typography className={classes.boxLabel}>{titleToShow}</Typography>
      {creditCardList?.map((cardData: any, index: number) => {
        return (
          <div
            key={`credit-card-${index}`}
            className={classes.dataSelectButtonContainer}
          >
            {isProfilePageVersion ? (
              <div>
                <div className={classes.dataRow}>
                  {renderCreditCardRow(cardData)}
                  <WhiteButton
                    disabled={disabled}
                    onClick={() => {
                      setSelectedCard(cardData)
                      setDialogShown(true)
                    }}
                    className={classes.editButton}
                  >
                    <Delete className={classes.deleteIcon} />
                  </WhiteButton>
                </div>
              </div>
            ) : (
              <div>
                <WhiteButton
                  disabled={disabled}
                  onClick={(event: ChangeEvent<{}>) =>
                    selectActiveCard && selectActiveCard(event, index, cardData)
                  }
                  className={classes.button + ' ' + classes.dataSelectButton}
                >
                  {creditCardList?.length > 1 ? (
                    <CheckboxSelector
                      checkedState={selectedCardIndex === index && !disabled}
                    />
                  ) : null}
                  {renderCreditCardRow(cardData)}
                  <WhiteButton
                    disabled={disabled}
                    onClick={() => {
                      setSelectedCard(cardData)
                      setDialogShown(true)
                    }}
                    className={classes.editButton}
                  >
                    <Delete className={classes.paymentDeleteIcon} />
                  </WhiteButton>
                </WhiteButton>
              </div>
            )}
          </div>
        )
      })}
      <WhiteButton
        onClick={() => createNewCard()}
        className={classes.button}
        disabled={disabled}
      >
        {t<string>('buttonText')}
      </WhiteButton>
      <ConfirmDialog
        visible={dialogShown}
        message={t<string>('popupTitle')}
        cancelText={t<string>('popupCancel')}
        confirmText={t<string>('popupDelete')}
        onConfirm={() => {
          setDialogShown(false)
          handleDeleteCard(selectedCard)
        }}
        onCancel={() => setDialogShown(false)}
      />
    </div>
  )
}

export { CreditCardBlock }
