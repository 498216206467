import { useCallback, useEffect } from 'react'

import { useStoreState, useStoreActions } from '../../store/store.hooks'

import { PushTypes } from '../../constants/pushTypes'
import { navigate } from '@reach/router'
import store from '../../store'
import { useAbly } from '../../hooks/useAbly'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

export const ProfessionalAblyHandler = () => {
  const { profileContents } = useStoreState((state) => state.profile)
  const { setNotification } = useStoreActions((actions) => actions.layout)
  const { t } = useTranslation('professionalAblyHandler')
  const ably = useAbly()

  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get('providers')
    channel.presence.enter(profileContents)
    return () => channel.presence.leave()
  }, [profileContents.id, ably])

  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)
    channel.subscribe(
      PushTypes.INVALIDATE_MATCH,
      function (message: { data: { requestId: number } }) {
        const currentRequestId =
          store.getState().incomingRequest.incomingRequestContents?.id
        console.log({ message, currentRequestId })
        if (message.data.requestId !== currentRequestId) return
        mixpanel.track('Ably message', message)
        console.log(
          '*** ProfessionalAblyHandler useEffect, navigating to prof dashboard'
        )
        navigate('/professional/dashboard')
        setNotification({
          isOpen: true,
          type: 'error',
          title: t('error'),
        })
      }
    )
    return () => channel.unsubscribe(PushTypes.INVALIDATE_MATCH)
  }, [profileContents.id, ably])

  const { fetchIncomingRequest } = useStoreActions(
    (actions) => actions.incomingRequest
  )

  const handleRequesterFound = useCallback(async (requestId: number) => {
    await fetchIncomingRequest(requestId)
    await navigate('/professional/patient-match')
  }, [])

  const handleManualEndCall = async () => {
    await navigate('/professional/dashboard')
  }

  useEffect(() => {
    if (!ably) return
    const channel = ably.channels.get(`user:${profileContents?.id}`)

    // Requester found message
    channel.subscribe(
      PushTypes.REQUESTER_FOUND,
      function (message: { data: { requestId: string } }) {
        console.log('Ably', message)
        mixpanel.track('Ably message', message)
        handleRequesterFound(Number(message.data.requestId))
      }
    )

    // When a call is manually forced to end
    channel.subscribe(
      PushTypes.MANUAL_END_CALL,
      function (message: { data: { requestId: string } }) {
        console.log('Ably', message)

        const currentRequestId =
          store.getState().incomingRequest.incomingRequestContents?.id

        if (Number(message.data.requestId) === Number(currentRequestId)) {
          console.log(
            'Manually force-closing current request #',
            currentRequestId
          )
          mixpanel.track('Manually ending a call')
          handleManualEndCall()
        }
      }
    )

    return () => {
      channel.unsubscribe(PushTypes.REQUESTER_FOUND)
      channel.unsubscribe(PushTypes.MANUAL_END_CALL)
    }
  }, [ably, profileContents.id, handleRequesterFound, handleManualEndCall])

  return null
}
