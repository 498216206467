import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(() => ({
  root: {
    width: '65%',
    height: '12px',
    borderRadius: 20,
    margin: '3% 15% 4%',
  },
  barColorPrimary: {
    background: 'linear-gradient(1.74deg, #3FA7F0 0%, #F01E5A 100%)',
  },
  colorPrimary: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}))

const LinearCallIndicator = () => {
  const classes = useStyles()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    // The 2 values product should add up to 1000
    const tickFrequencyInMilliseconds = 250
    const smootheningConstant = 1000 / tickFrequencyInMilliseconds

    function incrementProgress() {
      setProgress((oldProgress) => {
        // maxTimeToWaitInMinutes is determined on the backend
        // Now it is set to 5 minutes
        const maxTimeToWaitInMinutes = 5
        const secondToPercentage = 100 / 60

        const stepPerSecund =
          secondToPercentage / (maxTimeToWaitInMinutes * smootheningConstant)
        return Math.min(oldProgress + stepPerSecund, 100)
      })
    }

    const timer = setInterval(incrementProgress, tickFrequencyInMilliseconds)
    return () => clearInterval(timer)
  }, [])

  return (
    <LinearProgress
      value={progress}
      classes={{
        root: classes.root,
        colorPrimary: classes.colorPrimary,
        barColorPrimary: classes.barColorPrimary,
      }}
      variant="determinate"
      color={'primary'}
    />
  )
}

export { LinearCallIndicator }
