import React, { useRef, useEffect } from 'react'
import { IVideoTrack } from '../types'
import { styled } from '@material-ui/core/styles'
import { Track } from 'twilio-video'

const Video = styled('video')({
  width: '100%',
  height: '100%',
  pointerEvents: 'none',
  objectFit: 'contain',

  '@media (max-width: 720px) and (orientation: landscape)': {
    objectFit: 'fill',
  },
})

interface VideoTrackProps {
  track: IVideoTrack
  isLocal?: boolean
  priority?: Track.Priority | null
}

export function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!)

  useEffect(() => {
    if (!track) return
    const el = ref.current
    el.muted = true
    if (track.setPriority && priority) {
      track.setPriority(priority)
    }
    track.attach(el)
    return () => {
      track.detach(el)
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null)
      }
    }
  }, [track, priority])

  // The local video track is mirrored.
  const style = isLocal ? { transform: 'rotateY(180deg)' } : {}
  return <Video ref={ref} style={style} />
}
