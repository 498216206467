import React from 'react'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { Container, Grid, Typography } from '@material-ui/core'

import { Heading } from '../../components/Text'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { GradientButton } from '../../components/Button/GradientButton'

import { useStyles } from './styles'

export const Login = () => {
  const { t } = useTranslation('login')
  const classes = useStyles()

  return (
    <Container className={classes.login} component="main" maxWidth={false}>
      <Grid className={classes.container}>
        <Heading>{t<string>('heading')}</Heading>
        <Typography className={classes.subHeading}>
          {t<string>('subHeading')}
        </Typography>
        <Container maxWidth="xs" className={classes.bodyContainer}>
          <GradientButton
            className={classes.emailLoginButton}
            onClick={() => navigate('/email-login')}
          >
            {t<string>('emailLoginButton')}
          </GradientButton>
          <WhiteButton
            className={classes.registrationButton}
            onClick={() => navigate('/registration')}
          >
            {t<string>('registrationButton')}
          </WhiteButton>
        </Container>
        <div className={classes.bottomRegistrationBox}>
          <Typography className={classes.registrationBoxText}>
            {t<string>('doctorRegistration')}
          </Typography>
          <GradientButton
            className={classes.registrationBoxButton}
            onClick={() =>
              window.open('https://dokiapp.hu/#orvosoknak', '_blank')
            }
          >
            {t<string>('doctorButton')}
          </GradientButton>
        </div>
      </Grid>
    </Container>
  )
}
