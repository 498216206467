import React, { FunctionComponent, ReactElement } from 'react'
import { navigate, RouteComponentProps } from '@reach/router'

import { useStoreState } from '../store/store.hooks'
import { CircularProgress } from '@material-ui/core'
import { useRouteLogger } from './useRouteLogger'

type Props = RouteComponentProps & {
  children: any
}

export const PatientRoot: FunctionComponent<Props> = ({ children }) => {
  const { profileContents } = useStoreState((store) => store.profile)
  useRouteLogger()
  if (!profileContents.userTypeId) {
    return <CircularProgress color="inherit" />
  }
  // Handle if a professional wants to enter patient routes
  if (
    typeof profileContents.userTypeId === 'number' &&
    profileContents.userTypeId !== 2
  ) {
    navigate('/professional/dashboard')
  }
  // Just in case some1 logs in with an admin
  // else if () {
  //   alert('Invalid userTypeId')
  //   return null
  // }
  // https://stackoverflow.com/a/54908762/3155277
  return children as ReactElement<any>
}
