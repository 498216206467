import React, { useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { useForm } from 'react-hook-form'
import { Heading, SubHeading } from '../../components/Text'
import { WhiteButton } from '../../components'
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons'
import { FormInput } from '../../components/FormInput'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { post } from '../../services/request'

const useStyles = makeStyles((theme) => ({
  accountCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    borderRadius: '15px',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
    color: '#fff',
    minWidth: 275,
    height: '100%',
    paddingBottom: '1rem',
    margin: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      margin: '10px 20px',
    },
  },
  accountCardDescription: {
    marginTop: '1rem',
  },
  accountCardTitle: {
    marginTop: theme.spacing(1.5),
  },
  accountContainer: {
    padding: '60px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  cardActions: {
    flexDirection: 'column',
  },
  generalPublicCard: {
    background:
      'linear-gradient(90deg, rgb(235, 46, 103) 0%, rgb(229, 62, 116) 100%) rgb(239, 30, 91)',
  },
  optionGrid: {
    padding: '2rem',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0',
  },
  partnerAccountCard: {
    background:
      'linear-gradient(90deg, rgb(64, 171, 239) 0%, rgb(83, 179, 240) 100%) rgb(60, 170, 240)',
  },
  partnerAccountCardDescription: {
    marginBottom: '1rem',
  },
  partnerAccountInputLabel: {
    color: 'white !important',
    marginTop: '0.5rem',
  },
  root: {},
  successfullyRegistered: {
    marginTop: '0.5rem',
  },
  voucherCodeError: {
    textAlign: 'left',
  },
  voucherCodeErrorText: {
    marginTop: '1rem',
    fontWeight: 'bold',
  },
  voucherCodeIcon: {
    color: '#eb2e67',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  voucherInput: {},
}))

const Account = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('features')
  const { accountsByKey } = useStoreState((state) => state.accounts)
  const { fetchAccounts } = useStoreActions((state) => state.accounts)
  const generalPublicAccount = accountsByKey && accountsByKey['general-public']
  const { relativeNavigate } = useRelativeNavigate()
  const [error, setError] = useState(null)
  const [isSuccessfullyRegistered, setIsSuccessfullyRegistered] =
    useState(false)
  const [registeredAccountName, setRegisteredAccountName] = useState(null)

  const {
    watch,
    formState: { errors },
    register,
    clearErrors,
  } = useForm({})

  const { ref: voucherInputRef, ...voucherInputProps } = register('voucher', {
    required: t('missingField'),
  })

  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  const handleChooseGeneralPublic = async () => {
    setError(null)

    try {
      const result = await post('/subscription/associate', {
        accountId: generalPublicAccount.id,
      })

      if (result.userId && result.accountId) {
        relativeNavigate('/patient')
      }
    } catch (err) {
      console.error('Failed to associate with general public', err)
    }
  }

  const handleChooseAccount = async () => {
    setError(null)
    setIsSuccessfullyRegistered(false)
    setRegisteredAccountName(null)

    try {
      const result = await post('/vouchers/associate', {
        voucherCode: watch('voucher'),
      })

      if (result.userId && result.accountId) {
        setIsSuccessfullyRegistered(true)
        setRegisteredAccountName(result.fullName)
      } else if (result.error) {
        setError(result.error)
      }
    } catch (err) {
      console.error('Failed to associate with partner', err)
    }
  }

  const handleCompleteRegistration = () => {
    if (isSuccessfullyRegistered) {
      relativeNavigate('/patient')
    }
  }

  return (
    <>
      <Grid container xs={12} className={classes.accountContainer}>
        <Heading>{t('askForVoucherHeader')}</Heading>
        <SubHeading>{t('askForVoucherIntro')}</SubHeading>

        <Grid container spacing={3} className={classes.optionsContainer}>
          <Grid item className={classes.optionGrid} md={6} xs={12}>
            <Card
              className={[classes.accountCard, classes.generalPublicCard].join(
                ' '
              )}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.accountCardTitle}
                >
                  {t('generalPublicHeader')}
                </Typography>
                <Typography className={classes.accountCardDescription}>
                  {t('generalPublicDescription')}
                </Typography>
                <Typography className={classes.accountCardDescription}>
                  {t('generalPublicDisclaimer')}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <WhiteButton onClick={handleChooseGeneralPublic}>
                  {t('generalPublicButton')}
                  <ArrowForwardIosIcon />
                </WhiteButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item className={classes.optionGrid} md={6} xs={12}>
            <Card
              className={[classes.accountCard, classes.partnerAccountCard].join(
                ' '
              )}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  className={classes.accountCardTitle}
                >
                  {t('partnerAccountHeader')}
                </Typography>
                <Typography
                  className={[
                    classes.accountCardDescription,
                    classes.partnerAccountCardDescription,
                  ].join(' ')}
                >
                  {t('partnerAccountDescription')}
                </Typography>

                {!isSuccessfullyRegistered && (
                  <>
                    <form>
                      <FormInput
                        inputName="voucher"
                        inputLabelClassName={classes.partnerAccountInputLabel}
                        disableEndAdornment={true}
                        error={!!errors.voucher}
                        value={watch('voucher')}
                        label={t('askForVoucherInputLabel')}
                        clearError={() => clearErrors('voucher')}
                        placeholder={t('askForVoucherPlaceholder')}
                        errorMessage={String(errors?.voucher?.message ?? '')}
                        className={classes.voucherInput}
                        inputRef={voucherInputRef}
                        {...voucherInputProps}
                      />
                    </form>
                  </>
                )}

                {error && (
                  <>
                    <Grid container>
                      <Grid item xs={3} className={classes.voucherCodeIcon}>
                        <CancelIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={9} className={classes.voucherCodeError}>
                        <Typography className={classes.voucherCodeErrorText}>
                          {t('askForVoucherInvalidCode')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                {isSuccessfullyRegistered && (
                  <>
                    <Typography className={classes.successfullyRegistered}>
                      {t('successfullyRegistered')}
                    </Typography>
                    <Typography>{registeredAccountName}</Typography>
                  </>
                )}
              </CardContent>
              <CardActions className={classes.cardActions}>
                {!isSuccessfullyRegistered && (
                  <>
                    <WhiteButton
                      onClick={handleChooseAccount}
                      disabled={!watch('voucher')}
                    >
                      {t('partnerAccountButton')}
                      <ArrowForwardIosIcon />
                    </WhiteButton>
                  </>
                )}
                {isSuccessfullyRegistered && (
                  <>
                    <WhiteButton onClick={handleCompleteRegistration}>
                      {t('startUsing')}
                      <ArrowForwardIosIcon />
                    </WhiteButton>
                  </>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

Account.displayName = 'Account'
export { Account }
export default Account
