import React from 'react'
import dayjs from 'dayjs'

import { navigate } from '@reach/router'
import { useStoreState, useStoreActions } from '../../../../store/store.hooks'
import { sendProviderResponse } from '../../../../services/request.service'

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { NavigateBack } from '../../../../components/Nav/NavigateBack'
import { Heading } from '../../../../components/Text'
import { Input } from '../../../../components/Input/Input'
import { WhiteButton } from '../../../../components/Button'

import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

require('dayjs/locale/hu')

const DoctorCallFeedback = () => {
  const classes = useStyles()
  const { t } = useTranslation('doctorCallFeedback')

  const { incomingRequestContents } = useStoreState(
    (state) => state.incomingRequest
  )
  const complaints = incomingRequestContents?.complaints
    .map((complaint) => complaint.name)
    .join(', ')

  // TODO: eliminate duplication, see PatientMatch and DoctorCallRing
  const description = incomingRequestContents?.description
  const patientProfilePictureUrl = incomingRequestContents?.requester?.avatarUrl
  const patientName = incomingRequestContents?.requester?.name || 'Páciens'
  const patientAge = dayjs().diff(
    incomingRequestContents?.requester?.birthday,
    'year'
  )
  const patientAgeString = patientAge ? `(${patientAge})` : ''

  const {
    request: { setProviderResponse, resetRequestState },
    layout: { setNotification },
  } = useStoreActions((actions) => actions)

  const { providerResponse, requestId } = useStoreState(
    (state) => state.request
  )

  const respond = async () => {
    const body = {
      providerResponse: providerResponse,
      requestId: requestId || incomingRequestContents?.id,
    }
    try {
      sendProviderResponse({ body })

      resetRequestState()
      navigate('/professional/feedback-success')
      setNotification({
        isOpen: true,
        type: 'success',
        title: t<string>('success1Title'),
      })
    } catch (error) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t<string>('error1Title'),
        description: t<string>('error1Description'),
      })
    }
  }

  return (
    <Grid container className={classes.complaintDetailBackground}>
      <NavigateBack />
      <Grid item xs={12} className={classes.ComplaintDetailContainer}>
        <Heading>{t<string>('heading')}</Heading>
        <div className={classes.complaintDetail}>
          <div className={classes.patientInfoContainer}>
            <div className={classes.patientInfoUpper}>
              <Avatar
                alt="username"
                src={patientProfilePictureUrl}
                className={classes.avatar}
              />
              <div>
                <h3 className={classes.patientName}>
                  <strong>{patientName}</strong> {patientAgeString}
                </h3>
              </div>
            </div>
            <div className={classes.patientInfoLower}>
              <span className={classes.label}>
                {t<string>('complaintLabel')}
              </span>
              <span className={classes.complaints}>{complaints}</span>
              <span className={classes.complaintDetailText}>{description}</span>
            </div>
          </div>
          <div className={classes.doctorFeedback}>
            <span className={classes.label}>{t<string>('feedbackLabel')}</span>
            <Input
              rows="6"
              multiline
              value={providerResponse || ''}
              className={classes.complaintDetailInput}
              placeholder={t<string>('inputLabel')}
              onChange={(e) => setProviderResponse(e.target.value)}
            />
          </div>
        </div>
      </Grid>
      <Grid xs={12} container className={classes.complaintDetailFooter}>
        <WhiteButton
          onClick={() => respond()}
          className={classes.complaintDetailFooterButton}
        >
          <>
            {t<string>('buttonText')}
            <ArrowForwardIosIcon />
          </>
        </WhiteButton>
      </Grid>
    </Grid>
  )
}

export { DoctorCallFeedback }
