import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { Container, Avatar, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Delete, Edit } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { FormInput } from '../../components/FormInput'
import { GradientButton } from '../../components/Button/GradientButton'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { updateProfile } from '../../services/profile.service'
import { navigate } from '@reach/router'

import ProfileIcon from '../../assets/img/profile.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  headerContainer: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  editButton: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#1E2864',
    fontFamily: 'Nunito, Arial, sans-serif',

    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  editIcon: {
    padding: '4px',
    marginBottom: '-6px',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginBottom: '10px',
    background: '#FFFFFF',
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    '@media (max-height: 680px)': {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    border: '1px solid #E0E3EF',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 25,
  },
  userName: {
    fontSize: '20px',
    fontWeight: 300,
    margin: '5px 0',
  },
  userEmail: {
    fontWeight: 300,
  },
  avatarFallback: {
    color: '#C1C7DF',
    backgroundColor: '#FFFFFF',
    height: '56px',
    width: '56px',
  },
  paymentContainer: {
    paddingBottom: 20,
  },
  deleteIconContainer: {
    boxShadow: '0px 5px 10px rgba(100, 115, 175, 0.2)',
    background: '#FFFFFF',
    borderRadius: 20,
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 8,
    right: 0,
    zIndex: 10,
  },
  deleteIcon: {
    color: '#6473AF',
  },
  avatarWrapperContainer: {
    position: 'relative',
    display: 'flex',
    cursor: 'pointer',
  },
  saveButton: {
    height: '50px',
  },
  line: {
    height: 1,
    background: 'rgba(100, 115, 175, 0.2)',
  },
}))

const ProfileEdit = () => {
  const { i18n, t } = useTranslation('profileEdit')
  const classes = useStyles()

  const profileContents = useStoreState(
    (state) => state.profile.profileContents
  )
  const fetchProfile = useStoreActions(
    (actions) => actions.profile.fetchProfile
  )
  const {
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    register,
    reset,
  } = useForm()
  const [avatarUrl, setAvatarUrl] = useState(profileContents.avatarUrl || '')
  const [isAvatarDeleted, setIsAvatarDeleted] = useState(false)

  const { setNotification } = useStoreActions((actions) => actions.layout)

  useEffect(() => {
    if (profileContents?.avatarUrl) {
      setAvatarUrl(profileContents.avatarUrl)
    }
  }, [profileContents.avatarUrl])

  const onSubmit = async (values: any) => {
    let succeeded = true

    const updatedProfileContents: any = {
      ...profileContents,
      ..._.pick(
        values,
        'name',
        'email',
        'oldPassword',
        'newPassword',
        'newPasswordConf'
      ),
      birthday: new Date(),
      avatarImg: isAvatarDeleted ? '' : avatarUrl,
      avatarUrl: profileContents.avatarUrl || '',
      language: i18n.language,
    }

    if (profileContents.avatarUrl === avatarUrl) {
      updatedProfileContents.avatarImg = undefined
    }

    // TODO: update error handling
    const noPasswordValuesAreGiven =
      !values.oldPassword && !values.newPassword && !values.newPasswordConf
    const allPasswordValuesAreGiven =
      values.oldPassword && values.newPassword && values.newPasswordConf
    const notAllPasswordValuesAreGiven = !(
      noPasswordValuesAreGiven || allPasswordValuesAreGiven
    )

    if (notAllPasswordValuesAreGiven) {
      succeeded = false
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error1Title'),
        description: '',
      })
      return
    }

    try {
      await updateProfile(updatedProfileContents)
      setNotification({
        isOpen: true,
        type: 'success',
        title: t('successTitle'),
        description: '',
      })
      await fetchProfile({})
    } catch (e) {
      succeeded = false
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error2Title'),
        description: t<string>('error2Description'),
      })
    }
    setIsAvatarDeleted(false)
    reset({ name: updatedProfileContents.name })

    if (succeeded) {
      navigate('/patient/profile')
    }
  }

  const triggerAvatarUpload = () =>
    document.getElementById('imgUploadProfileEdit')?.click()

  const handleAvatarUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const tgt = event.target,
      files = tgt.files

    if (FileReader && files && files.length) {
      const fr = new FileReader()
      fr.onload = () => setAvatarUrl(fr.result)
      fr.readAsDataURL(files[0])
    } else {
      alert(t('unsupportedFileUpload'))
    }
    setIsAvatarDeleted(false)
  }

  const resetAvatarUrl = (event: MouseEvent) => {
    event.stopPropagation()
    setAvatarUrl('')
    setIsAvatarDeleted(true)
  }

  const getAvatarIcon = () => {
    if (!avatarUrl || avatarUrl === '') {
      return (
        <div
          className={classes.deleteIconContainer}
          onClick={triggerAvatarUpload}
        >
          <Edit className={classes.deleteIcon} />
        </div>
      )
    }
    return (
      <div className={classes.deleteIconContainer} onClick={resetAvatarUrl}>
        <Delete className={classes.deleteIcon} />
      </div>
    )
  }

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t<string>('emptyField'),
  })
  const { ref: oldPasswordInputRef, ...oldPasswordInputProps } =
    register('oldPassword')
  const { ref: newPasswordInputRef, ...newPasswordInputProps } =
    register('newPassword')
  const { ref: newPasswordConfInputRef, ...newPasswordConfInputProps } =
    register('newPasswordConf')

  return (
    <Container className={classes.container}>
      <NavigateBack />
      <Grid item xs={12} className={classes.headerContainer}>
        <Heading>{t<string>('heading')}</Heading>
      </Grid>
      <div className={classes.avatarContainer}>
        <input
          type="file"
          id="imgUploadProfileEdit"
          style={{ display: 'none' }}
          onChange={(event) => handleAvatarUpload(event)}
        />
        <div
          className={classes.avatarWrapperContainer}
          onClick={triggerAvatarUpload}
        >
          <Avatar alt="username" src={avatarUrl} className={classes.avatar}>
            <img
              src={ProfileIcon}
              className={classes.avatarFallback}
              alt="avatar-fallback"
            />
          </Avatar>
          {getAvatarIcon()}
        </div>
      </div>
      <Container
        maxWidth="xs"
        component="main"
        className={classes.paymentContainer}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <FormInput
              inputName="name"
              error={!!errors.name}
              value={watch('name')}
              defaultValue={profileContents.name || ''}
              label={t<string>('nameLabel')}
              clearError={() => clearErrors('name')}
              placeholder={t<string>('namePlaceholder')}
              errorMessage={String(errors?.name?.message ?? '')}
              inputRef={nameInputRef}
              {...nameInputProps}
            />
          </Box>
          <Box mb={3} className={classes.line}></Box>
          <Box mb={3}>
            <FormInput
              inputName="oldPassword"
              type="password"
              error={!!errors.oldPassword}
              defaultValue=""
              value={watch('oldPassword')}
              label={t<string>('oldPasswordLabel')}
              clearError={() => clearErrors('oldPassword')}
              placeholder={t<string>('oldPasswordPlaceholder')}
              errorMessage={String(errors?.oldPassword?.message ?? '')}
              inputRef={oldPasswordInputRef}
              {...oldPasswordInputProps}
            />
          </Box>
          <Box mb={3}>
            <FormInput
              inputName="newPassword"
              type="password"
              error={!!errors.newPassword}
              defaultValue=""
              value={watch('newPassword')}
              label={t<string>('newPasswordLabel')}
              clearError={() => clearErrors('newPassword')}
              placeholder={t<string>('newPasswordPlaceholder')}
              errorMessage={String(errors?.newPassword?.message ?? '')}
              inputRef={newPasswordInputRef}
              {...newPasswordInputProps}
            />
          </Box>
          <Box mb={3}>
            <FormInput
              inputName="newPasswordConf"
              type="password"
              error={!!errors.newPasswordConf}
              defaultValue=""
              value={watch('newPasswordConf')}
              label={t<string>('newPasswordConfLabel')}
              clearError={() => clearErrors('newPasswordConf')}
              placeholder={t<string>('newPasswordConfPlaceholder')}
              errorMessage={String(errors?.newPasswordConf?.message ?? '')}
              inputRef={newPasswordConfInputRef}
              {...newPasswordConfInputProps}
            />
          </Box>
          <Box mb={3}>
            <GradientButton type={'submit'} className={classes.saveButton}>
              {t<string>('saveButton')}
            </GradientButton>
          </Box>
        </form>
      </Container>
    </Container>
  )
}

export { ProfileEdit }
