import UIfx from 'uifx'

// TODO: remove eslint-disable and fix mp3 imports
/*eslint-disable */
const paymentSound = require('../assets/sounds/successfulPayment.mp3')
const ringingSound = require('../assets/sounds/ringing.mp3')
const callingSound = require('../assets/sounds/calling.mp3')
const matchSound = require('../assets/sounds/match.mp3')
/*eslint-enable */

export const playRingingSoundEffect = () => {
  const ringing = new UIfx(ringingSound, {
    volume: 0.5, // number between 0.0 ~ 1.0
    throttleMs: 100,
  })
  ringing.play()
}

export const playCallingSoundEffect = () => {
  const calling = new UIfx(callingSound, {
    volume: 0.5, // number between 0.0 ~ 1.0
    throttleMs: 100,
  })
  calling.play()
}

export const playMatchSoundEffect = () => {
  const match = new UIfx(matchSound, {
    volume: 0.5, // number between 0.0 ~ 1.0
    throttleMs: 100,
  })
  match.play()
}

export const playPaymentSoundEffect = () => {
  const payment = new UIfx(paymentSound, {
    volume: 0.5, // number between 0.0 ~ 1.0
    throttleMs: 100,
  })
  payment.play()
}
