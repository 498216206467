import { useLocation } from '@reach/router'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'

export const useRouteLogger = () => {
  const location = useLocation()

  useEffect(() => {
    // console.log('routeLogger', location.pathname)
    mixpanel.track('Location change', { path: location.pathname })
    // ga.send(['pageview', location.pathname]);
  }, [location])
}
