import React, { ChangeEvent, FunctionComponent } from 'react'
import clsx from 'clsx'
import CheckIcon from '@material-ui/icons/Check'
import { makeStyles } from '@material-ui/core/styles'
import { TextField, InputAdornment, FormLabel, Box } from '@material-ui/core'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

type Props = {
  inputRef?: any
  type?: string
  label: string
  value?: string
  error: boolean
  onChange?: any
  clearError: any
  inputName: string
  className?: string
  inputLabelClassName?: string
  placeholder: string
  errorMessage: string
  defaultValue?: any
  multiline?: boolean
  rows?: string
  disableClearErrorOnBlurAndFocus?: boolean
  outlineClassName?: string
  disableEndAdornment?: boolean
  minNumericValue?: number
  maxNumericValue?: number
}

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    '& .MuiOutlinedInput-root:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #C1C7DF',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#6473AF',
      },
    },
  },

  input: {
    color: '#1E2864',
    borderRadius: '15px',
    backgroundColor: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  multilineInputPadding: {
    padding: '4px',
  },
  notchedOutline: {
    border: '1px solid rgba(100,115,175,0.4)',
    borderRadius: '15px',
  },
  inputLabel: {
    color: '#6473AF',
    float: 'left',
    marginLeft: 15,
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 12,
  },
  customInput: {
    width: '100%',
  },
}))

const FormInput: FunctionComponent<Props> = ({
  errorMessage,
  placeholder,
  clearError,
  className,
  inputLabelClassName,
  inputName,
  inputRef,
  label,
  value,
  error,
  type = '',
  defaultValue,
  multiline,
  rows,
  onChange,
  disableClearErrorOnBlurAndFocus,
  outlineClassName,
  disableEndAdornment = false,
  minNumericValue,
  maxNumericValue,
}) => {
  const classes = useStyles()

  const inputClasses = className
    ? [classes.customInput, className].join(' ')
    : classes.customInput

  const inputTextareaClasses = multiline
    ? [classes.input, classes.multilineInputPadding].join(' ')
    : classes.input

  const textFieldInputProps = {
    inputProps: { min: minNumericValue, max: maxNumericValue },
    value,
    classes: {
      input: inputTextareaClasses,
      notchedOutline: clsx([classes.notchedOutline, outlineClassName]),
    },
    endAdornment:
      !disableEndAdornment &&
      (error ? (
        <InputAdornment position="end">
          <ReportProblemOutlinedIcon style={{ color: '#1E2864' }} />
        </InputAdornment>
      ) : value ? (
        <InputAdornment position="end">
          <CheckIcon style={{ color: 'green' }} />
        </InputAdornment>
      ) : null),
  }
  const inputLabelProps = {
    shrink: true,
    classes: {
      root: inputLabelClassName
        ? [classes.inputLabel, inputLabelClassName].join(' ')
        : classes.inputLabel,
    },
  }

  return (
    <Box className={classes.container}>
      <FormLabel
        className={
          inputLabelClassName
            ? [classes.inputLabel, inputLabelClassName].join(' ')
            : classes.inputLabel
        }
      >
        {label}
      </FormLabel>
      <TextField
        type={type}
        error={error}
        name={inputName}
        variant="outlined"
        inputRef={inputRef}
        defaultValue={defaultValue}
        className={inputClasses}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        helperText={error && errorMessage}
        InputLabelProps={inputLabelProps}
        onFocus={() => {
          !disableClearErrorOnBlurAndFocus && clearError()
        }}
        onBlur={() => {
          !disableClearErrorOnBlurAndFocus && clearError()
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange && onChange(event)
        }
        InputProps={textFieldInputProps}
      />
    </Box>
  )
}

export { FormInput }
