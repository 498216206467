import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from '@reach/router'

type Props = {
  component: FunctionComponent
  guard?: 'patient' | 'prof'
} & RouteComponentProps

// This is important otherwise typescript won't work properly
// We would have to implement RouteComponentProps for all views
const Route: FunctionComponent<Props> = ({ component: Component, ...rest }) => {
  // @ts-ignore
  return <Component {...rest} />
}

export default Route
