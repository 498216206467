import React, { Fragment, useEffect, useState } from 'react'
import { navigate } from '@reach/router'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { WhiteButton } from '../../../../components/Button'

import {
  fetchCallData,
  calculateDuration,
} from '../../../../services/duration.service'
import successCircle from '../../../../assets/img/circle.png'
import { useStoreState } from '../../../../store/store.hooks'

import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const DoctorCallFinish = () => {
  const classes = useStyles()
  const { incomingRequestContents } = useStoreState(
    (state) => state.incomingRequest
  )

  const { t } = useTranslation('doctorCallFinish')

  const [callData, setCallData] = useState<any>(null)
  const [durationString, setDurationString] = useState('00:00')

  const fetchCall = async (requestId: any) => {
    if (!callData || !callData.callEndedAt) {
      setTimeout(async () => {
        try {
          const call = await fetchCallData(requestId)
          setCallData(call)
        } catch (e) {
          console.error('Failed to fetch call data')
          console.error(e)
        }
      }, 1000)
    }
  }

  useEffect(() => {
    fetchCall(incomingRequestContents?.id)
  }, [])

  useEffect(() => {
    const duration = calculateDuration(
      callData?.callEndedAt,
      callData?.recipientConnectedAt
    )
    setDurationString(duration)
  }, [callData])

  return (
    <Fragment>
      <Grid container className={classes.doctorCallFinishTop}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.doctorCallFinishContainer}>
          <Grid item xs={12} className={classes.doctorCallFinish}>
            <img src={successCircle} alt="consultancy-success" />
            <Typography className={classes.title} variant="h1" component="h2">
              {t<string>('successfulConsultancy')}
            </Typography>
            <Typography className={classes.duration} component="p">
              {durationString}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.consultancyInfo}>
        <Typography className={classes.mainTitle} variant="h1" component="h2">
          {t<string>('heading')}
        </Typography>
        <div className={classes.buttonContainer}>
          <WhiteButton
            onClick={() => navigate('/professional/call-feedback')}
            className={classes.footerButtonProblem}
          >
            {t<string>('problem')}
          </WhiteButton>
        </div>
      </Grid>
    </Fragment>
  )
}

export { DoctorCallFinish }
