import { get } from './request'

export async function fetchLanguageUrls() {
  try {
    const serverSideConfig = await get<any>('/config')
    return serverSideConfig.languageUrls
  } catch (err) {
    console.error('Failed to fetch server side config', err)
  }
}
