import React, { useEffect } from 'react'
import { useStoreState } from '../store/store.hooks'
import { FeatureDisabled } from './FeatureDisabled'

const FeatureAwareRoute = ({ component: Component, path, feature }) => {
  const { accountFeaturesBySku: features } = useStoreState(
    (state) => state.subscription
  )

  const canNavigate = features && features[feature] === true

  if (!canNavigate) {
    return <FeatureDisabled />
  }

  return (
    <>
      <Component />
    </>
  )
}

FeatureAwareRoute.displayName = 'FeatureAwareRoute'
export { FeatureAwareRoute }
export default FeatureAwareRoute
