/*
    Copyright © DokiApp, 2021
*/

import React from 'react'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const FullPageSpinner = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

FullPageSpinner.displayName = 'FullPageSpinner'

FullPageSpinner.propTypes = {}

FullPageSpinner.defaultProps = {}

export default FullPageSpinner
export { FullPageSpinner }
