export const checkBrowser = () => {
  const browsers = {
    chrome: false,
    firefox: false,
    safari: false,
  }
  // Get the user-agent string
  const userAgentString = navigator.userAgent

  // Detect Chrome
  browsers.chrome = userAgentString.indexOf('Chrome') > -1

  // Detect Firefox
  browsers.firefox = userAgentString.indexOf('Firefox') > -1

  // Detect Safari
  browsers.safari = userAgentString.indexOf('Safari') > -1

  // Discard Safari since it also matches Chrome
  if (browsers.chrome && browsers.safari) browsers.safari = false

  return browsers
}
