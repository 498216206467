/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowForward as ArrowForwardIcon,
} from '@material-ui/icons'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton, WhiteButton } from '../../components/Button'
import { ConfirmDialog } from '../../components'
import { useTranslation } from 'react-i18next'
import {
  get as executeGet,
  deleteReq as executeDelete,
} from '../../services/request'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { MissingResponses } from './MissingResponses'

const useStyles = makeStyles((theme) => ({
  arrowForward: {
    fontSize: '3rem',
    color: '#D92B6E',
  },
  arrowUpward: {
    fontSize: '4rem',
    color: '#D92B6E',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonContainer: {
    margin: '0 2rem',
  },
  centered: {
    textAlign: 'center',
  },
  consultationButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '1px solid #6473AF',
  },
  desktopView: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      visibility: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
  evaluationList: {
    margin: '0 2rem',
  },
  evaluationListItem: {
    textAlign: 'center',
    fontSize: '1rem',
  },
  evaluationTable: {
    margin: '0 2rem',
  },
  explanation: {
    margin: '2rem',
    fontSize: '1.1rem',
    fontWeight: '600',
    textAlign: 'justify',
  },
  mobileView: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
      visibility: 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      visibility: 'visible',
    },
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  questionContainer: {
    margin: '0 2rem 2rem',
    textAlign: 'justify',
  },
  selected: {
    fontWeight: '800',
  },
  startNewTestButton: {
    width: '90%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',

    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: 30,
    },
  },
  tableCell: {
    padding: 0,
    border: 'none',
  },
  timeNeeded: {
    margin: '2rem',
    textAlign: 'center',
  },
  totalScore: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '2rem',
    },
  },
}))

const SurveyEvaluation = ({ surveyId }) => {
  const [open, setOpen] = useState(false)
  const [evaluation, setEvaluation] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const {
    i18n: { language },
    t,
  } = useTranslation('survey')
  const classes = useStyles()
  const { relativeNavigate } = useRelativeNavigate()

  const showConfirmDialog = () => {
    setOpen(true)
  }

  const closeConfirmDialog = () => {
    setOpen(false)
  }

  const handleReset = async () => {
    await executeDelete(`/surveys/${surveyId}/responses`)
    closeConfirmDialog()
    relativeNavigate(`/surveys/${surveyId}/questions`)
  }

  const handleStartConsultation = () => {
    relativeNavigate('/dashboard')
  }

  useEffect(() => {
    const fetchEvaluation = async () => {
      setLoading(true)
      setError(null)

      try {
        const previousEvaluation = await executeGet(
          `/surveys/${surveyId}/responses?lng=${language}`
        )
        setEvaluation(previousEvaluation)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchEvaluation()
  }, [])

  if (!evaluation) {
    return null
  }

  if (!evaluation.hasResponses) {
    return <MissingResponses surveyId={surveyId} />
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.page}>
        <NavigateBack />
        <Grid item xs={12} className={classes.pageHeader}>
          <Heading>{t('evaluationHeader')}</Heading>
        </Grid>

        <Grid container className={classes.mobileView}>
          <Table className={classes.evaluationTable} padding="0">
            <TableBody>
              {evaluation.evaluations.map((e) => (
                <TableRow key={`evaluation-${e.bgColor}`}>
                  <TableCell className={classes.tableCell}>
                    <Box>
                      {e.text === evaluation.evaluation.text && (
                        <ArrowForwardIcon className={classes.arrowForward} />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Box
                      sx={{ bgcolor: e.bgColor, p: 2, color: e.textColor }}
                      className={
                        e.text === evaluation.evaluation.text
                          ? classes.selected
                          : ''
                      }
                    >
                      {e.text}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid
          container
          className={[classes.evaluationList, classes.desktopView]}
        >
          {evaluation.evaluations.map((e) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={`evaluation-${e.bgColor}`}
              className={classes.evaluationListItem}
            >
              <Box
                sx={{ bgcolor: e.bgColor, p: 2, color: e.textColor }}
                className={
                  e.text === evaluation.evaluation.text ? classes.selected : ''
                }
              >
                {e.text}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          className={[classes.evaluationList, classes.desktopView]}
        >
          {evaluation.evaluations.map((e) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={`evaluation-${e.bgColor}`}
              className={classes.evaluationListItem}
            >
              <Box>
                {e.text === evaluation.evaluation.text && (
                  <ArrowUpwardIcon className={classes.arrowUpward} />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} className={classes.totalScore}>
          <Typography>
            {t('totalScoreOutOfMaxScore')}: {evaluation.totalScore} /{' '}
            {evaluation.maxScore}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.explanation}
          dangerouslySetInnerHTML={{
            __html: evaluation.evaluation.description,
          }}
        ></Grid>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          className={classes.buttonContainer}
        >
          <Grid item xs={6} className={classes.centered}>
            <GradientButton
              className={classes.startNewTestButton}
              onClick={showConfirmDialog}
            >
              {t('evaluationStartNewTest')}
            </GradientButton>
          </Grid>
          <Grid item xs={6} className={classes.centered}>
            <WhiteButton
              className={classes.consultationButton}
              onClick={handleStartConsultation}
            >
              {t('consultationButton')}
            </WhiteButton>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.timeNeeded}>
          <Typography>{t('timeNeeded')}</Typography>
        </Grid>

        <ConfirmDialog
          visible={open}
          message={t('evaluationPopupWarning')}
          cancelText={t('cancelButton')}
          confirmText={t('evaluationStartNewTest')}
          onConfirm={handleReset}
          onCancel={closeConfirmDialog}
        />
      </Grid>
    </>
  )
}

SurveyEvaluation.displayName = 'SurveyEvaluation'

SurveyEvaluation.propTypes = {}

SurveyEvaluation.defaultProps = {}

export default SurveyEvaluation
export { SurveyEvaluation }
