const constants = {
  doctorConsultancy: {
    title: 'Orvossal beszélek',
    description: 'Testi, fizikai tüneteim vannak',
    price: '6990 Ft',
    styles: {
      backgroundColor: '#EF1E5B',
      background:
        'linear-gradient(90deg, rgb(235,46,103) 0%, rgb(229,62,116) 100%)',
    },
  },
  psychologistConsultancy: {
    title: 'Pszichológussal beszélek',
    description: 'Mentális vagy lelki segítségre van szükségem',
    price: '6990 Ft',
    styles: {
      backgroundColor: '#3CAAF0',
      background:
        'linear-gradient(90deg, rgb(64,171,239) 0%, rgb(83,179,240) 100%)',
    },
  },
  coronaConsultancy: {
    title: 'Koronavírus konzultáció',
    description:
      'Csak akkor válaszd ezt az opciót, ha konkrétan a korona vírussal kapcsolatban szeretnél konzultálni.',
    price: '6990 Ft',
  },
  coronaConsultation: {
    styles: {
      background:
        'linear-gradient(90deg, rgba(183,220,243,0) 0%, rgba(183,220,243,0.5) 100%)',
      backgroundColor: '#6473AF',
    },
  },
}

export { constants }
