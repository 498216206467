/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

const CheckboxAnswer = ({ question, onAnswered, disabled }) => {
  const [value, setValue] = useState('')

  const handleChange = (event) => {
    setValue(event.target.value)

    const selectedAnswer = question.answers.find(
      (a) => a.answer === event.target.value
    )
    if (onAnswered instanceof Function) {
      const answer = {
        question,
        answer: { ...selectedAnswer, checked: event.target.checked },
      }
      onAnswered(answer)
    }
  }

  const hasAnswers =
    question && Array.isArray(question.answers) && question.answers.length > 0
  const answers = hasAnswers
    ? question.answers.map((answer) => (
        <FormControlLabel
          key={answer.id}
          name={answer.id}
          value={answer.answer}
          disabled={disabled}
          control={
            <Checkbox onChange={handleChange} style={{ color: '#F01E5A' }} />
          }
          label={answer.answer}
        />
      ))
    : null

  return (
    <>
      <FormGroup>{answers}</FormGroup>
    </>
  )
}

CheckboxAnswer.displayName = 'CheckboxAnswer'

CheckboxAnswer.propTypes = {}

CheckboxAnswer.defaultProps = {}

export default CheckboxAnswer
export { CheckboxAnswer }
