import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: '60px 60px 0 60px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  complaintDetail: {
    marginTop: 30,
    padding: '0 70px',
    color: '#1E2864',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  patientInfoContainer: {
    borderRadius: 15,
    border: '1px solid #C1C7DF',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
  },
  patientInfoUpper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C1C7DF',
    padding: 20,
  },
  avatar: {
    width: 80,
    height: 80,
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    marginRight: 15,
  },
  patientName: {
    margin: 0,
    fontSize: 24,
    fontWeight: 300,
  },
  patientInfoLower: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  complaintDetailInput: {
    width: '100%',
    borderRadius: 15,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#6473AF',
  },
  complaints: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  complaintDetailText: {
    fontSize: 20,
    marginTop: 5,
  },
  doctorFeedback: {
    margin: '30px 0',
  },
  complaintDetailFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  complaintDetailFooterButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF',
    marginLeft: '70px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 280,
    },
  },
  complaintDetailBackground: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
}))

export { useStyles }
