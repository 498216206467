const supportedLanguages = [
  {
    locales: ['hu', 'hu-hu'],
    targetLocale: 'hu',
  },
  {
    locales: ['en', 'en-us', 'en-gb'],
    targetLocale: 'en',
  },
  {
    locales: ['ua', 'uk-ua'],
    targetLocale: 'ua',
  },
]

const validateLocales = (lng) => {
  if (lng && typeof lng === 'string') lng = lng.toLowerCase()

  const language = supportedLanguages.find((language) =>
    language.locales.some((locale) => locale.toLowerCase().indexOf(lng) > -1)
  )

  if (language !== undefined) return language.targetLocale

  // There was no match in the hashtable above
  console.warn(
    'helpers/languageFormatter/validateLocales: unsupported language encountered',
    lng
  )

  return supportedLanguages[0].targetLocale
}

export { validateLocales }
export default validateLocales
