import React, { FunctionComponent, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import TrustLogo from '../../assets/img/trust_logo.png'
import TrustLogoEn from '../../assets/img/trust_logo_en.png'
import TrustLogoUa from '../../assets/img/trust_logo_ua.png'
import { useTranslation } from 'react-i18next'
import validateLocales from '../../helpers/languageFormatter'

interface HeadingProps {
  enableTrustLogo?: boolean
  children?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '40px',
    margin: 0,
    color: '#1E2864',
    letterSpacing: '0',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
  },
  trustLogo: {
    position: 'absolute',
    top: '-105px',
    right: 0,
    maxHeight: '210px',

    [theme.breakpoints.down('sm')]: {
      maxHeight: '130px',
      top: '-105px',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '90px',
      top: '-75px',
    },
  },
}))

const Heading: FunctionComponent<HeadingProps> = ({
  children,
  enableTrustLogo,
}) => {
  const classes = useStyles()
  const { i18n } = useTranslation()
  const [logo, setLogo] = useState('')

  useEffect(() => {
    const handleLogo = () => {
      switch (validateLocales(i18n.language)) {
        case 'en':
          setLogo(TrustLogoEn)
          break

        case 'ua':
          setLogo(TrustLogoUa)
          break

        default:
          setLogo(TrustLogo)
      }
    }
    handleLogo()
  }, [i18n.language])

  return (
    <h1 className={classes.heading}>
      {children}
      {enableTrustLogo && (
        <img src={logo} className={classes.trustLogo} alt="trust-logo" />
      )}
    </h1>
  )
}

export { Heading }
