import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: '50px 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  patientMatchTop: {
    borderRadius: '15px 15px 0 0',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: '15px 15px 0 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  patientMatchContainer: {
    zIndex: 3,
  },
  name: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 200,
    fontFamily: 'Nunito, Arial, sans-serif',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  birth: {
    color: '#B7DCF3',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  timeLimit: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  consultancyInfo: {
    backgroundColor: '#fff',
    padding: 40,
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
    },
  },
  avatar: {
    height: 128,
    width: 128,
  },
  patientFound: {
    fontSize: 20,
    color: '#fff',
    marginBottom: 5,
  },
  callButton: {
    maxWidth: 400,
    marginTop: 15,
  },
  callIcon: {
    marginRight: 10,
  },
  complaintLabel: {
    color: '#6473AF',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  complaintList: {
    fontWeight: 'bold',
    fontSize: 24,
    marginBottom: 5,
  },
  complaintText: {
    fontSize: 20,
  },
  download: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E0E3EF',
    padding: '10px 20px',
    maxWidth: 350,
    marginTop: 50,
    borderRadius: 15,
    color: '#6473AF',
  },
}))

export { useStyles }
