import React from 'react'

import { Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { NavigateBack } from '../../components/Nav/NavigateBack'

type Props = {
  textForm: {
    type: string
    text: string
  }[]
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  bodyContainer: {
    padding: '10px 30px 60px 30px',
    maxWidth: '760px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  header: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '49px',
    color: '#1E2864',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '50px',
  },
  subheader: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#1E2864',
    marginBottom: '25px',
    marginTop: '15px',
  },
  text: {
    fontFamily: 'Nunito, Arial, sans-serif',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#1E2864',
    marginBottom: '50px',
  },
}))

export const TextPage = (props: Props) => {
  const classes = useStyles()

  const renderHeader = (text: string) => {
    return <Typography className={classes.header}>{text}</Typography>
  }

  const renderSubheader = (text: string) => {
    return <Typography className={classes.subheader}>{text}</Typography>
  }

  const renderText = (text: string) => {
    return <Typography className={classes.text}>{text}</Typography>
  }

  const { textForm } = props

  return (
    <div className={classes.container}>
      <NavigateBack />
      <Container component="main" className={classes.bodyContainer}>
        {textForm.map((textBlock) => {
          if (textBlock.type === 'header') {
            return renderHeader(textBlock.text)
          } else if (textBlock.type === 'subheader') {
            return renderSubheader(textBlock.text)
          } else if (textBlock.type === 'text') {
            return renderText(textBlock.text)
          }
        })}
      </Container>
    </div>
  )
}
