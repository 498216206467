import { action, thunk } from 'easy-peasy'
import { RequestModel } from './request.interface'
import {
  fetchRequestTypeList,
  fetchRequesterInfo,
  getUserRequests,
  fetchComplaints,
} from '../../services/request.service'
import { CONSULTANCY_TYPES } from '../../constants'
import _ from 'lodash'

export const requestModel: RequestModel = {
  requests: undefined,
  requestId: undefined,
  providerTypeId: undefined,
  requestTypeList: [],
  requestTypeIds: [],
  requestDescription: undefined,
  requestDescriptionImage: undefined,
  requestDescriptionImageName: undefined,
  requestFeedbackDescription: undefined,
  requesterInfo: {},
  payedAsOneOff: false,
  termsAndConditionsAccepted: false,
  providerResponse: undefined,
  setRequestId: action((state, payload) => {
    state.requestId = payload
  }),
  setProviderTypeId: action((state, payload) => {
    state.providerTypeId = payload
  }),
  setLanguage: action((state, payload) => {
    state.language = payload
  }),
  setRequestTypeList: action((state, payload) => {
    state.requestTypeList = payload
  }),
  fetchRequestTypeList: thunk(async (actions, payload, helpers) => {
    const { providerTypeId, language, specialization } = helpers.getState()
    if (!providerTypeId) throw new Error('providerTypeId is not set yet!')
    const helperActions: any = helpers.getStoreActions()
    helperActions.layout.setIsLoading(true)
    const contents = await fetchRequestTypeList({
      providerTypeId,
      language,
      specialization,
    })
    actions.setRequestTypeList(contents)
    helperActions.layout.setIsLoading(false)
  }),

  complaints: [],
  selectedComplaintIds: [],
  fetchComplaints: thunk(async (actions, payload, helpers) => {
    const { language, specialization } = helpers.getState()

    const helperActions: any = helpers.getStoreActions()
    helperActions.layout.setIsLoading(true)

    const complaints = await fetchComplaints({
      language,
      specialization,
    })

    actions.setComplaints(complaints)
    helperActions.layout.setIsLoading(false)
  }),
  setComplaints: action((state, payload) => {
    state.complaints = payload
  }),
  setSelectedComplaintIds: action((state, payload) => {
    state.selectedComplaintIds = payload
  }),

  childName: undefined,
  setChildName: action((state, payload) => {
    state.childName = payload
  }),
  childAge: undefined,
  setChildAge: action((state, payload) => {
    state.childAge = payload
  }),

  attachments: [],
  setAttachments: action((state, payload) => {
    state.attachments = payload
  }),

  fetchRequests: thunk(async (actions, _, helpers) => {
    const { language } = helpers.getState()
    const requests = await getUserRequests({ language })
    actions.setRequests(requests)
  }),
  fetchRequesterInfo: thunk(async (actions, payload, helpers) => {
    const helperActions: any = helpers.getStoreActions()
    helperActions.layout.setIsLoading(true)
    const requesterInfo = await fetchRequesterInfo()
    actions.setRequesterInfo(requesterInfo)
    helperActions.layout.setIsLoading(false)
  }),
  chooseCorona: thunk(async (actions, payload, helpers) => {
    // TODO: Later change it to CORONA
    const { language } = helpers.getState()
    const providerTypeId = CONSULTANCY_TYPES.DOCTOR
    actions.setProviderTypeId(1)
    const helperActions: any = helpers.getStoreActions()
    helperActions.layout.setIsLoading(true)
    const contents = await fetchRequestTypeList({ providerTypeId, language })
    let corona = _.find(contents, { title: 'Koronavírus' })

    if (!corona) {
      corona = _.find(contents, { title: 'Coronavirus' })
    }

    // DUH handle DEV env
    if (!corona) {
      corona = contents[0]
    }
    actions.setRequestTypeList(contents)
    actions.setRequestTypeIds([corona.id])
    helperActions.layout.setIsLoading(false)
  }),
  setRequesterInfo: action((state, payload) => {
    state.requesterInfo = payload
  }),
  setPayedAsOneOff: action((state, payload) => {
    state.payedAsOneOff = payload
  }),
  setRequests: action((state, payload) => {
    state.requests = payload
  }),
  setRequestTypeIds: action((state, payload) => {
    state.requestTypeIds = payload
  }),
  setRequestDescription: action((state, payload) => {
    state.requestDescription = payload
  }),
  setRequestDescriptionImage: action((state, payload) => {
    state.requestDescriptionImage = payload
  }),
  setRequestDescriptionImageName: action((state, payload) => {
    state.requestDescriptionImageName = payload
  }),
  setRequestFeedbackDescription: action((state, payload) => {
    state.requestFeedbackDescription = payload
  }),
  setTermsAndConditionsAccepted: action((state, payload) => {
    state.termsAndConditionsAccepted = payload
  }),
  resetRequestState: action((state) => {
    state.providerTypeId = undefined
    state.requestTypeList = []
    state.requestTypeIds = []
    state.complaints = []
    state.selectedComplaintIds = []
    state.requestDescription = undefined
    state.requestDescriptionImage = undefined
    state.termsAndConditionsAccepted = false
    state.requestId = undefined
    state.requestFeedbackDescription = undefined
    state.providerResponse = undefined
    state.requestDescriptionImageName = undefined
    state.specialization = undefined
    state.childName = undefined
    state.childAge = undefined
    state.productSku = undefined
    state.attachments = []
  }),
  setProviderResponse: action((state, payload) => {
    state.providerResponse = payload
  }),
  setSpecialization: action((state, payload) => {
    state.specialization = payload
  }),
  setProductSku: action((state, payload) => {
    state.productSku = payload
  }),
}
