import { ROUTES } from './routes'

const EXTERNAL_FILES = Object.freeze({
  en: {
    APP_PRIVACY_POLICY: ROUTES.INTL_PRIVACY_POLICY_FOR_WEB_APPLICATION,
    PRIVACY_POLICY: ROUTES.INTL_PRIVACY_POLICY,
    TERMS_AND_CONDITIONS: ROUTES.INTL_GENERAL_TERMS_AND_CONDITIONS,
  },
  hu: {
    APP_PRIVACY_POLICY: ROUTES.PRIVACY_POLICY_FOR_WEB_APPLICATION,
    PRIVACY_POLICY: ROUTES.PRIVACY_POLICY,
    TERMS_AND_CONDITIONS: ROUTES.GENERAL_TERMS_AND_CONDITIONS,
  },
  ua: {
    APP_PRIVACY_POLICY: ROUTES.INTL_PRIVACY_POLICY_FOR_WEB_APPLICATION,
    PRIVACY_POLICY: ROUTES.INTL_PRIVACY_POLICY,
    TERMS_AND_CONDITIONS: ROUTES.INTL_GENERAL_TERMS_AND_CONDITIONS,
  },
})

export { EXTERNAL_FILES }
