import React, { Fragment, useState } from 'react'
import { navigate } from '@reach/router'

import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish'
import CloseIcon from '@material-ui/icons/Close'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { NavigateBack } from '../../components/Nav/NavigateBack'
import { Heading } from '../../components/Text'
import { FormInput } from '../../components/FormInput'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { PediatricsSpecialization } from './PediatricsSpecialization'

import successCircleIcon from '../../assets/img/done.png'

import { useStoreActions, useStoreState } from '../../store/store.hooks'

import { useStyles } from './styles'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UploadAttachments } from '../../components'

type FormData = {
  requestDescription: string
  childName: string
  childAge: number
}

const MAX_IMAGE_FILE_SIZE = 3145728 // 3 * 1024 * 1024 bytes
const MAX_FILE_NAME_LENGTH = 22

const ComplaintDetail = () => {
  const classes = useStyles()

  const {
    setRequestDescription,
    setRequestDescriptionImage,
    setRequestDescriptionImageName,
    setChildName,
    setChildAge,
  } = useStoreActions((actions) => actions.request)

  const {
    providerTypeId,
    requestDescription,
    requestDescriptionImageName,
    specialization,
    childName,
    childAge,
  } = useStoreState((state) => state.request)

  const form = useForm<FormData>({
    defaultValues: {
      childName,
      childAge,
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
  } = form

  const { t } = useTranslation('complaintsDetail')
  const [uploadError, setUploadError] = useState(false)
  const [error, setError] = useState<string>('')
  const hasSpecialization = !!specialization

  const onSubmit = handleSubmit((values) => {
    setError('')
    setRequestDescription(values.requestDescription)
    setChildName(values.childName)
    setChildAge(values.childAge)

    if (!values.requestDescription) {
      setError(t<string>('missingRequestDescription'))
      return
    }

    if (specialization) {
      if (!values.childName || !values.childAge) {
        setError(t<string>('missingNameOrAge'))
        return
      }
    }

    navigate('/patient/complaint-sum')
  })

  const onFileChange = async (file: any) => {
    const reader = new FileReader()
    reader.onloadend = async () => {
      setRequestDescriptionImage(reader.result as string)
      setRequestDescriptionImageName(file.name)
    }
    reader.readAsDataURL(file)
  }

  const handleDeleteImage = (e: any) => {
    setRequestDescriptionImage(undefined as unknown)
    setRequestDescriptionImageName(undefined as unknown)
    e.preventDefault()
  }

  const { ref: requestDescriptionInputRef, ...requestDescriptionInputProps } =
    register('requestDescription')

  const formatFileName = (fileName: string) => {
    if (fileName?.length > MAX_FILE_NAME_LENGTH)
      return fileName.substring(0, MAX_FILE_NAME_LENGTH - 3) + '...'
    return fileName
  }

  let specializationComponent = null

  switch (specialization) {
    default:
      specializationComponent = null
      break

    case 'pediatrics':
      specializationComponent = <PediatricsSpecialization form={form} />
      break
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container className={classes.complaintDetailBackground}>
        <NavigateBack />
        <Grid item xs={12} className={classes.ComplaintDetailContainer}>
          <Heading>
            {providerTypeId === 2
              ? t<string>('headingPsycho')
              : t<string>('headingDoc')}
          </Heading>
          <div className={classes.complaintDetail}>
            <Typography component="h4" className={classes.subheading}>
              {t<string>('subheading')}
            </Typography>
            <Typography component="p" className={classes.paragraph}>
              {providerTypeId === 2
                ? t<string>('paragraphPsycho')
                : t<string>('paragraphDoc')}
            </Typography>

            <FormInput
              multiline
              rows="10"
              placeholder={t<string>('inputLabel')}
              label={t<string>('inputLabel')}
              inputName="requestDescription"
              error={!!errors.requestDescription}
              errorMessage={t<string>('errorMessage')}
              clearError={() => {
                clearErrors('requestDescription')
              }}
              defaultValue={requestDescription}
              inputRef={requestDescriptionInputRef}
              {...requestDescriptionInputProps}
            />
            <Grid container className={classes.fileInputContainer}>
              <UploadAttachments />
            </Grid>

            {hasSpecialization && (
              <Box className={classes.specializationBox}>
                <Typography component="p" className={classes.paragraph}>
                  {t<string>('specializationHeader')}
                </Typography>

                {/* Depending on the type of specialization, show the proper component here */}
                {specializationComponent}
              </Box>
            )}

            {error && (
              <Box className={classes.errorContainer}>
                <Typography className={classes.errorText}>{error}</Typography>
              </Box>
            )}
          </div>
        </Grid>

        <Grid xs={12} container className={classes.complaintDetailFooter}>
          <WhiteButton
            type="submit"
            className={classes.complaintDetailFooterButton}
          >
            {t<string>('buttonText')}
            <ArrowForwardIosIcon />
          </WhiteButton>
        </Grid>
      </Grid>
    </form>
  )
}

export { ComplaintDetail }
