import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { StoreProvider } from 'easy-peasy'

import App from './App'
import theme from './theme'
import store from './store'
import { SingletonHooksContainer } from 'react-singleton-hook'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from 'react-mixpanel'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import { FullPageSpinner } from './components'
import { AccountContextProvider, AudioVideoContextProvider } from './hooks'

const resolveEnvironment = () => {
  const {
    location: { host },
  } = window
  if (!host) {
    return 'unknown'
  }

  if (host.match(/rendelo|clinic/i)) {
    return 'production'
  } else if (host.match(/tesztelo|tester/i)) {
    return 'staging'
  } else if (host.match(/localhost|127.0.0.1/i)) {
    return 'local'
  }

  return 'unknown'
}

Sentry.init({
  dsn: 'https://c156295774174ba88a5abdb00e2fa60f@o1077435.ingest.sentry.io/6080355',
  //release: process.env.REACT_APP_SENTRY_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  environment: resolveEnvironment(),
  tracesSampleRate: 1.0,
})

mixpanel.init('a423e6957f112b47b6ee4f79b53b3078')

type Props = StoreProvider['props'] & { children: React.ReactNode }
const StoreProviderCast = StoreProvider as unknown as React.ComponentType<Props>

ReactDOM.render(
  <StoreProviderCast store={store}>
    <ThemeProvider theme={theme}>
      <MixpanelProvider mixpanel={mixpanel}>
        <AccountContextProvider>
          <AudioVideoContextProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <SingletonHooksContainer />
            <Suspense fallback={<FullPageSpinner />}>
              <App />
            </Suspense>
          </AudioVideoContextProvider>
        </AccountContextProvider>
      </MixpanelProvider>
    </ThemeProvider>
  </StoreProviderCast>,
  document.querySelector('#root')
)
