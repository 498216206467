import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontSize: '24px',
    margin: 0,
    color: '#1E2864',
    letterSpacing: '0',
    fontWeight: 200,
    marginTop: '20px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
}))

type Props = {
  children?: React.ReactNode
}

const SubHeading: FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles()

  return <h1 className={classes.subHeading}>{children}</h1>
}

export { SubHeading }
