/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const UnsupportedBrowser = () => {
  const { t } = useTranslation('twilio-diagnostics')

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={10}>
          <Typography variant="h4" gutterBottom>
            {t('unsupportedBrowserFirstLine')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t('unsupportedBrowserSecondLine')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

UnsupportedBrowser.displayName = 'UnsupportedBrowser'

UnsupportedBrowser.propTypes = {}

UnsupportedBrowser.defaultProps = {}

export default UnsupportedBrowser
export { UnsupportedBrowser }
