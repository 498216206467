import React, { Fragment, useEffect } from 'react'
import { navigate } from '@reach/router'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

import { Heading } from '../../../components/Text'
import { GradientButton } from '../../../components/Button'
import { useStoreState, useStoreActions } from '../../../store/store.hooks'
import { useInterval } from '../../../helpers/useInterval'

import { useRelativeNavigate } from '../../../hooks/useRelativeNavigate'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  onlinePatientsContainer: {
    padding: '60px 30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#EFF9FF',
    borderRadius: '15px',
    textAlign: 'center',

    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  helpOptions: {
    marginTop: '40px',
  },
  outerCircle: {
    height: 250,
    width: 250,
    marginTop: 50,
    borderRadius: '50%',
    boxShadow: '0 20px 40px 0 rgba(100,115,175,0.4)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexDirection: 'column',
    background: '#FFFFFF',
  },
  innerCircle: {
    borderRadius: '50%',
    border: '10px solid #6473AF',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.4)',
    position: 'absolute',
    top: '10%',
    bottom: '10%',
    left: '10%',
    right: '10%',
    opacity: '0.2',
  },
  patientCount: {
    fontWeight: 'bold',
    fontSize: 50,
  },
  onlinePatients: {
    color: '#6473AF',
    fontSize: 16,
    fontWeight: 'bold',
  },
  consultancyStats: {
    display: 'flex',
    marginTop: 60,
    marginBottom: 20,
  },
  consultancyStat: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 15px',
    maxWidth: 110,
  },
  count: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  countText: {
    opacity: '0.8',
    fontSize: 12,
    fontWeight: 'bold',
  },
  searchForPatientButton: {
    marginTop: 20,
    maxWidth: 400,
  },
  microphoneTestButton: {
    marginTop: 15,
    maxWidth: 400,
  },
  warningText: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#1E2864',
    marginTop: 25,
    padding: '0 20px',
  },
  validationWarningText: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    padding: '20px 60px',
    margin: '30px 40px 0 40px',
    backgroundColor: 'white',
    border: '1px solid #E3E6F0',
    borderRadius: 15,
    color: '#F01E5A',

    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      margin: '30px 5px 0',
    },
  },
}))

const OnlinePatients = () => {
  const classes = useStyles()
  const { relativeNavigate } = useRelativeNavigate()

  const { profileContents } = useStoreState((state) => state.profile)
  const { providerInfoContents } = useStoreState((state) => state.providerInfo)
  const { fetchProviderInfo, putAvailability } = useStoreActions(
    (actions) => actions.providerInfo
  )
  const { t } = useTranslation('onlinePatients')
  useEffect(() => {
    fetchProviderInfo({})
  }, [fetchProviderInfo])

  useInterval(() => {
    fetchProviderInfo({})
  }, 5000)

  if (providerInfoContents?.isAvailable) {
    navigate('/professional/patient-search')
    return null
  }

  async function onPatientSearch() {
    await putAvailability(true)
  }

  const hasTestedAudioAndVideoDevices =
    sessionStorage.getItem('hasTestedAudioAndVideoDevices') !== 'true'

  return (
    <Fragment>
      <Grid item xs={12} className={classes.onlinePatientsContainer}>
        <Heading>{t<string>('heading')}</Heading>

        <div className={classes.outerCircle}>
          <div className={classes.innerCircle}></div>
          <span className={classes.patientCount}>
            {providerInfoContents?.availableRequesters}
          </span>
          <span className={classes.onlinePatients}>
            {t<string>('onlinePatients')}
          </span>
        </div>

        <div className={classes.consultancyStats}>
          <div
            className={classes.consultancyStat}
            style={{
              borderRight: '1px solid rgba(100,115,175,0.2)',
              color: '#97D344',
            }}
          >
            <span className={classes.count}>
              {providerInfoContents?.successfulNr}
            </span>
            <span className={classes.countText}>
              {t<string>('consultancySuccess')}
            </span>
          </div>
          <div
            className={classes.consultancyStat}
            style={{
              borderRight: '1px solid rgba(100,115,175,0.2)',
              color: '#F01E5A',
            }}
          >
            <span className={classes.count}>
              {providerInfoContents?.failedNr}
            </span>
            <span className={classes.countText}>
              {t<string>('consultancyFail')}
            </span>
          </div>
          <div className={classes.consultancyStat}>
            <span className={classes.count}>
              {Math.ceil((providerInfoContents?.callSeconds ?? 0) / 60)}
            </span>
            <span className={classes.countText}>
              {t<string>('consultancyTime')}
            </span>
          </div>
        </div>

        {!profileContents.validated ? (
          <Typography className={classes.validationWarningText}>
            {t<string>('validationWarning')}
          </Typography>
        ) : null}

        {hasTestedAudioAndVideoDevices ? (
          <div>
            <Typography className={classes.warningText}>
              {t<string>('testWarning')}
            </Typography>
            <GradientButton
              disabled={!profileContents.validated}
              onClick={() => relativeNavigate('/audio-video-test')}
              className={classes.microphoneTestButton}
            >
              {t<string>('microphoneTest')}
            </GradientButton>
          </div>
        ) : null}

        <GradientButton
          disabled={!profileContents.validated || hasTestedAudioAndVideoDevices}
          onClick={onPatientSearch}
          className={classes.searchForPatientButton}
        >
          {t<string>('searchForPatient')}
        </GradientButton>
      </Grid>
    </Fragment>
  )
}

export { OnlinePatients }
