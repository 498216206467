import React from 'react'

import firefox from '../../../../assets/img/microphone_tutorial/firefox.png'
import firefox0 from '../../../../assets/img/microphone_tutorial/firefox0.png'

const FirefoxMicHelpDetail = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: 30 }}>
      <p>1. Kattints az alábbi képen láthatö X ikonra </p>
      <div
        style={{
          backgroundImage: `url(${firefox0})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'left top',
          backgroundSize: '140%',
        }}
      ></div>
      <p>
        1. Kattints az alábbi képen látható 'Allow' vagy 'Engedélyezem gombra'{' '}
      </p>
      <div
        style={{
          backgroundImage: `url(${firefox})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'left',
          backgroundSize: '120%',
        }}
      ></div>
    </div>
  )
}

export { FirefoxMicHelpDetail }
