import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  block: {
    width: '76%',
    textAlign: 'center',
    position: 'relative',
    marginTop: '-15px',
    marginBottom: '15px',
    margin: '0 12% 0',
    color: '#6473AF',

    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 10,
      height: '1px',
      width: '38%',
      background: '#6473AF',
    },

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: 10,
      right: 0,
      height: '1px',
      width: '38%',
      background: '#6473AF',
    },
  },
}))

const SeparatorBlock = () => {
  const classes = useStyles()
  const { t } = useTranslation('separatorBlock')
  return <Typography className={classes.block}>{t<string>('or')}</Typography>
}

export { SeparatorBlock }
