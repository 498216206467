/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Code as CodeIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { GradientButton } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { useForm } from 'react-hook-form'
import { post } from '../../services/request'

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    margin: '2rem 1rem 1rem 1rem',
  },
  disclaimerText: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
  firstStep: {
    marginLeft: '1rem',
    fontWeight: 'bold',
    color: '#1E2864',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
  },
  gateContainer: {
    borderRadius: '1rem',
    boxShadow: '0 10px 20px 0 rgba(100, 115, 175, 0.4)',
    padding: '1rem 2rem 2rem 1rem',
    justifyContent: 'center',
  },
  icon: {
    color: '#1E2864',
  },
  inputBox: {
    margin: '1rem',
  },
  inputGrid: {
    justifyContent: 'center',
  },
  invalidVoucher: {
    fontWeight: 'bold',
  },
  submitSection: {
    display: 'flex',
    justifyContent: 'center',
  },
  validationResult: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  voucherInput: {
    '& input.MuiInputBase-input': {
      textTransform: 'uppercase',
    },
  },
}))

const Gate = ({ onValidationCompleted }) => {
  const classes = useStyles()
  const { t } = useTranslation('survey')
  const {
    watch,
    formState: { errors, isValid },
    register,
    clearErrors,
    handleSubmit,
  } = useForm()
  const [loading, setLoading] = useState(false)
  const [validationResult, setValidationResult] = useState()

  const { ref: voucherInputRef, ...voucherInputProps } = register('voucher', {
    required: {
      value: true,
      message: t('corporatePsychoDiagVoucherValidationError'),
    },
  })

  const onSubmit = handleSubmit(async (values) => {
    if (isValid) {
      try {
        setLoading(true)
        const { voucher } = values
        const validationResult = await post('/coupon/validate', {
          couponCode: voucher,
        })

        setValidationResult(validationResult)

        if (onValidationCompleted instanceof Function) {
          onValidationCompleted(validationResult)
        }
      } catch (err) {
        console.error('Failed to validate voucher', err)
      } finally {
        setLoading(false)
      }
    }
  })

  return (
    <>
      <form onSubmit={onSubmit}>
        <Grid container className={classes.gateContainer}>
          <Grid item xs={12} className={classes.header}>
            <CodeIcon className={classes.icon} />
            <Typography className={classes.firstStep}>
              {t('corporatePsychoDiagFirstStep')}
            </Typography>
          </Grid>

          <Grid item container md={4} xs={12} className={classes.inputGrid}>
            <Grid item xs={12} className={classes.inputBox}>
              <FormInput
                inputName="voucher"
                className={classes.voucherInput}
                placeholder={t('corporatePsychoDiagEnterVoucher')}
                value={watch('voucher')}
                defaultValue={''}
                clearError={() => clearErrors(['voucher'])}
                inputRef={voucherInputRef}
                error={!!errors.voucher}
                errorMessage={errors.voucher && errors.voucher.message}
                {...voucherInputProps}
                disableEndAdornment={true}
              />
            </Grid>

            {!loading && validationResult && !validationResult.isValid && (
              <Grid item xs={12} className={classes.validationResult}>
                <Typography className={classes.invalidVoucher} gutterBottom>
                  {t('corporatePsychoDiagInvalidVoucher')}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6} className={classes.submitSection}>
              {!loading && (
                <GradientButton type="submit">
                  {t('corporatePsychoDiagVoucherButtonText')}
                </GradientButton>
              )}

              {loading && <CircularProgress />}
            </Grid>
          </Grid>

          {!loading && (
            <Grid item xs={12} className={classes.disclaimer}>
              <Typography
                className={classes.disclaimerText}
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: t('corporatePsychoDisclaimer'),
                }}
              ></Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  )
}

Gate.displayName = ''

export default Gate
export { Gate }
