import React, { FunctionComponent } from 'react'
import { Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'

type Props = {
  checkedState?: boolean
}

const useStyles = makeStyles(() => ({
  iconContainer: {
    width: 30,
    height: 30,
    borderWidth: 2,
    borderRadius: 15,
    borderStyle: 'solid',
  },
  tickIcon: {
    marginBottom: '6px',
    color: '#CF3F7C',
    fontSize: 20,
  },
}))

const CheckboxSelector: FunctionComponent<Props> = ({ checkedState }) => {
  const classes = useStyles()

  return (
    <Checkbox
      checked={checkedState}
      icon={
        <div
          className={classes.iconContainer}
          style={{ borderColor: checkedState ? '#CF3F7C' : '#C1C7DF' }}
        />
      }
      checkedIcon={
        <div
          className={classes.iconContainer}
          style={{ borderColor: checkedState ? '#CF3F7C' : '#C1C7DF' }}
        >
          <Check className={classes.tickIcon} />
        </div>
      }
    />
  )
}

export { CheckboxSelector }
