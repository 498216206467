import { action, thunk } from 'easy-peasy'
import { AuthModel } from './auth.interface'
import { authService } from '../../services/authService'

const authModel: AuthModel = {
  email: '',
  password: '',
  token: null,
  setEmail: action((state, payload) => {
    state.email = payload
  }),
  setPassword: action((state, payload) => {
    state.password = payload
  }),
  setToken: action((state, payload) => {
    state.token = payload
  }),
  loginUser: thunk(async (actions, payload) => {
    await authService.login(payload)
  }),
  resetAuthState: action((state) => {
    state.email = ''
    state.password = ''
    state.token = null
    localStorage.removeItem('token')
  }),
}

export { authModel }
