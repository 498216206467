import { LocalVideoTrack, Room } from 'twilio-video'
import { useCallback } from 'react'
import { LocalTracks } from './types'

export default function useLocalVideoToggle({
  room: { localParticipant },
  localTracks,
  getLocalVideoTrack,
}: {
  room: Room
  localTracks: LocalTracks
  getLocalVideoTrack: () => Promise<LocalVideoTrack>
}) {
  const videoTrack = localTracks.find(
    (track) => track.name === 'camera'
  ) as LocalVideoTrack

  const toggleVideoEnabled = useCallback(() => {
    if (videoTrack) {
      if (localParticipant) {
        const localTrackPublication =
          localParticipant.unpublishTrack(videoTrack)
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit('trackUnpublished', localTrackPublication)
      }
      videoTrack.stop()
    } else {
      getLocalVideoTrack().then((track: LocalVideoTrack) => {
        if (localParticipant) {
          localParticipant.publishTrack(track)
        }
      })
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack])

  return [!!videoTrack, toggleVideoEnabled] as const
}
