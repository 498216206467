/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  iframe: {
    backgroundColor: 'white',
  },
}))

const Iframe = ({ height, scrolling, src, width }) => {
  const classes = useStyles()

  return (
    <>
      <iframe
        src={src}
        frameBorder={0}
        scrolling={scrolling}
        width={width}
        height={height}
        className={classes.iframe}
      ></iframe>
    </>
  )
}

Iframe.displayName = 'Iframe'

Iframe.propTypes = {
  height: PropTypes.string,
  scrolling: PropTypes.bool,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
}

Iframe.defaultProps = {
  height: '1024px',
  scrolling: true,
  width: '100%',
}

export default Iframe
export { Iframe }
