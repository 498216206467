import { action, thunk } from 'easy-peasy'
import { FeaturePriceModel, SubscriptionModel } from './subscription.interface'
import * as subscriptionService from '../../services/subscription.service'
import { AccountFeature } from '../accounts/accounts.interface'

const subscriptionModel: SubscriptionModel = {
  id: undefined,
  plan: undefined,
  features: [],
  account: undefined,
  subscriptionRetrieved: false,
  accountFeatures: [],
  activeVoucher: undefined,

  fetchSubscription: thunk(async (actions) => {
    actions.setSubscriptionRetrieved(false)

    const subscription = await subscriptionService.fetchSubscription()
    actions.setId(subscription.id)
    actions.setPlan(subscription.plan)
    actions.setFeatures(subscription.features)
    actions.setAccount(subscription.account)
    actions.setAccountFeatures(subscription.accountFeatures)
    actions.setActiveVoucher(subscription.activeVoucher)
    actions.setSubscriptionRetrieved(true)
  }),
  setId: action((state, payload) => {
    state.id = payload
  }),
  setPlan: action((state, payload) => {
    state.plan = payload
  }),
  setFeatures: action((state, payload) => {
    state.features = payload

    const featuresBySku: Record<string, FeaturePriceModel> = {}
    for (const product of payload) {
      featuresBySku[product.sku] = product
    }

    state.featuresBySku = featuresBySku
  }),
  setAccount: action((state, payload) => {
    state.account = payload
  }),
  setSubscriptionRetrieved: action((state, payload) => {
    state.subscriptionRetrieved = payload
  }),
  setAccountFeatures: action((state, payload) => {
    state.accountFeatures = payload

    const accountFeaturesBySku: Record<string, boolean> = {}
    for (const accountFeature of payload) {
      accountFeaturesBySku[accountFeature.sku] = accountFeature.enabled
    }

    state.accountFeaturesBySku = accountFeaturesBySku
  }),
  setActiveVoucher: action((state, payload) => {
    state.activeVoucher = payload
  }),
}

export { subscriptionModel }
