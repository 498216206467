import React from 'react'

import step1 from '../../../../assets/img/microphone_tutorial/step1.png'
import step2 from '../../../../assets/img/microphone_tutorial/step2.png'

const ChromeMicHelpDetail = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: 30 }}>
      <p>
        1. Kattints az alábbi képen jobb alsó sarokban látható mikrofon ikonra,
        a böngészö keresö sávjában.{' '}
      </p>
      <div
        style={{
          backgroundImage: `url(${step1})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'right',
        }}
      ></div>
      <p>
        2. Kattints az alábbi képen lévö beállításra, hogy engedélyezve legyen a
        mikrofon.
      </p>
      <div
        style={{
          backgroundImage: `url(${step2})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'right',
          backgroundSize: '270%',
        }}
      ></div>
    </div>
  )
}

export { ChromeMicHelpDetail }
