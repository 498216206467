/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { GradientButton } from '../../components/Button/GradientButton'
import { Heading } from '../../components/Text'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#fff',
    borderRadius: '15px',
    paddingTop: 20,
  },
  button: {
    width: 'auto',
  },
  buttonContainer: {
    marginBottom: 60,
    marginTop: 40,
    textAlign: 'center',
  },
  details: {
    textAlign: 'center',
  },
  statusText: {
    fontSize: 20,
    margin: 20,
  },
}))

const EmailConfirmation = (
  {
    /* Props come here */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('emailConfirmation')
  // We won't care about errors here
  const statusText = t('confirmed')

  const navigateHome = () => navigate('/')

  return (
    <>
      <Grid container className={classes.background}>
        <Grid item xs={12}>
          <Heading>{t('header')}</Heading>
          <div className={classes.details}>
            <Typography component="h3" className={classes.statusText}>
              {statusText}
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <GradientButton className={classes.button} onClick={navigateHome}>
              {t('navigate')}
            </GradientButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

EmailConfirmation.displayName = 'EmailConfirmation'

EmailConfirmation.propTypes = {}

EmailConfirmation.defaultProps = {}

export default EmailConfirmation
export { EmailConfirmation }
