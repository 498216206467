import { action, thunk } from 'easy-peasy'
import { ProfessionalModel } from './professional.interface'
import * as professionalService from '../../services/professional.service'

const professionalModel: ProfessionalModel = {
  meetings: [],

  fetchMeetings: thunk(async (actions) => {
    const meetings = await professionalService.fetchMeetings()
    actions.setMeetings(meetings)
  }),

  setMeetings: action((state, payload) => {
    state.meetings = payload
  }),
}

export { professionalModel }
