import React from 'react'
import { Box, Typography, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import searchLogo from '../../../../../../assets/img/guideline_tutorial/4.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '2%',
  },
  heading: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '34px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      marginBottom: '5px',
      fontSize: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  subHeading: {
    fontSize: '24px',
    marginBottom: '3%',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  image: {
    width: '40%',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '15px',
    },
  },
  textBlock: {
    margin: '0 16%',
    marginTop: '1%',

    [theme.breakpoints.down('xs')]: {
      margin: '0 5%',
    },
  },
  text: {
    lineHeight: 1.5,
    fontSize: '26px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
}))

const FifthPageTutorialContent = () => {
  const classes = useStyles()
  const { t } = useTranslation('fifthPageTutorialContent')
  return (
    <Container className={classes.container}>
      <Typography variant="h3" component="p" className={classes.heading}>
        {t<string>('text1')}
      </Typography>

      <Box flexGrow={1}>
        <Typography variant="h4" component="p" className={classes.subHeading}>
          {t<string>('text2')}
        </Typography>

        <img src={searchLogo} className={classes.image} alt="" />

        <Box className={classes.textBlock}>
          <Typography variant="h5" component="p" className={classes.text}>
            {t<string>('text3')}
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export { FifthPageTutorialContent }
