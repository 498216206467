import React, { Fragment } from 'react'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'

import { WhiteButton } from '../../../../Button'
import { checkBrowser } from '../../../../../util/checkBrowser'
import { useStoreActions } from '../../../../../store/store.hooks'
import { ChromeCamHelp } from '../CamHelp/ChromeCamHelp'
import { FirefoxCamHelp } from '../CamHelp/FirefoxCamHelp'
import { SafariCamHelp } from '../CamHelp/SafariCamHelp'

enum Strings {
  title = 'Engedélyezd a kamerahozzáférést',
  bodyText = 'Továbbhaladáshoz engedélyezd a kamera hozzáférést a böngészoben a kovetkezo módon:.',
  cancel = 'Rendben',
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    padding: 15,
    color: '#fff',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  dialogText: {
    textAlign: 'center',
    padding: '0 10%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 5%',
    },
  },
  dialogContentText: {
    color: '#fff',
    fontWeight: 200,
  },
  dialogFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFF9FF',
    padding: 25,
  },
  footerButton: {
    width: '50%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
  },
}))

const CamHelp = () => {
  const classes = useStyles()
  const { setHelperModal } = useStoreActions((actions) => actions.layout)

  const setDone = () => {
    setHelperModal({ isOpen: false, type: '' })
    window.location.reload()
  }

  let content
  const browser = checkBrowser()

  if (browser.chrome) content = <ChromeCamHelp />
  if (browser.firefox) content = <FirefoxCamHelp />
  if (browser.safari) content = <SafariCamHelp />

  return (
    <Fragment>
      <div className={classes.modalBody}>
        <div className={classes.dialogText}>
          <DialogTitle id="alert-dialog-title">{Strings.title}</DialogTitle>
          <DialogContent>
            <DialogContentText
              className={classes.dialogContentText}
              id="alert-dialog-description"
            >
              {Strings.bodyText}
            </DialogContentText>
            {content}
          </DialogContent>
        </div>
      </div>
      <DialogActions className={classes.dialogFooter}>
        <WhiteButton className={classes.footerButton} onClick={() => setDone()}>
          {Strings.cancel}
        </WhiteButton>
      </DialogActions>
    </Fragment>
  )
}

export { CamHelp }
