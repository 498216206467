/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AnswerBox from './AnswerBox'

const useStyles = makeStyles((theme) => ({
  question: {},
  questionHeader: {
    margin: '1rem 2rem 0.5rem 2rem',
    textAlign: 'left',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  smallerQuestionHeader: {
    margin: '1rem 2rem 0.5rem 2rem',
    textAlign: 'left',
    fontSize: '1rem',
  },
  answer: {
    margin: '0 2rem 2rem 2rem',
    textAlign: 'justify',
  },
}))

const PagedSurveyQuestionList = ({
  questions,
  onAnswered,
  disabled,
  category,
}) => {
  const classes = useStyles()
  const hasQuestions = Array.isArray(questions) && questions.length > 0

  if (!hasQuestions) {
    return null
  }

  const questionHeaderClassName =
    category === 'corporate-diagnostics'
      ? classes.smallerQuestionHeader
      : classes.questionHeader

  return questions.map((question) => (
    <React.Fragment key={`question-${question.id}`}>
      <Grid
        key={`question-grid-${question.id}-question`}
        item
        xs={12}
        className={classes.question}
      >
        <Typography className={questionHeaderClassName}>
          {question.question}
        </Typography>
      </Grid>
      <Grid
        key={`question-grid-${question.id}-answers`}
        item
        xs={12}
        className={classes.answer}
      >
        <AnswerBox
          question={question}
          onAnswered={onAnswered}
          disabled={disabled}
        />
      </Grid>
    </React.Fragment>
  ))
}

PagedSurveyQuestionList.displayName = 'PagedSurveyQuestionList'

PagedSurveyQuestionList.propTypes = {}

PagedSurveyQuestionList.defaultProps = {}

export default PagedSurveyQuestionList
export { PagedSurveyQuestionList }
