const normalizeLanguage = (i18nLanguage) => {
  if (i18nLanguage) {
    if (i18nLanguage.toLowerCase().indexOf('en') > -1) {
      return 'en'
    } else if (i18nLanguage.toLowerCase().indexOf('ua') > -1) {
      return 'ua'
    }
  }

  return 'hu'
}

export default normalizeLanguage
export { normalizeLanguage }
