import { action, thunk } from 'easy-peasy'
import { Setting, SettingsModel } from './settings.interface'
import * as settingsService from '../../services/settings.service'

const settingsModel: SettingsModel = {
  settings: [],

  fetchSettings: thunk(async (actions) => {
    const settings = await settingsService.fetchSettings()
    actions.setSettings(settings)
  }),

  setSettings: action((state, payload) => {
    state.settings = payload

    const settingsByKey: Record<string, any> = {}
    for (const setting of payload) {
      settingsByKey[setting.key] = setting.value
    }

    state.settingsByKey = settingsByKey
  }),
}

export { settingsModel }
