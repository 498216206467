import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

import { useStoreState } from '../../../store/store.hooks'

import { MicHelp } from './ModalContent/MicHelp'
import { CamHelp } from './ModalContent/CamHelp/CamHelp'
import { Guideline } from './ModalContent/Guideline/Guideline'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 15,
  },
  guideline: {
    borderRadius: 15,
    width: 1000,
    maxWidth: 1000,
  },
}))

const HelperModal = () => {
  const classes = useStyles()

  const {
    layout: { helperModal },
  } = useStoreState((state) => state)

  const modalContent = () => {
    if (helperModal) {
      switch (helperModal.type) {
        case 'guideline':
          return <Guideline />
        case 'mic':
          return <MicHelp />
        default:
          return <CamHelp />
      }
    }
  }

  const content = modalContent()

  if (!helperModal) {
    return null
  }

  return (
    <Dialog
      open={helperModal.isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
      PaperProps={{
        classes: {
          root:
            helperModal.type === 'guideline' ? classes.guideline : classes.root,
        },
      }}
    >
      {content}
    </Dialog>
  )
}

export { HelperModal }
