import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useStyles } from './styles'
import { useStoreState, useStoreActions } from '../../store/store.hooks'
import dayjs from 'dayjs'

import { getTimerString } from '../../services/duration.service'

export function CallLength() {
  const { incomingRequestContents, call } = useStoreState(
    (state) => state.incomingRequest
  )
  const { fetchCall, setCall } = useStoreActions(
    (state) => state.incomingRequest
  )
  const classes = useStyles()

  const calculateTimeSpent = () => {
    if (!call?.recipientConnectedAt) return '00:00'
    const startTime = call?.recipientConnectedAt

    const difference = dayjs().diff(startTime)

    const timeSpent = {
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    }

    return getTimerString(timeSpent)
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeSpent())

  useEffect(() => {
    // lets empty the timer
    setCall({} as any)
    // fetch with delay so recipientConnectedAt will be set for sure
    setTimeout(() => {
      fetchCall(incomingRequestContents?.id as number)
    }, 3000)
  }, [incomingRequestContents?.id])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeSpent())
    }, 1000)
  })

  return <Typography className={classes.timer}>{timeLeft}</Typography>
}
