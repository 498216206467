import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: '0 30px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
      paddingBottom: 0,
    },
  },
  successfulPaymentTop: {
    borderRadius: '15px 15px 0 0',
    position: 'relative',
    background: 'linear-gradient(45deg, #3FA7F0 0%, #F01E5A 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 25,
    },
  },
  navigateBackContainer: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    justifyContent: 'flex-end',
  },
  navigateBackLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '16px',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  navigateBackLinkIcon: {
    marginLeft: 5,
    opacity: '0.6',
  },
  bgLayover: {
    backgroundColor: '#1E2864',
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: '0.5',
    right: 0,
    left: 0,
    borderRadius: '15px 15px 0 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 'unset',
    },
  },
  successfulPaymentContainer: {
    zIndex: 3,
  },
  paymentTitle: {
    color: '#fff',
    fontSize: 28,
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'Nunito, Arial, sans-serif',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  paymentAlert: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 200,
    textAlign: 'center',
    padding: '0 15%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  loading: {
    color: '#fff',
  },
  mainTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  consultancyInfo: {
    backgroundColor: '#fff',
    padding: 40,
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 25px',
    },
  },
  subheading: {
    fontSize: 20,
    padding: '0 15%',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  notificationsButton: {
    marginBottom: theme.spacing(4),
  },
}))

export { useStyles }
