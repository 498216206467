import React, { useEffect } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
//import { useForm } from 'react-hook-form'
import { Heading } from '@/components/Text'
import ProfessionalMeetingList from './ProfessionalMeetingList'

const useStyles = makeStyles((theme) => ({
  meetingsContainer: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },
}))

const ProfessionalMeetings = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('professionalMeetings')
  const { fetchMeetings } = useStoreActions((action) => action.professional)
  const { meetings } = useStoreState((state) => state.professional)

  useEffect(() => {
    fetchMeetings()
  }, [fetchMeetings])

  return (
    <>
      <Grid item xs={12} className={classes.meetingsContainer}>
        <Box display="flex" justifyContent="center" width="100%">
          <Heading>{t('heading')}</Heading>
        </Box>

        <ProfessionalMeetingList meetings={meetings} />
      </Grid>
    </>
  )
}

ProfessionalMeetings.displayName = 'ProfessionalMeetings'
export { ProfessionalMeetings }
export default ProfessionalMeetings
