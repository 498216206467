import React, { FunctionComponent, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Select, FormLabel, MenuItem } from '@material-ui/core'

type Props = {
  containerClassName?: string
  labelClassName?: string
  inputClassName?: string
  valueOptions: { value: string; label: string }[]
  valueIndex: number
  className?: string
  handleChange?: any
  error?: boolean
  register?: any
  name?: string
  label: string
  fullWidth?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiOutlinedInput-root:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #C1C7DF',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#6473AF',
      },
    },
  },
  select: {
    minWidth: 120,
    color: '#1E2864',
    marginBottom: '30px',
    borderRadius: '15px',
    backgroundColor: '#fff',
    marginTop: theme.spacing(1),
    fontFamily: 'Nunito, Arial, sans-serif',
    textAlign: 'left',
    borderColor: 'red',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C1C7DF',
    },
  },
  inputLabel: {
    color: '#6473AF',
    float: 'left',
    marginLeft: 15,
    fontSize: 12,
  },
  menuItem: {
    color: '#6473AF',
  },
}))

const FormSelect: FunctionComponent<Props> = ({
  containerClassName,
  inputClassName,
  labelClassName,
  handleChange,
  valueOptions,
  valueIndex,
  className,
  register,
  name,
  error,
  label,
  fullWidth,
}) => {
  const classes = useStyles()

  const selectClasses = className
    ? [classes.select, className].join(' ')
    : classes.select

  const labelClasses = labelClassName
    ? [classes.inputLabel, labelClassName].join(' ')
    : classes.inputLabel

  const containerClasses = containerClassName
    ? [classes.container, containerClassName].join(' ')
    : classes.container

  useEffect(() => {
    register && register()
  }, [register])

  return (
    <div className={containerClasses}>
      <FormLabel className={labelClasses}>{label}</FormLabel>
      <Select
        displayEmpty
        error={error}
        name={name}
        value={valueIndex}
        onChange={handleChange}
        className={selectClasses}
        variant={'outlined'}
        classes={{
          root: inputClassName,
        }}
        defaultValue={valueIndex}
        fullWidth={fullWidth}
      >
        {valueOptions.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              className={classes.menuItem}
            >
              {item.label}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  )
}

export { FormSelect }
