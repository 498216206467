import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  complaintSumContainer: {
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    color: '#6473AF',
  },
  root: {
    borderRadius: 15,
    border: '1px solid #C1C7DF',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
  },
  consultWithContainer: {
    borderBottom: '1px solid #C1C7DF',
  },
  consultWith: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  complaintSum: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  complaintDetail: {
    fontSize: 20,
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  faqContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  faqList: {
    marginTop: 50,
    padding: '0 15%',
    fontSize: 20,
    color: '#1E2864',
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
      padding: '0 40px',
      fontSize: 16,
    },
  },
  faqListItem: {
    marginBottom: 20,
  },
  complaintDetailFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  complaintDetailFooterButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF',
    [theme.breakpoints.down('xs')]: {
      width: 280,
    },
  },
  consultancyPriceContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  consultancyPrice: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  buttonContainer: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  pricaLayoutHelper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileMarginTop30: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  complaintsSum: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  originalPrice: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  discountedPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#97D344',
  },
  oldPrice: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#F01E5A',
    textDecoration: 'line-through',
    display: 'inline-block',
    marginRight: 20,
  },
}))

export { useStyles }
