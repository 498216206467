import React, { useState, useEffect, useCallback } from 'react'
import {
  MenuItem,
  Typography,
  FormControl,
  makeStyles,
  Select,
} from '@material-ui/core'
import { useDevices } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  form: {
    margin: '0.5em 0 1em',
  },
  deviceLabelContainer: {
    margin: '0.8em 0',
  },
  deviceList: {
    height: '36px',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
    '& svg': {
      marginRight: '0.3em',
    },
  },
})

const AudioDevice = ({
  disabled,
  kind,
  onDeviceChange,
  setDeviceError,
  error,
}) => {
  const { t } = useTranslation('twilio-diagnostics')

  const labels = {
    audioinput: {
      audioLevelText: t('audioDeviceInputLevel'),
      headerText: t('audioDeviceInputHeader'),
    },
    audiooutput: {
      audioLevelText: t('audioDeviceOutputLevel'),
      headerText: t('audioDeviceOutputHeader'),
    },
  }

  const classes = useStyles()
  const devices = useDevices()

  const audioDevices =
    kind === 'audiooutput'
      ? devices.audioOutputDevices
      : devices.audioInputDevices
  const [selectedDevice, setSelectedDevice] = useState('')
  const { headerText } = labels[kind]
  const noAudioRedirect = !Audio.prototype.setSinkId && kind === 'audiooutput'

  const updateSelectedDevice = useCallback(
    (value) => {
      onDeviceChange(value)
      setSelectedDevice(value)
      setDeviceError('')
    },
    [onDeviceChange, setSelectedDevice, setDeviceError]
  )

  useEffect(() => {
    const hasSelectedDevice = audioDevices.some(
      (device) => device.deviceId === selectedDevice
    )
    if (audioDevices.length && !hasSelectedDevice) {
      updateSelectedDevice(audioDevices[0].deviceId)
    }
  }, [audioDevices, devices, selectedDevice, updateSelectedDevice])

  return (
    <>
      <Typography variant="subtitle2">
        <strong>{headerText}</strong>
      </Typography>

      {noAudioRedirect && (
        <div className={classes.deviceLabelContainer}>
          <Typography variant="body1">
            {t('audioDeviceDefaultOutput')}
          </Typography>
        </div>
      )}

      {!noAudioRedirect && (
        <FormControl
          variant="outlined"
          disabled={disabled}
          className={classes.form}
          fullWidth
        >
          <Select
            value={selectedDevice}
            onChange={(e) => updateSelectedDevice(e.target.value)}
            className={classes.deviceList}
          >
            {audioDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {error && (
        <div className={classes.error}>
          <Typography variant="subtitle2" color="error">
            {error === 'No audio detected'
              ? t('audioDeviceNoAudioDetected')
              : t('audioDeviceUnableToConnect')}
          </Typography>
        </div>
      )}
    </>
  )
}

export default AudioDevice
export { AudioDevice }
