import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { AppNavigation } from './AppNavigation'
import { BackgroundOverlay } from './BackgroundOverlay'
import { SideMenu } from '../SideMenu/SideMenu'
import { Notification } from '../Notification'
import { HelperModal } from '../Modals'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  mainLayout: {
    fontFamily: 'Nunito, Arial, sans-serif',
    color: '#1E2864',
  },
  mainContentContainer: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '100vh',
    backgroundColor: '#e1e8f0',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    position: 'relative',
    // backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  copyRight: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: '10px',
    marginTop: '60px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
    },
    '@media print': {
      display: 'none',
    },
  },
}))

type Props = {
  children: any
}

const MainLayout: FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles()
  const { t } = useTranslation('mainLayout')
  return (
    <div className={classes.mainLayout}>
      <Container
        className={classes.mainContentContainer}
        maxWidth={false}
        disableGutters={true}
      >
        <SideMenu />
        <BackgroundOverlay />
        <AppNavigation />
        <Container
          disableGutters={true}
          className={classes.contentContainer}
          maxWidth="md"
        >
          <React.Fragment>{children}</React.Fragment>
        </Container>
        <div className={classes.copyRight}>
          <Typography>
            {`${new Date().getFullYear()}${t('copyRight')}`}
          </Typography>
        </div>
      </Container>
      <Notification />
      <HelperModal />
    </div>
  )
}

export { MainLayout }
