import React, { FunctionComponent } from 'react'

import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'

import { consultancyOptionsStyles } from './styles'
import { useStoreState } from '../../../store/store.hooks'
import { WhiteButton } from '../../../components/Button/WhiteButton'
import { useTranslation } from 'react-i18next'

type Props = {
  description?: string
  title: string
  price?: string
  notes?: string
  styles?: any
  className?: string
  handleClick?: any
  placeholder?: boolean
  placeholderText?: string
}

const ConsultancyOption: FunctionComponent<Props> = ({
  description,
  price,
  title,
  notes,
  styles,
  className,
  handleClick,
  placeholder,
  placeholderText,
}) => {
  const { t } = useTranslation('consultancyOption')
  const { isMobile } = useStoreState((state) => state.layout)
  const { background, backgroundColor } = styles
  const classes = consultancyOptionsStyles()
  const extraClassName = className || ''
  const consultancyPriceClassName = isMobile
    ? [classes.consultancyPrice, classes.consultancyPriceMobile].join(' ')
    : classes.consultancyPrice

  return (
    <Card
      className={[classes.root, extraClassName].join(' ')}
      style={{ background: background, backgroundColor: backgroundColor }}
      onClick={isMobile ? handleClick : null}
    >
      <CardContent>
        {title && (
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
        )}
        {!placeholder && (
          <>
            <Typography className={classes.pos}>{description}</Typography>
            <Typography
              className={consultancyPriceClassName}
              variant="body2"
              component="p"
            >
              {t<string>('consultancyPrice')} <strong>{price}</strong>
              <br />
              <strong>{notes}</strong>
            </Typography>
          </>
        )}
        {placeholder && (
          <Typography className={classes.placeholder}>
            <span
              dangerouslySetInnerHTML={{ __html: placeholderText || '' }}
            ></span>
          </Typography>
        )}
      </CardContent>
      {!placeholder && (
        <CardActions className={classes.consultancyActions}>
          <Typography
            component="span"
            className={classes.coronaCardButtonLabel}
          >
            <>
              {t<string>('callDurationText')}
              <strong>
                <AccessAlarmIcon
                  className={classes.coronaCardButtonLabelIcon}
                />
                {t<string>('callDurationDoc')}
              </strong>
            </>
          </Typography>

          <WhiteButton onClick={handleClick}>
            <>
              {t<string>('buttonText')}
              <ArrowForwardIosIcon />
            </>
          </WhiteButton>
        </CardActions>
      )}
    </Card>
  )
}

export { ConsultancyOption }
