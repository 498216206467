const MAX_FILE_NAME_LENGTH = 22

const formatFileName = (fileName, maxLength = MAX_FILE_NAME_LENGTH) => {
  if (fileName && fileName.length > maxLength)
    return fileName.substring(0, maxLength - 3) + '...'

  return fileName
}

export default formatFileName
export { formatFileName }
