import React from 'react'
import { Box, Typography, Container } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import dokiLogo from '../../../../../../assets/img/guideline_tutorial/1.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '5%',
  },
  heading: {
    fontSize: '34px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
  },
  text: {
    lineHeight: 1.5,
    fontSize: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  logo: {
    minWidth: '220px',
    width: '38%',

    [theme.breakpoints.down('xs')]: {
      marginTop: '55px',
    },
  },
}))

const FirstPageTutorialContent = () => {
  const classes = useStyles()
  const { t } = useTranslation('firstPageTutorialContent')
  return (
    <Container className={classes.container}>
      <img src={dokiLogo} className={classes.logo} alt="" />

      <Box mt={5}>
        <Typography className={classes.heading} component="p">
          <strong>{t<string>('welcome')}</strong>
        </Typography>
      </Box>

      <Box mt={1}>
        <Typography className={classes.text} component="p">
          {t<string>('text1')} <br />
          {t<string>('text2')}
        </Typography>
      </Box>
    </Container>
  )
}

export { FirstPageTutorialContent }
