import React, { ChangeEvent } from 'react'
import { navigate } from '@reach/router'

import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'

import { WhiteButton } from '../../../components/Button/WhiteButton'
import { CheckboxSelector } from '../../../components/CheckboxSelector'
import { useTranslation } from 'react-i18next'

type AddressDetails = { id: number; name: string; fullAddress: string }

type Props = {
  selectedAddressIndex?: number
  isProfilePageVersion?: boolean
  billingAddressList?: AddressDetails[]
  selectActiveBillingAddress?: (event: ChangeEvent<{}>, index: number) => void
  editBillingAddress?: (addressIndex: number) => void
  deleteBillingAddress?: (addressIndex: number) => void
  disabled?: boolean
}

const useStyles = makeStyles(() => ({
  boxLabel: {
    color: '#6473AF',
    marginLeft: 15,
    marginBottom: 10,
    fontWeight: 500,
    fontSize: 12,
  },
  button: {
    width: '100%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
  },
  dataSelectButtonContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: 20,
  },
  dataSelectButton: {
    border: '1px solid #6473AF;',
    justifyContent: 'start',
    marginBottom: 0,
    padding: 0,
    paddingLeft: 10,
  },
  dataContainer: {
    marginBottom: '40px',
  },
  dataBlock: {
    padding: 10,
    paddingLeft: 5,
  },
  dataTitle: {
    color: '#1E2864',
    fontSize: 16,
  },
  cardIcon: {
    marginRight: 5,
  },
  cardNumber: {
    color: '#6473AF',
    fontWeight: 500,
    fontSize: 16,
  },
  editButton: {
    top: 0,
    right: 0,
    width: 60,
    zIndex: 100,
    height: '94%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  deleteIcon: {
    top: 20,
    right: 0,
    width: 60,
    zIndex: 100,
    position: 'absolute',
    color: '#6473AF',
    cursor: 'pointer',
  },
  dataRow: {
    width: '100%',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '1px solid #6473AF;',
    borderRadius: 10,
    paddingLeft: 10,
    color: '#6473AF',
  },
  infoText: {
    color: '#1E2864',
    fontSize: '0.8rem',
    textAlign: 'justify',
    margin: '1rem 0',
  },
}))

const BillingAdderssBlock = (props: Props) => {
  const {
    selectedAddressIndex,
    billingAddressList,
    isProfilePageVersion,
    selectActiveBillingAddress,
    deleteBillingAddress,
    editBillingAddress,
    disabled,
  } = props
  const { t } = useTranslation('billingAddressBlock')
  const classes = useStyles()

  const renderBillingAddressRow = (address: AddressDetails) => {
    return (
      <Box
        display="flex"
        alignItems="start"
        flexDirection="column"
        className={classes.dataBlock}
      >
        <Typography className={classes.dataTitle}>{address.name}</Typography>
        <Box display="flex">
          <Typography className={classes.cardNumber}>
            {address.fullAddress}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <div className={classes.dataContainer}>
      <Typography className={classes.boxLabel}>{t<string>('title')}</Typography>
      <Typography
        className={classes.infoText}
        dangerouslySetInnerHTML={{ __html: t<string>('info') }}
      />
      {billingAddressList?.map((address: any, index: number) => {
        return (
          <div key={index} className={classes.dataSelectButtonContainer}>
            {isProfilePageVersion ? (
              <div>
                <div className={classes.dataRow}>
                  {renderBillingAddressRow(address)}
                  <WhiteButton
                    disabled={disabled}
                    onClick={() => {
                      deleteBillingAddress && deleteBillingAddress(address.id)
                    }}
                    className={classes.editButton}
                  >
                    <Delete className={classes.deleteIcon} />
                  </WhiteButton>
                </div>
              </div>
            ) : (
              <div>
                <WhiteButton
                  disabled={disabled}
                  onClick={(event: ChangeEvent<{}>) => {
                    selectActiveBillingAddress &&
                      selectActiveBillingAddress(event, index)
                  }}
                  className={classes.button + ' ' + classes.dataSelectButton}
                >
                  {billingAddressList.length > 1 ? (
                    <CheckboxSelector
                      checkedState={selectedAddressIndex === index}
                    />
                  ) : null}
                  {renderBillingAddressRow(address)}
                </WhiteButton>
                <WhiteButton
                  disabled={disabled}
                  onClick={() => {
                    editBillingAddress && editBillingAddress(index)
                  }}
                  className={classes.editButton}
                >
                  <Edit />
                </WhiteButton>
              </div>
            )}
          </div>
        )
      })}
      <WhiteButton
        disabled={disabled}
        onClick={() => navigate('/patient/billing-address')}
        className={classes.button}
      >
        {t<string>('buttonText')}
      </WhiteButton>
    </div>
  )
}

export { BillingAdderssBlock }
