import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { Heading } from '../../components/Text/Heading'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  dashBoardContainer: {
    padding: '60px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  p: {
    whiteSpace: 'pre',
  },
}))

const WhatIsDokiApp = () => {
  const { t }: { t: any } = useTranslation('whatIsDokiApp')
  const classes = useStyles()

  return (
    <Fragment>
      <Grid item xs={12} className={classes.dashBoardContainer}>
        <Heading>{t('heading')}</Heading>

        <p>{t('text1')}</p>
        <p>{t('text2')}</p>
        <p className={classes.p}>{t('text3')}</p>
      </Grid>
    </Fragment>
  )
}

export { WhatIsDokiApp }
