import React from 'react'

import safari from '../../../../../assets/img/cam_tutorial/safari.png'

const SafariCamHelp = () => {
  return (
    <div style={{ textAlign: 'left', marginTop: 30 }}>
      <p>
        1. Kattints az alábbi képen látható 'Allow' vagy 'Engedélyezem gombra'{' '}
      </p>
      <div
        style={{
          backgroundImage: `url(${safari})`,
          height: 200,
          width: '100%',
          backgroundPosition: 'center top',
          backgroundSize: '100%',
        }}
      ></div>
    </div>
  )
}

export { SafariCamHelp }
