import React from 'react'
import EnSvg from '../../assets/img/en.svg'
import HuSvg from '../../assets/img/hu.svg'
import UaSvg from '../../assets/img/ua.svg'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  iconBtn: {
    width: '90%',
  },
  iconBtnMargin: {
    width: '90%',
    marginLeft: '20%',
  },
  iconBtnDisplay: {
    width: '5%',
    marginLeft: '0',
  },
  en: {
    width: '35px',
  },
  enMargin: {
    width: '35px',
  },
  hu: {
    width: '35px',
  },
  huMargin: {
    width: '35px',
    marginBottom: '3px',
  },
  ua: {
    width: '35px',
  },
  uaMargin: {
    width: '35px',
  },
}))

const CountryFlag = ({ countryCode, state }) => {
  const classes = useStyles()

  const getClass = () => {
    if (state === 'listItem') {
      return classes.iconBtn
    } else if (state === 'display') {
      return classes.iconBtnDisplay
    } else {
      return classes.iconBtnMargin
    }
  }
  switch (countryCode) {
    case 'en':
      return (
        <IconButton
          className={() => {
            return getClass()
          }}
        >
          <img
            src={EnSvg}
            alt="English"
            className={state === 'listItem' ? classes.en : classes.enMargin}
          />
        </IconButton>
      )

    case 'ua':
      return (
        <IconButton
          className={() => {
            return getClass()
          }}
        >
          <img
            src={UaSvg}
            alt="Ukranian country flag"
            className={state === 'listItem' ? classes.ua : classes.uaMargin}
          />
        </IconButton>
      )

    default:
      return (
        <IconButton
          className={() => {
            return getClass()
          }}
        >
          <img
            src={HuSvg}
            alt="Hungarian country flag"
            className={state === 'listItem' ? classes.hu : classes.huMargin}
          />
        </IconButton>
      )
  }
}
export default CountryFlag
export { CountryFlag }
