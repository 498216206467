import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ComplaintDetailContainer: {
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  complaintDetail: {
    marginTop: 30,
    padding: '0 70px',
    color: '#1E2864',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  subheading: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paragraph: {
    fontSize: 20,
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      fontSize: 16,
    },
  },
  input: {
    display: 'none',
  },
  fileInputContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      flexDirection: 'column',
    },
    textAlign: 'center',
  },
  fileInput: {
    borderRadius: 15,
    backgroundColor: 'rgba(100,115,175,0.1)',
    border: '1px dashed rgba(100,115,175,0.4)',
    color: '#6473AF',
    fontSize: 16,
    padding: '10px 20px',
    textTransform: 'unset',
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      marginBottom: 10,
    },
  },
  uploadHelp: {
    maxWidth: 300,
    color: '#6473AF',
    fontSize: 12,
  },
  complaintDetailFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  complaintDetailFooterButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
    marginLeft: '70px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: 280,
    },
  },
  fileInputContainerLabel: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  complaintDetailBackground: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  closeIcon: {
    color: 'rgba(240,30,90,0.8)',
  },
  specializationBox: {
    marginTop: '4rem',
  },
  errorContainer: {
    marginTop: '1rem',
    textAlign: 'center',
  },
  errorText: {
    color: '#1E2864',
    fontWeight: 'bold',
  },
}))

export { useStyles }
