import { useState, useEffect } from 'react'
import { useAudioVideo } from '.'

export default function useDevices() {
  const { devices, setDevices } = useAudioVideo()

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices
        .enumerateDevices()
        .then((newDevices) => setDevices(newDevices))

    navigator.mediaDevices.addEventListener('devicechange', getDevices)
    getDevices()

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', getDevices)
    }
  }, [])

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(
      (device) => device.kind === 'audiooutput'
    ),
  }
}

export { useDevices }
