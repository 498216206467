import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Close as CloseIcon, Publish as PublishIcon } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { post, deleteReq as axiosDelete } from '../../services/request'
import successCircleIcon from '../../assets/img/done.png'
//import { useForm } from 'react-hook-form'
import { formatFileName } from '../../helpers'

const MAX_IMAGE_FILE_SIZE = 3145728 // 3 * 1024 * 1024 bytes
const MAX_FILES_ALLOWED = 3 // We only allow 3 files to be uploaded

const useStyles = makeStyles((theme) => ({
  fileInput: {
    borderRadius: 15,
    backgroundColor: 'rgba(100,115,175,0.1)',
    border: '1px dashed rgba(100,115,175,0.4)',
    color: '#6473AF',
    fontSize: 16,
    padding: '10px 20px',
    textTransform: 'unset',
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      marginBottom: 10,
    },
  },
  fileInputContainerLabel: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  input: {
    display: 'none',
  },
  uploadAttachmentContainer: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.5rem',
      flexDirection: 'column',
    },
    textAlign: 'center',
  },
  uploadedAttachmentsContainer: {
    marginTop: '1rem',
  },
  uploadAttachmentsHeader: {
    marginTop: '1rem',
    fontSize: '0.8rem',
    color: '#6473AF',
  },
  uploadHelp: {
    maxWidth: 300,
    color: '#6473AF',
    fontSize: 12,
  },
}))

const UploadAttachments = (
  {
    /* props */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('complaintsDetail')
  const { attachments } = useStoreState((state) => state.request)
  const { setAttachments } = useStoreActions((state) => state.request)
  const [uploadError, setUploadError] = useState(false)
  const canUploadFile =
    Array.isArray(attachments) && attachments.length < MAX_FILES_ALLOWED
  const [isUploading, setIsUploading] = useState(false)

  const uploadFile = async (file) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      const result = await post('/attachments', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return {
        id: result.attachmentId,
        fileName: result.fileName,
        mimeType: result.mimeType,
        fileUrl: result.fileUrl,
      }
    } catch (err) {
      console.error('Failed to upload attachment', err)
    }
  }

  const handleFilesUpload = async (e) => {
    setUploadError(false)
    setIsUploading(true)

    try {
      const { files } = e.target

      if (!files || !files.length) {
        e.preventDefault()
        return
      }

      // If we are trying to upload more than the max allowed,
      // let the user know
      if (
        attachments &&
        attachments.length &&
        attachments.length >= MAX_FILES_ALLOWED
      ) {
        setUploadError(true)
        e.preventDefault()
        return
      }

      const uploadedFiles = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file.size > MAX_IMAGE_FILE_SIZE) {
          console.warn(
            'File size limit reached, cannot upload a file this big',
            file.size
          )
          e.preventDefault()
          setUploadError(true)
          return
        }

        const newlyUploadedFile = await uploadFile(file)
        uploadedFiles.push(newlyUploadedFile)
      }

      setAttachments([...attachments, ...uploadedFiles])
    } finally {
      setIsUploading(false)
    }
  }

  const handleFileDelete = async (e, attachment) => {
    e.preventDefault()

    try {
      const result = await axiosDelete(`/attachments/${attachment.id}`)

      if (result.succeeded === true) {
        const newAttachments = attachments.filter((a) => a.id !== attachment.id)
        setAttachments(newAttachments)
      }
    } catch (err) {
      console.error('Failed to delete attachment', err)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.uploadAttachmentContainer}>
          <input
            accept="image/*,application/pdf"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFilesUpload}
            multiple
            disabled={!canUploadFile}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="raised"
              color="primary"
              component="span"
              className={classes.fileInput}
              disabled={attachments.length >= MAX_FILES_ALLOWED}
            >
              {t('uploadTextLine1')} {t('uploadTextLine2')}
              <PublishIcon />
            </Button>
          </label>
          <span className={classes.uploadHelp}>{t('uploadHelp')}</span>
          {uploadError && <span>{t('uploadError')}</span>}
        </Grid>
      </Grid>
      {attachments && attachments.length > 0 && (
        <>
          <Typography className={classes.uploadAttachmentsHeader}>
            {t('uploadedAttachmentsHeader')}
          </Typography>

          {isUploading && (
            <Grid container>
              <Grid item xs={12}>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            className={classes.uploadedAttachmentsContainer}
            spacing={1}
          >
            {attachments.map((attachment) => (
              <Grid item key={`attachment-${attachment.id}`} md={6} xs={12}>
                <label
                  htmlFor="contained-button-file"
                  title={attachment.fileName}
                >
                  <Button
                    variant="raised"
                    color="primary"
                    component="span"
                    className={classes.fileInput}
                    onClick={(e) => handleFileDelete(e, attachment)}
                  >
                    <img src={successCircleIcon} alt="" />
                    {formatFileName(attachment.fileName)}
                    <CloseIcon className={classes.closeIcon} />
                  </Button>
                </label>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}

UploadAttachments.displayName = 'UploadAttachments'
export { UploadAttachments }
export default UploadAttachments
