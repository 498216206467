import React, { Fragment, useEffect, useState } from 'react'
import { navigate } from '@reach/router'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import {
  fetchCallData,
  calculateDuration,
} from '../../services/duration.service'
import { GradientButton, WhiteButton } from '../../components/Button'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { sendRequestFeedback } from '../../services/request.service'
import successCircle from '../../assets/img/circle.png'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const ConsultancyEnd = () => {
  const classes = useStyles()
  const {
    layout: { setNotification },
    request: { resetRequestState },
  } = useStoreActions((actions) => actions)
  const { requestId } = useStoreState((state) => state.request)
  const { incomingRequestContents } = useStoreState(
    (state) => state.incomingRequest
  )
  const { t } = useTranslation('consultacyEnd')

  const setFeedback = async () => {
    const body = {
      requestId: requestId,
      description: '',
      isSatisfied: true,
    }

    try {
      const message = await sendRequestFeedback({ body })

      resetRequestState()
      navigate('/')
      setNotification({
        isOpen: true,
        type: 'success',
        title: t('successTitle'),
      })
      return message
    } catch (error) {
      setNotification({
        isOpen: true,
        type: 'error',
        title: t('error1Title'),
        description: t<string>('error1Description'),
      })
    }
  }

  // TODO create call type
  const [callData, setCallData] = useState<any>(null)
  const [durationString, setDurationString] = useState('0:00')

  const fetchCall = async (requestId: any) => {
    try {
      const call = await fetchCallData(requestId)
      setCallData(call)
    } catch (e: any) {
      console.error(e.stack)
    }
  }

  useEffect(() => {
    fetchCall(incomingRequestContents?.id)
  }, [])

  useEffect(() => {
    const duration = calculateDuration(
      callData?.callEndedAt,
      callData?.recipientConnectedAt
    )
    setDurationString(duration)
  }, [callData])

  useEffect(() => {
    const dataLayer = (window as any).dataLayer || []
    dataLayer.push({
      event: t('datalayerPushEvent'),
      eventCategory: t('datalayerPushEventCategory'),
      eventAction: t('datalayerPushEventAction'),
      eventLabel: 'Button',
    })
  }, [requestId])

  return (
    <Fragment>
      <Grid container className={classes.consultancyEndTop}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.successfulConsultancyContainer}>
          <Grid item xs={12} className={classes.ComplaintDetailContainer}>
            <img src={successCircle} alt="consultancy-success" />
            <Typography
              className={classes.paymentTitle}
              variant="h1"
              component="h2"
            >
              {t<string>('successfulConsultancy')}
            </Typography>
            <Typography className={classes.paymentAlert} component="p">
              {durationString}
            </Typography>
            <Typography className={classes.paymentAlert} component="p">
              <strong>{`${
                incomingRequestContents?.provider?.namePrefix ?? ''
              } ${incomingRequestContents?.provider?.lastName ?? ''} ${
                incomingRequestContents?.provider?.firstName ?? ''
              }`}</strong>{' '}
              {t<string>('subheading')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.consultancyInfo}>
        <Typography className={classes.mainTitle} variant="h1" component="h2">
          {t<string>('heading')}
        </Typography>
        <div className={classes.buttonContainer}>
          <GradientButton
            className={classes.footerButtonSuccess}
            onClick={() => setFeedback()}
          >
            {t<string>('satisfied')}
          </GradientButton>
          <WhiteButton
            onClick={() => navigate('/patient/consultancy-problem')}
            className={classes.footerButtonProblem}
          >
            {t<string>('problem')}
          </WhiteButton>
        </div>
      </Grid>
    </Fragment>
  )
}

export { ConsultancyEnd }
