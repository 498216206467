import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { makeStyles, Theme } from '@material-ui/core/styles'

import successCircle from '../../assets/img/circle.png'
import alertTriangle from '../../assets/img/alert.png'

import { useStoreActions, useStoreState } from '../../store/store.hooks'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  snackBar: {
    marginRight: 90,
    borderRadius: 15,
    fontFamily: 'Nunito, Arial, sans-serif',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  success: {
    backgroundColor: 'rgba(30,40,100,0.8)',
    borderRadius: 15,
    boxShadow: '0 10px 20px 0 rgba(30,40,100,0.4)',
  },
  error: {
    backgroundColor: 'rgba(240,30,90,0.8)',
    borderRadius: 15,
    boxShadow: '0 10px 20px 0 rgba(30,40,100,0.4)',
  },
  snackBarContentsContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 300,
    padding: 10,
    maxWidth: 400,
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      maxWidth: 250,
      padding: 5,
    },
  },
  snackBarContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  snackBarImg: {
    minHeight: 40,
    minWidth: 40,
    maxHeight: 40,
    maxWidth: 40,
    marginBottom: '-8px',
    marginRight: 20,
  },
  snackBarTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  snackBarDescription: {
    fontSize: 14,
    fontWeight: 300,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  closeIcon: {
    alignItems: 'flex-start',
    opacity: '0.6',
  },
}))

const Notification = () => {
  const classes = useStyles()

  const notification = useStoreState((state) => state.layout.notification)
  const { resetNotification } = useStoreActions((actions) => actions.layout)

  const typeClassName =
    notification?.type === 'success' ? classes.success : classes.error

  if (!notification || !notification.isOpen) {
    return null
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notification?.isOpen ?? false}
        autoHideDuration={4000}
        onClose={() => resetNotification()}
        className={classes.snackBar}
      >
        <Alert
          onClose={() => resetNotification()}
          severity="success"
          className={typeClassName}
          icon={false}
          classes={{
            action: classes.closeIcon,
          }}
        >
          <div className={classes.snackBarContentsContainer}>
            {notification?.type === 'success' ? (
              <img
                className={classes.snackBarImg}
                src={successCircle}
                alt={notification?.type}
              />
            ) : (
              <img
                className={classes.snackBarImg}
                src={alertTriangle}
                alt={notification?.type}
                style={{ marginBottom: 0 }}
              />
            )}
            <div className={classes.snackBarContent}>
              <span className={classes.snackBarTitle}>
                {notification?.title}
              </span>
              <span className={classes.snackBarContent}>
                {notification?.description}
              </span>
            </div>
          </div>
        </Alert>
      </Snackbar>
    </div>
  )
}

export { Notification }
