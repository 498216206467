/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { GradientButton } from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { useStoreState } from '../../store/store.hooks'
import { get, post } from '../../services/request'
import { Gate } from './Gate'
import { Demographics } from './Demographics'

const useStyles = makeStyles((theme) => ({
  completedBox: {
    textAlign: 'center',
  },
  completedText: {
    color: '#1E2864',
    fontWeight: 'bold',
  },
  container: {
    padding: '1rem',
  },
  gateIntro: {
    fontSize: '1.1rem',
  },
  greeting: {
    fontSize: '1.875rem',
    marginBottom: '2rem',
    textAlign: 'center',
  },
  important: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '1rem 0',
  },
  importantToKnown: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  intro: {
    margin: '0 2rem 2rem',
  },
  longIntroText: {
    fontSize: '1.1rem',
    textAlign: 'justify',
  },
  page: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
  },
  pageHeader: {
    marginBottom: theme.spacing(3),
  },
  profileName: {
    fontSize: '2rem',
    color: '#1E2864',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  profileNameContainer: {
    margin: '1rem',
  },
  progress: {
    justifyContent: 'center',
    textAlign: 'center',
    margin: '2rem',
  },
  shortIntroText: {
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  surveyCard: {
    padding: '1rem 1rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  surveyCards: {
    margin: '0.5rem 2rem',
    justifyContent: 'center',
  },
  surveyDescription: {
    fontSize: '0.9rem',
    textAlign: 'justify',
  },
  surveyName: {
    color: '#1E2864',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  startButton: {
    marginTop: '2rem',
  },
}))

const CorporatePsychologicalDiagnostics = ({}) => {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation('survey')
  const {
    profile: { profileContents },
  } = useStoreState((state) => state)
  const { relativeNavigate } = useRelativeNavigate()

  const [hasValidated, setHasValidated] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [demographics, setDemographics] = useState(null)
  const [surveys, setSurveys] = useState([])

  const fetchDemographics = async () => {
    try {
      setLoading(true)
      const corporateDiagnostics = await get(
        `/surveys/corporate-diagnostics?lng=${language}`
      )

      setDemographics(corporateDiagnostics.demographics)
      setSurveys(corporateDiagnostics.surveys)
    } catch (err) {
      console.error('Failed to fetch demographics and/or surveys', err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    // Check if we already have demographics saved
    fetchDemographics()
  }, [])

  useEffect(() => {
    // If we have the demographics info saved, let's skip the gate and the demographics
    // sections
    if (demographics && !!demographics.voucher) {
      setHasValidated(true)
    }
  }, [demographics])

  const handleValidationCompleted = async (validationResult) => {
    // If the coupon code comes back as valid, save it in the demographics section
    if (validationResult.isValid) {
      await post(
        `/surveys/corporate-diagnostics/demographics?lng=${language}`,
        {
          voucher: validationResult.voucher,
        }
      )
    }

    setHasValidated(validationResult.isValid)
  }

  const handleDemographicsSaved = async () => {
    // Reload the demographics and update the UI accordingly
    fetchDemographics()
  }

  const handleStartSurvey = (wellKnownKey) => {
    relativeNavigate(`/surveys/${wellKnownKey}/questions`)
  }

  const handleNavigateToEvaluation = () => {
    relativeNavigate(`/surveys/corporate-diagnostics/evaluation`)
  }

  const hasDemographics = !!(
    demographics &&
    demographics.voucher &&
    demographics.department &&
    demographics.gender &&
    demographics.age &&
    demographics.location &&
    demographics.education
  )

  const hasSurveys = surveys && surveys.length > 0
  const showShortIntro = !loading && !hasValidated
  const showLongIntro = !loading && hasValidated && !hasDemographics
  const allSurveysCompleted = hasSurveys && surveys.every((s) => s.completed)
  const showName = !loading && hasValidated && !hasDemographics

  return (
    <>
      <Grid container className={classes.page}>
        <NavigateBack />
        <Grid item xs={12} className={classes.pageHeader}>
          <Heading>{t('corporatePsychoDiagWelcome')}</Heading>
        </Grid>

        {showName && (
          <Grid item xs={12} className={classes.profileNameContainer}>
            <Typography className={classes.profileName}>
              {t('greeting')} {profileContents && profileContents.name}!
            </Typography>
          </Grid>
        )}

        {showShortIntro && (
          <Grid item xs={12} className={classes.intro}>
            <Typography className={classes.shortIntroText}>
              {t('corporatePsychoDiagShortIntro')}
            </Typography>
          </Grid>
        )}

        {showLongIntro && (
          <Grid item xs={12} className={classes.intro}>
            <Typography className={classes.longIntroText}>
              {t('corporatePsychoDiagLongIntro')}
            </Typography>
          </Grid>
        )}

        {loading && (
          <Grid container className={classes.progress}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        {!loading && !hasValidated && (
          <Grid item xs={12} className={classes.container}>
            <Gate onValidationCompleted={handleValidationCompleted} />
          </Grid>
        )}

        {!loading && hasValidated && !hasDemographics && (
          <Grid item xs={12} className={classes.container}>
            <Demographics onSave={handleDemographicsSaved} />
          </Grid>
        )}

        {!loading && hasValidated && hasDemographics && hasSurveys && (
          <Grid
            item
            container
            xs={12}
            className={classes.surveyCards}
            spacing={3}
          >
            {surveys.map((survey) => (
              <Grid item md={4} xs={12} key={survey.wellKnownKey}>
                <Paper elevation={3} className={classes.surveyCard}>
                  <Typography className={classes.surveyName} gutterBottom>
                    {survey.title}
                  </Typography>
                  <Typography
                    className={classes.surveyDescription}
                    gutterBottom
                  >
                    {survey.description}
                  </Typography>

                  {survey.completed && (
                    <Box className={classes.completedBox}>
                      <CheckCircleOutlineIcon />
                      <Typography className={classes.completedText}>
                        {t('corporatePsychoDiagThankYou')}
                      </Typography>
                    </Box>
                  )}
                  {!survey.completed && (
                    <GradientButton
                      className={classes.startButton}
                      onClick={() => handleStartSurvey(survey.wellKnownKey)}
                    >
                      {t('corporatePsychoDiagStartSurveyButton')}
                    </GradientButton>
                  )}
                </Paper>
              </Grid>
            ))}
            <Grid container>
              <Grid item xs>
                <Typography className={classes.important}>
                  {t('corporatePsychoDiagSurveysWarning')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Typography className={classes.importantToKnown}>
                  {t('corporatePsychoDiagSurveysImportantToKnow')}
                </Typography>
                <ul>
                  <li>{t('corporatePsychoDiagSurveysImportantToKnowLine0')}</li>
                  <li>{t('corporatePsychoDiagSurveysImportantToKnowLine1')}</li>
                  <li>{t('corporatePsychoDiagSurveysImportantToKnowLine2')}</li>
                  <li>{t('corporatePsychoDiagSurveysImportantToKnowLine3')}</li>
                </ul>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs>
                <Typography className={classes.important}>
                  {t('corporatePsychoDiagPrivacyInfo')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={4}>
                <GradientButton
                  onClick={handleNavigateToEvaluation}
                  disabled={!allSurveysCompleted}
                >
                  {t('corporatePsychoDiagSurveysResults')}
                </GradientButton>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs>
                <Typography className={classes.important}>
                  {t('corporatePsychoDiagSurveysResultsPlaceholder')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

CorporatePsychologicalDiagnostics.displayName =
  'CorporatePsychologicalDiagnostics'

export default CorporatePsychologicalDiagnostics
export { CorporatePsychologicalDiagnostics }
