import React, { useEffect } from 'react'
import { navigate } from '@reach/router'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { useTranslation } from 'react-i18next'

import { Container, Avatar, Typography, Grid } from '@material-ui/core'
import EditOutlined from '@material-ui/icons/EditOutlined'
import { makeStyles } from '@material-ui/core/styles'

import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { BillingAdderssBlock } from '../Payment/components/BillingAddressBlock'
import { AssociatedAccount, DeleteProfile } from '../../components'

import ProfileIcon from '../../assets/img/profile.svg'

import { deleteReq, post } from '../../services/request'
import { normalizeLanguage } from '../../util'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
  headerContainer: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  editButton: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#1E2864',
    fontFamily: 'Nunito, Arial, sans-serif',

    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  editIcon: {
    padding: '4px',
    marginBottom: '-6px',
  },
  avatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
    marginBottom: '10px',
    background: '#FFFFFF',
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    '@media (max-height: 680px)': {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    border: '1px solid #E0E3EF',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 25,
  },
  userName: {
    fontSize: '20px',
    fontWeight: 300,
    margin: '5px 0',
  },
  userEmail: {
    fontWeight: 300,
  },
  avatarFallback: {
    color: '#C1C7DF',
    backgroundColor: '#FFFFFF',
    height: '56px',
    width: '56px',
  },
  paymentContainer: {
    paddingBottom: 20,
  },
  deleteAccountContainer: {
    paddingBottom: '2rem',
  },
}))

const Profile = () => {
  const classes = useStyles()
  const { i18n, t } = useTranslation('profile')

  const { cards, profileContents } = useStoreState((state) => state.profile)
  const fetchProfile = useStoreActions(
    (actions) => actions.profile.fetchProfile
  )
  const fetchCard = useStoreActions((actions) => actions.profile.fetchCard)
  const layoutSetIsLoading = useStoreActions(
    (actions) => actions.layout.setIsLoading
  )

  const creditCards = cards?.map((card) => {
    return {
      id: card.referenceTransactionId,
      lastFourDigits: card.paymentDeviceNumber,
      cardName: card.cardType,
      referenceTransactionId: card.referenceTransactionId,
    }
  })

  const billingAddresses = profileContents?.addresses?.map((address) => {
    return {
      id: address.id,
      name: address.userGivenName,
      fullAddress: `${address.postalCode} ${address.city} ${address.streetAddress}`,
    }
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)

    if (!searchParams.has('prevCcCount')) {
      return
    }

    const prevCcCount = Number(searchParams.get('prevCcCount'))

    const shouldRefetch = cards.length <= prevCcCount

    if (shouldRefetch) {
      layoutSetIsLoading(true)
      const timeout = setTimeout(() => fetchCard({}), 2000)
      return () => {
        clearTimeout(timeout)
      }
    } else {
      layoutSetIsLoading(false)
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  })

  const createNewCard = async () => {
    const { url } = await post<{ url: string }>(
      `/transaction/card?returnTo=profile&prevCcCount=${
        cards.length
      }&lng=${normalizeLanguage(i18n.language)}`
    )
    window.location.href = url
  }

  const deleteBillingAddress = async (billingAddressId: number) => {
    await deleteReq(`/user/address/${billingAddressId}`)
    fetchProfile({})
  }

  const handleDeleteCard = async (card: any) => {
    await deleteReq(`/transaction/card/`, {
      referenceTransactionId: card.referenceTransactionId,
    })
    fetchProfile({})
    window.location.reload()
  }

  const userName =
    (profileContents.firstName
      ? `${profileContents.lastName} ${profileContents.firstName}`
      : profileContents?.name) ?? t<string>('defaultUserName')

  return (
    <Container className={classes.container}>
      <NavigateBack />
      <Typography
        className={classes.editButton}
        onClick={() => navigate('profile-edit')}
      >
        {t<string>('editButton')}
        <EditOutlined className={classes.editIcon} />
      </Typography>
      <Grid item xs={12} className={classes.headerContainer}>
        <Heading>{t<string>('heading')}</Heading>
      </Grid>
      <div className={classes.avatarContainer}>
        <Avatar
          alt="username"
          src={profileContents?.avatarUrl}
          className={classes.avatar}
        >
          <img
            src={ProfileIcon}
            className={classes.avatarFallback}
            alt="avatar-fallback"
          />
        </Avatar>
        <Typography className={classes.userName} component="h3">
          <strong>{userName}</strong>
        </Typography>
        <Typography className={classes.userEmail} component="h4">
          {profileContents?.email}
        </Typography>
      </div>
      <Container
        maxWidth="xs"
        component="main"
        className={classes.paymentContainer}
      >
        <BillingAdderssBlock
          deleteBillingAddress={deleteBillingAddress}
          billingAddressList={billingAddresses}
          isProfilePageVersion={true}
        />
      </Container>

      <Container maxWidth="xs" className={classes.paymentContainer}>
        <AssociatedAccount />
      </Container>

      <Container maxWidth="xs" className={classes.deleteAccountContainer}>
        <DeleteProfile />
      </Container>
    </Container>
  )
}

export { Profile }
