import { action, thunk } from 'easy-peasy'
import { fetchLanguageUrls } from '../../services/layout.service'
import { LayoutModel } from './layout.interface'

const initialNotification = {
  isOpen: false,
  type: '',
  title: '',
  description: '',
}

const layoutModel: LayoutModel = {
  languageUrls: undefined,
  isMobile: window.innerWidth <= 768,
  isSideMenuOpen: false,
  isCancelPaymentModalOpen: false,
  helperModal: {
    isOpen: false,
    type: '',
  },
  notification: {
    isOpen: false,
    type: '',
    title: '',
    description: '',
  },
  isLoading: false,
  setLanguageUrls: action((state, payload) => {
    state.languageUrls = payload
  }),
  fetchLanguageUrls: thunk(async (actions, payload, helpers) => {
    const languages = await fetchLanguageUrls()
    actions.setLanguageUrls(languages)
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsMobile: action((state, payload) => {
    state.isMobile = payload
  }),
  setIsSideMenuOpen: action((state, payload) => {
    state.isSideMenuOpen = payload
  }),
  setIsCancelPaymentModalOpen: action((state, payload) => {
    state.isCancelPaymentModalOpen = payload
  }),
  setHelperModal: action((state, payload) => {
    state.helperModal = payload
  }),
  setNotification: action((state, payload) => {
    state.notification = payload
  }),
  resetNotification: action((state) => {
    state.notification = initialNotification
  }),
  resetLayoutState: action((state) => {
    state.isSideMenuOpen = false
    state.isCancelPaymentModalOpen = false
    state.isLoading = false
    state.notification = initialNotification
    state.helperModal = {
      isOpen: false,
      type: '',
    }
  }),
}

export { layoutModel }
