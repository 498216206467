import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'

const useStyles = makeStyles(() => ({
  whiteButton: {
    backgroundColor: '#fff',
    width: '200px',
    borderRadius: '10px',
    textAlign: 'center',
    color: '#6473AF',
    textTransform: 'unset',
    fontSize: '16px',
    cursor: 'pointer',

    ' &:hover': {
      backgroundColor: '#FFFFFF',
      color: '#6473AF',
    },
  },
}))

const WhiteButton = (props: ButtonProps) => {
  const { children, className } = props
  const classes = useStyles()

  const buttonClasses = className
    ? [classes.whiteButton, className].join(' ')
    : classes.whiteButton

  return (
    <Button {...props} className={buttonClasses}>
      {children}
    </Button>
  )
}

export { WhiteButton }
