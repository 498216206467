export enum PushTypes {
  INCOMING_CALL = 'incomingCall',
  CALLER_CANCELED = 'callerCanceled',
  RECIPIENT_CANCELED = 'recipientCanceled',
  REQUESTER_FOUND = 'requesterFound',
  PROVIDER_FOUND = 'providerFound',
  PROVIDER_FEEDBACK = 'providerFeedback',
  NO_PROVIDER_FOUND = 'noProviderFound',
  REQUEST_TIMEOUT = 'requestTimeout',
  END_CALL = 'endCall',
  INVALIDATE_MATCH = 'invalidateMatch',
  MANUAL_END_CALL = 'manualEndCall',
}
