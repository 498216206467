/*
    Copyright © DokiApp, 2022
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, Grid, Typography } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { FormInput } from '../../components/FormInput'
import { makeStyles } from '@material-ui/core/styles'
import { GradientButton } from '../../components/Button/GradientButton'
import { Heading } from '../../components/Text'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { post } from '../../services/request'
import { navigate } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  button: {},
  container: {
    backgroundColor: '#fff',
    borderRadius: '15px',
    padding: theme.spacing(2),
  },
  explanation: {
    textAlign: 'center',
  },
  explanationText: {
    fontSize: 20,
    margin: 20,
  },
  input: {
    marginBottom: '2rem',
  },
  root: {
    margin: '2rem 25%',
  },
}))

const ResetPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation('resetPassword')
  const [error, setError] = useState(null)
  const [saving, setSaving] = useState(false)
  const [completed, setCompleted] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')

  const {
    watch,
    formState: { errors },
    register,
    clearErrors,
    handleSubmit,
  } = useForm()

  const onSubmit = async (values) => {
    setSaving(true)
    const { newPassword, confirmPassword } = values
    try {
      const response = await post('/auth/resetPassword', {
        newPassword,
        confirmPassword,
        token,
      })

      console.debug('Password reset', { response })
      setCompleted(true)
    } catch (err) {
      console.error('Failed to reset password', err)
      setError(t('error'))
    } finally {
      setSaving(false)
    }
  }

  const handleNavigateHome = () => {
    navigate('/email-login')
  }

  const { ref: newPasswordInputRef, ...newPasswordInputProps } = register(
    'newPassword',
    {
      required: t('missingField'),
    }
  )
  const { ref: confirmPasswordInputRef, ...confirmPasswordInputProps } =
    register('confirmPassword', {
      required: t('missingField'),
      validate: (value) =>
        value === watch('newPassword') || t('passwordMismatch'),
    })

  return (
    <>
      <Container className={classes.container}>
        <Grid className={classes.root}>
          <Heading>{t('header')}</Heading>
          <div className={classes.explanation}>
            <Typography component="h3" className={classes.explanationText}>
              {t('explanation')}
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              type="password"
              defaultValue={''}
              inputName="newPassword"
              error={!!errors.newPassword}
              value={watch('newPassword')}
              label={t('passwordBoxLabel')}
              clearError={() => {
                clearErrors(['newPassword', 'password'])
              }}
              placeholder={t('passwordBoxPlaceholder')}
              errorMessage={errors?.newPassword?.message ?? ''}
              className={classes.input}
              inputRef={newPasswordInputRef}
              {...newPasswordInputProps}
            />
            <FormInput
              type="password"
              defaultValue={''}
              inputName="confirmPassword"
              error={!!errors.confirmPassword}
              value={watch('confirmPassword')}
              label={t('confirmPasswordBoxLabel')}
              clearError={() => {
                clearErrors(['confirmPassword', 'password'])
              }}
              placeholder={t('confirmPasswordBoxPlaceholder')}
              errorMessage={errors?.confirmPassword?.message ?? ''}
              className={classes.input}
              inputRef={confirmPasswordInputRef}
              {...confirmPasswordInputProps}
            />

            {!saving && error && (
              <Alert className={classes.alert} severity="error">
                {error}
              </Alert>
            )}

            {!saving && !error && completed && (
              <Alert className={classes.alert} severity="success">
                {t('successfulResetAlert')}
              </Alert>
            )}

            {!completed && (
              <GradientButton
                type={'submit'}
                className={classes.button}
                disabled={saving}
              >
                {t('submitButton')}
              </GradientButton>
            )}

            {completed && (
              <GradientButton
                type={'button'}
                className={classes.button}
                onClick={handleNavigateHome}
              >
                {t('navigateButton')}
              </GradientButton>
            )}
          </form>
        </Grid>
      </Container>
    </>
  )
}

ResetPassword.displayName = 'ResetPassword'

ResetPassword.propTypes = {}

ResetPassword.defaultProps = {}

export default ResetPassword
export { ResetPassword }
