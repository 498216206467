import React, {
  Dispatch,
  ChangeEvent,
  SetStateAction,
  FunctionComponent,
  useEffect,
} from 'react'
import { Controller, Control, useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from '../../store/store.hooks'
import { Container, Checkbox, Typography, Link, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import { SeparatorBlock } from './components/SeparatorBlock'
import { CreditCardBlock } from './components/CreditCardBlock'
import { OneOffPaymentBlock } from './components/OneOffPaymentBlock'
import { BillingAdderssBlock } from './components/BillingAddressBlock'
import { FormInput } from '../../components/FormInput'
import {
  CouponValidationResponse,
  CouponGetDiscountedPriceResponse,
} from '../../types/types'
import { CouponValidationErrorMessages } from '../../types/enums'
import { deleteReq, post } from '../../services/request'
import { normalizeLanguage } from '../../util'
import { EXTERNAL_FILES, FEATURES, SPECIALIZATIONS } from '../../constants'
import { validateLocales } from '../../helpers/languageFormatter'

type FormData = {
  tosCheckedPayment: boolean
}

type Props = {
  control?: Control<FormData, any>
  creditCardList?: { id: string; lastFourDigits: string; cardName: string }[]
  billingAddressList?: { id: number; name: string; fullAddress: string }[]
  selectedCardIndex: number
  setSelectedCardIndex: Dispatch<SetStateAction<number>>
  selectedAddressIndex: number
  setSelectedAddressIndex: Dispatch<SetStateAction<number>>
  editBillingAddress: (addressIndex: number) => void
  editCardData: (cardIndex: number) => void
  disableBillingAddress?: boolean
  disableCreditCards?: boolean
  onVoucherValidating?: (isVoucherValidating: boolean) => void
}

const useStyles = makeStyles(() => ({
  iconContainer: {
    width: 45,
    height: 45,
    borderWidth: 2,
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: 'grey',
  },
  tickIcon: {
    fontSize: 24,
    color: '#3CAAF0',
    marginTop: 8,
  },
  success: {
    border: '1px solid rgb(151, 211, 68)',
  },
}))

const disableOneClickPayment = true

const PaymentForm: FunctionComponent<Props> = (props) => {
  const { i18n, t } = useTranslation('paymentForm')
  const classes = useStyles()
  const { setTermsAndConditionsAccepted } = useStoreActions(
    (actions) => actions.request
  )
  const termsAndConditionsAccepted = useStoreState(
    (state) => state.request.termsAndConditionsAccepted
  )
  const { activeVoucher } = useStoreState((state) => state.subscription)

  const {
    formState: { errors },
    clearErrors,
    setError,
    setValue: setInputValue,
  } = useForm()

  const language = validateLocales(i18n.language)
  const linkedFiles = (EXTERNAL_FILES as any)[language]

  const {
    control,
    creditCardList,
    billingAddressList,
    selectedCardIndex,
    setSelectedCardIndex,
    selectedAddressIndex,
    setSelectedAddressIndex,
    editBillingAddress,
    editCardData,
    disableBillingAddress,
    disableCreditCards,
    onVoucherValidating,
  } = props

  const createNewCard = async () => {
    const { url } = await post<{ url: string }>(
      `/transaction/card?returnTo=payment&prevCcCount=${
        creditCardList?.length || 0
      }&lng=${normalizeLanguage(i18n.language)}`
    )
    window.location.href = url
  }

  const selectActiveCard = (event: ChangeEvent<{}>, cardIndex: number) => {
    setSelectedCardIndex(cardIndex)
  }

  const selectActiveBillingAddress = (
    event: ChangeEvent<{}>,
    cardIndex: number
  ) => {
    setSelectedAddressIndex(cardIndex)
  }

  /* store state */
  const payedAsOneOff = useStoreState((state) => state.request.payedAsOneOff)
  const providerTypeId = useStoreState((state) => state.request.providerTypeId)
  const couponCode = useStoreState((state) => state.coupon.couponCode)
  const specialization = useStoreState((state) => state.request.specialization)
  const {
    subscription: { featuresBySku },
  } = useStoreState((state) => state)

  const feature =
    specialization === SPECIALIZATIONS.PEDIATRICS
      ? FEATURES.ON_DEMAND_PEDIATRICIAN
      : language === 'hu'
      ? FEATURES.ON_DEMAND_MEDICAL_DOCTOR
      : FEATURES.ON_DEMAND_MEDICAL_DOCTOR_INTL
  const featurePrice = feature && featuresBySku && featuresBySku[feature]

  /* store actions */
  const setPayedAsOneOff = useStoreActions(
    (actions) => actions.request.setPayedAsOneOff
  )
  const setCouponCode = useStoreActions(
    (actions) => actions.coupon.setCouponCode
  )
  const setDiscountedPrice = useStoreActions(
    (actions) => actions.coupon.setDiscountedPrice
  )
  const { fetchProfile } = useStoreActions((actions) => actions.profile)

  const setVoucherValidating = (isVoucherValidating: boolean) => {
    if (onVoucherValidating instanceof Function) {
      onVoucherValidating(isVoucherValidating)
    }
  }

  const makeValidateRequest = async (couponCode: string) => {
    setVoucherValidating(true)

    try {
      const response: CouponValidationResponse = await post(
        '/coupon/validate',
        {
          couponCode,
          providerTypeId,
        }
      )
      if (!response.isValid) {
        let message = ''

        if (response.message === CouponValidationErrorMessages.CouponNotFound) {
          message = t<string>('invalidCoupon')
        }

        if (response.message === CouponValidationErrorMessages.CouponExpired) {
          message = t<string>('couponExpired')
        }

        if (response.message === CouponValidationErrorMessages.CouponSoldOut) {
          message = t<string>('couponsUsedUp')
        }

        if (
          response.message ===
          CouponValidationErrorMessages.LimitedToMedicalConsultation
        ) {
          message = t<string>('couponLimitedToMedicalConsultation')
        }

        if (
          response.message ===
          CouponValidationErrorMessages.LimitedToTherapistConsultation
        ) {
          message = t<string>('couponLimitedToTherapistConsultation')
        }

        if (
          response.message === CouponValidationErrorMessages.VoucherNotYetValid
        ) {
          message = t<string>('voucherNotYetValid')
        }

        setCouponCode(undefined)
        setDiscountedPrice(undefined)
        if (couponCode.length === 0) {
          clearErrors()
        } else {
          setError('coupon', { message })
        }
      } else {
        const originalPrice = featurePrice?.price

        clearErrors()
        const couponDiscountResponse: CouponGetDiscountedPriceResponse =
          await post('/coupon/get-discounted-price', {
            originalPrice,
            couponCode,
          })
        setCouponCode(couponCode)
        setDiscountedPrice(couponDiscountResponse.discountedPrice)
      }
    } finally {
      setVoucherValidating(false)
    }
  }

  const validateCoupon = _.debounce(makeValidateRequest, 500)

  const deleteCard = async (card: any) => {
    await deleteReq(`/transaction/card`, {
      referenceTransactionId: card.referenceTransactionId,
    })
      .then(() => {
        fetchProfile({})
        window.location.reload()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (disableOneClickPayment) {
      setPayedAsOneOff(true)
    }
  }, [disableOneClickPayment])

  useEffect(() => {
    if (activeVoucher && activeVoucher.code) {
      setInputValue('coupon', activeVoucher.code)
      validateCoupon(activeVoucher.code)
    }
  }, [activeVoucher])

  return (
    <Container component="main" maxWidth="xs">
      {!disableOneClickPayment && (
        <>
          <CreditCardBlock
            editCardData={editCardData}
            creditCardList={creditCardList}
            selectActiveCard={selectActiveCard}
            selectedCardIndex={selectedCardIndex}
            createNewCard={createNewCard}
            onDeleteCard={deleteCard}
            isProfilePageVersion={false}
            disabled={disableCreditCards || payedAsOneOff}
          />
          <SeparatorBlock />
        </>
      )}
      <OneOffPaymentBlock
        activated={payedAsOneOff}
        setIsOneOffPayment={setPayedAsOneOff}
        disabled={disableCreditCards}
      />
      <BillingAdderssBlock
        selectedAddressIndex={selectedAddressIndex}
        billingAddressList={billingAddressList}
        selectActiveBillingAddress={selectActiveBillingAddress}
        editBillingAddress={editBillingAddress}
        isProfilePageVersion={false}
        disabled={disableBillingAddress}
      />
      <Box mb={4}>
        <FormInput
          inputName="coupon"
          error={!!errors.coupon}
          defaultValue={activeVoucher && activeVoucher.code}
          label={t<string>('couponLabel')}
          clearError={() => clearErrors('coupon')}
          placeholder={t<string>('couponPlaceholder')}
          errorMessage={String(errors?.coupon?.types?.message || '')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            validateCoupon(event.target.value)
          }}
          disableClearErrorOnBlurAndFocus
          outlineClassName={couponCode ? classes.success : undefined}
        />
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
        <Controller
          render={({}) => (
            <Checkbox
              icon={<div className={classes.iconContainer} />}
              checkedIcon={
                <div className={classes.iconContainer}>
                  <Check className={classes.tickIcon} />
                </div>
              }
              onChange={(e: any) => {
                setTermsAndConditionsAccepted(e.target.checked)
              }}
              checked={termsAndConditionsAccepted}
            />
          )}
          control={control}
          name="tosCheckedPayment"
        />
        <Typography align="left">
          {t<string>('textPart1')}
          <Link
            href={linkedFiles.TERMS_AND_CONDITIONS}
            target="_blank"
            color="error"
            rel="noopener noreferrer"
          >
            {t<string>('tosLink')}
          </Link>
          {t<string>('textPart2')}
          <Link
            href={linkedFiles.APP_PRIVACY_POLICY}
            target="_blank"
            color="error"
            rel="noopener noreferrer"
          >
            {t<string>('privacyLink')}
          </Link>
          {t<string>('textPart3')}
        </Typography>
      </Box>
    </Container>
  )
}

export { PaymentForm }
