import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'

import successCircle from '../../assets/img/circle.png'
import { Tips } from '../../components/Tips'
import { CancelPaymentModal } from '../../components/Modals/'
import { useStoreActions, useStoreState } from '../../store/store.hooks'
import { useStyles } from './styles'
import { playPaymentSoundEffect } from '../../lib/soundEffects'
// TODO: NoSleep
//import NoSleep from 'nosleep.js'
import { updateRequestStatus } from '../../services/request.service'

export const SuccessfulPayment = () => {
  const classes = useStyles()
  const { t } = useTranslation('successfulPayment')
  // TODO: NoSleep
  //const noSleep = new NoSleep()

  const { setIsCancelPaymentModalOpen } = useStoreActions(
    (actions) => actions.layout
  )
  const { setRequestId } = useStoreActions((actions) => actions.request)

  const { providerTypeId, requestId } = useStoreState((state) => state.request)
  const {
    incomingRequest: { incomingRequestContents },
  } = useStoreState((state) => state)

  useEffect(() => {
    console.log(
      '[REQUEST] status =',
      incomingRequestContents?.status,
      'id =',
      requestId
    )

    playPaymentSoundEffect()
    if (!requestId) {
      const lastActiveRequestId = parseInt(
        localStorage.getItem('requestId') ?? '0'
      )
      setRequestId(lastActiveRequestId)
      localStorage.removeItem('requestId')
    }
  }, [])

  // TODO: NoSleep
  // const enableNoSleep = () => {
  //   document.removeEventListener('click', enableNoSleep, false)
  //   document.removeEventListener('scroll', enableNoSleep, false)
  //   document.removeEventListener('touchmove', enableNoSleep, false)

  //   try {
  //     noSleep.enable()
  //   } catch (err) {
  //     console.warn(
  //       'Failed to enable noSleep, probably due to missing interaction with site',
  //       err
  //     )
  //   }
  // }

  const handleVisibilityChange = async () => {
    await updateRequestStatus({
      requestId,
      body: {
        visible: document.visibilityState === 'visible' ? true : false,
      },
    })
  }

  // TODO: NoSleep
  // useEffect(() => {
  //   document.addEventListener('click', enableNoSleep, false)
  //   document.addEventListener('scroll', enableNoSleep, false)
  //   document.addEventListener('touchmove', enableNoSleep, false)

  //   return () => {
  //     try {
  //       noSleep.disable()
  //     } catch (err) {
  //       console.warn('Failed to disable noSleep', err)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false)

    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleVisibilityChange,
        false
      )
    }
  }, [])

  return (
    <Fragment>
      <Grid container className={classes.successfulPaymentTop}>
        <div className={classes.bgLayover}></div>
        <Grid container className={classes.successfulPaymentContainer}>
          <Grid container className={classes.navigateBackContainer}>
            <div
              onClick={() => setIsCancelPaymentModalOpen(true)}
              className={classes.navigateBackLink}
            >
              {t<string>('cancel')}
              <CloseIcon className={classes.navigateBackLinkIcon} />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.ComplaintDetailContainer}>
            <img src={successCircle} alt="consultancy-success" />
            <Typography
              className={classes.paymentTitle}
              variant="h1"
              component="h2"
            >
              {t<string>('successfulPayment')}
            </Typography>

            <Typography className={classes.paymentAlert} component="p">
              {providerTypeId === 2
                ? t<string>('paymentAlertPsycho')
                : t<string>('paymentAlertDoctor')}
            </Typography>

            <CircularProgress className={classes.loading} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.consultancyInfo}>
        <Typography className={classes.mainTitle} variant="h1" component="h2">
          {t<string>('heading')}
        </Typography>
        <Typography className={classes.subheading} component="p">
          {t<string>('subheading')}
        </Typography>
        <Tips />
      </Grid>
      <CancelPaymentModal />
    </Fragment>
  )
}
