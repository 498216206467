import { action } from 'easy-peasy'
import { ComplaintsModel } from './complaints.interface'

const complaintsModel: ComplaintsModel = {
  consultancyType: '',
  selectedComplaints: [],
  complaintDetail: '',
  setConsultancyType: action((state, payload) => {
    state.consultancyType = payload
  }),
  setSelectedComplaints: action((state, payload) => {
    state.selectedComplaints = payload
  }),
  setComplaintDetail: action((state, payload) => {
    state.complaintDetail = payload
  }),
  resetComplaintState: action((state) => {
    state.consultancyType = ''
    state.selectedComplaints = []
    state.complaintDetail = ''
  }),
}

export { complaintsModel }
