import React from 'react'
import { ActionCreator } from 'easy-peasy'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import { WhiteButton } from '../../../components/Button'
import { CheckboxSelector } from '../../../components/CheckboxSelector'

type Props = {
  activated?: boolean
  setIsOneOffPayment: ActionCreator<boolean>
  disabled?: boolean
}

const useStyles = makeStyles(() => ({
  dataContainer: {
    marginBottom: '40px',
  },
  button: {
    width: '100%',
    justifyContent: 'start',
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF;',
    position: 'relative',
    marginBottom: 10,
  },
}))

const OneOffPaymentBlock = (props: Props) => {
  const { t } = useTranslation('oneOffPaymentBlock')
  const classes = useStyles()

  const { activated, disabled, setIsOneOffPayment } = props
  const checkedState = disabled ? false : activated

  return (
    <div className={classes.dataContainer}>
      <WhiteButton
        disabled={disabled}
        onClick={() => {
          setIsOneOffPayment(!activated)
        }}
        className={classes.button}
      >
        <CheckboxSelector checkedState={checkedState} />
        {t<string>('buttonText')}
      </WhiteButton>
    </div>
  )
}

export { OneOffPaymentBlock }
