import React from 'react'
import {
  Room,
  AudioTrack as IAudioTrack,
  LocalVideoTrack,
  LocalAudioTrack,
} from 'twilio-video'
import useParticipants from '../../twilio-doki/useParticipants'
import usePublications from '../../twilio-doki/usePublications'
import useTrack from '../../twilio-doki/useTrack'
import _ from 'lodash'
import { IVideoTrack } from '../../twilio-doki/types'
import { VideoCall } from '.'

export const DokiRoom = ({
  room,
  localTracks,
  getLocalVideoTrack,
}: {
  room: Room
  localTracks: (LocalAudioTrack | LocalVideoTrack)[]
  getLocalVideoTrack: () => Promise<LocalVideoTrack>
}) => {
  const participants = useParticipants(room)
  const publications = usePublications(participants[0])
  const videoPublication = _.find(publications, { kind: 'video' })
  const audioPublication = _.find(publications, { kind: 'audio' })
  const videoTrack = useTrack(videoPublication)
  const audioTrack = useTrack(audioPublication)

  return (
    <VideoCall
      videoTrack={videoTrack as IVideoTrack}
      audioTrack={audioTrack as IAudioTrack}
      localTracks={localTracks}
      getLocalVideoTrack={getLocalVideoTrack}
      room={room}
    />
  )
}
