/*
    Copyright © DokiApp, 2023
*/

import React, { useEffect, useRef, useState } from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { GradientButton } from '../../components/Button'
import { post } from '../../services/request'

const useStyles = makeStyles((theme) => ({
  demographicsFillOnceContainer: {
    margin: '1rem',
    padding: '1rem',
  },
  demographicsFillOnceText: {
    fontWeight: 'bold',
    color: '#1E2864',
    textAlign: 'center',
  },
  demographicsPage: {
    borderRadius: '1rem',
    boxShadow: '0 10px 20px 0 rgba(100, 115, 175, 0.4)',
    padding: '1rem 2rem 2rem 1rem',
    justifyContent: 'center',
  },
  demographicsRow: {
    marginBottom: '1rem',
  },
  errorContainer: {
    margin: '1rem 1rem 2rem 1rem',
  },
  errorText: {
    fontWeight: 'bold',
    paddign: '1rem',
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  infoText: {
    color: '#1E2864',
    textAlign: 'justify',
  },
  privacyInfoText: {
    fontWeight: 'bold',
    padding: '1rem',
    textAlign: 'center',
  },
  saveDemographicsButton: {
    maxWidth: '50%',
  },
}))

const Demographics = ({ onSave }) => {
  const classes = useStyles()
  const { t } = useTranslation('survey')
  const [error, setError] = useState(false)

  const {
    watch,
    formState: { errors, isValid },
    register,
    clearErrors,
    handleSubmit,
  } = useForm()
  const formRef = useRef(null)

  const { ref: departmentInputRef, ...departmentInputProps } =
    register('department')
  const { ref: ageInputRef, ...ageInputProps } = register('age')
  const { ref: genderInputRef, ...genderInputProps } = register('gender')
  const { ref: locationInputRef, ...locationInputProps } = register('location')
  const { ref: educationInputRef, ...educationInputProps } =
    register('education')

  const onSubmit = handleSubmit(async (values) => {
    setError(false)
    if (isValid) {
      const allValuesSet =
        !!values.department &&
        !!values.age &&
        !!values.gender &&
        !!values.location &&
        !!values.education

      if (!allValuesSet) {
        setError(true)
        if (formRef && formRef.current) {
          formRef.current.scrollIntoView()
        }
        return
      }

      const response = await post(
        '/surveys/corporate-diagnostics/demographics',
        values
      )
      if (response && response.success) {
        if (onSave instanceof Function) {
          onSave()
        }
      }
    }
  })

  const menuItems = {
    educations: [
      {
        value: 'Unfinished Primary School',
        label: t('corporatePsychoDiagEducation0'),
      },
      {
        value: 'Primary School',
        label: t('corporatePsychoDiagEducation1'),
      },
      {
        value: 'Trade School',
        label: t('corporatePsychoDiagEducation2'),
      },
      {
        value: 'High Scool',
        label: t('corporatePsychoDiagEducation3'),
      },
      {
        value: 'Vocational School',
        label: t('corporatePsychoDiagEducation4'),
      },
      {
        value: 'Higher Degree',
        label: t('corporatePsychoDiagEducation5'),
      },
    ],

    departments: [
      {
        value: 'Finance',
        label: t('corporatePsychoDiagDepartmentFinance'),
      },
      {
        value: 'HR',
        label: t('corporatePsychoDiagDepartmentHR'),
      },
      {
        value: 'Management',
        label: t('corporatePsychoDiagDepartmentManagement'),
      },
      {
        value: 'Manufacturing',
        label: t('corporatePsychoDiagDepartmentManufacturing'),
      },
      {
        value: 'Marketing',
        label: t('corporatePsychoDiagDepartmentMarketing'),
      },
      {
        value: 'Logistics',
        label: t('corporatePsychoDiagDepartmentLogistics'),
      },
      {
        value: 'Sales',
        label: t('corporatePsychoDiagDepartmentSales'),
      },
      {
        value: 'IT',
        label: t('corporatePsychoDiagDepartmentIT'),
      },
      {
        value: 'Customer Service',
        label: t('corporatePsychoDiagDepartmentCustomerService'),
      },
      {
        value: 'Legal',
        label: t('corporatePsychoDiagDepartmentLegal'),
      },
    ],

    genders: [
      {
        value: 'Female',
        label: t('corporatePsychoDiagGenderFemale'),
      },
      {
        value: 'Male',
        label: t('corporatePsychoDiagGenderMale'),
      },
    ],

    locations: [
      {
        value: 'Capital',
        label: t('corporatePsychoDiagLocationCapital'),
      },
      {
        value: 'District',
        label: t('corporatePsychoDiagLocationDistrict'),
      },
      {
        value: 'City',
        label: t('corporatePsychoDiagLocationCity'),
      },
      {
        value: 'Smalltown',
        label: t('corporatePsychoDiagLocationSmalltown'),
      },
      {
        value: 'Farm',
        label: t('corporatePsychoDiagLocationFarm'),
      },
    ],
  }

  return (
    <>
      <form onSubmit={onSubmit} ref={formRef}>
        <Grid container className={classes.demographicsPage}>
          <Grid item xs={12} className={classes.demographicsFillOnceContainer}>
            <Typography className={classes.demographicsFillOnceText}>
              {t('corporatePsychoDiagDemographicsFillOnce')}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.demographicsRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="department">
                {t('corporatePsychoDiagDepartment')}
              </InputLabel>
              <Select
                labelId="department"
                ref={departmentInputRef}
                {...departmentInputProps}
                value={watch('department')}
                defaultValue={''}
              >
                {menuItems.departments.map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.demographicsRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="gender">
                {t('corporatePsychoDiagGender')}
              </InputLabel>
              <Select
                labelId="gender"
                ref={genderInputRef}
                {...genderInputProps}
                value={watch('gender')}
                defaultValue={''}
              >
                {menuItems.genders.map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.demographicsRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="age">{t('corporatePsychoDiagAge')}</InputLabel>
              <Input
                id=""
                value={watch('age')}
                ref={ageInputRef}
                {...ageInputProps}
                type="number"
                defaultValue={''}
              />
              <FormHelperText>
                {t('corporatePsychoDiagAgeHelpText')}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.demographicsRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="location">
                {t('corporatePsychoDiagLocation')}
              </InputLabel>
              <Select
                labelId="location"
                ref={locationInputRef}
                {...locationInputProps}
                value={watch('location')}
                defaultValue={''}
              >
                {menuItems.locations.map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} className={classes.demographicsRow}>
            <FormControl className={classes.formControl}>
              <InputLabel id="education">
                {t('corporatePsychoDiagEducation')}
              </InputLabel>
              <Select
                labelId="education"
                ref={educationInputRef}
                {...educationInputProps}
                value={watch('education')}
                defaultValue={''}
              >
                {menuItems.educations.map((d) => (
                  <MenuItem key={d.value} value={d.value}>
                    {d.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.privacyInfoText}>
              {t('corporatePsychoDiagPrivacyInfo')}
            </Typography>
          </Grid>

          {error && (
            <Grid item xs={12} className={classes.errorContainer}>
              <Typography className={classes.errorText}>
                {t('corporatePsychoDiagDemographicsMissingValues')}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} className={classes.saveDemographicsButton}>
            <GradientButton type="submit">
              {t('corporatePsychoDiagSaveDemographicsButton')}
            </GradientButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

Demographics.displayName = 'Demographics'

export default Demographics
export { Demographics }
