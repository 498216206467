/*
    Copyright © DokiApp, 2021
*/

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  makeStyles,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import {
  GetStarted,
  CheckPermissions,
  CheckVideo,
  CheckAudio,
  CheckBrowser,
  CheckConnectivity,
} from './steps'
import { useStoreState } from '../../store/store.hooks'
import { useRelativeNavigate } from '../../hooks/useRelativeNavigate'
import { Heading } from '../../components/Text'
import { NavigateBack } from '../../components/Nav/NavigateBack'
import { WhiteButton } from '../../components/Button/WhiteButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  activeIcon: {},
  completedIcon: {},
  footerButton: {
    width: 400,
    boxShadow: ' 0 10px 20px 0 rgba(100,115,175,0.4)',
    border: '2px solid #6473AF',
    [theme.breakpoints.down('xs')]: {
      width: 280,
    },
  },
  icon: {
    color: '#b64888',
    '&$activeIcon': {
      color: '#b64888',
    },
    '&$completedIcon': {
      color: '#b64888',
    },
  },
  pageFooter: {
    height: '150px',
    borderRadius: ' 0 0 15px 15px;',
    background: 'linear-gradient(180deg, #EFF9FF 0%, rgba(239,249,255,0) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  pageHeader: {
    backgroundColor: '#fff',
    borderRadius: '15px',
  },
}))

const TwilioDiagnostics = (
  {
    /* Props come here */
  }
) => {
  const classes = useStyles()
  const { t } = useTranslation('twilio-diagnostics')

  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState([])
  const [canMoveForward, setCanMoveForward] = useState(false)
  const { profileContents } = useStoreState((store) => store.profile)
  const { relativeNavigate } = useRelativeNavigate()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSupportedBrowser = () => {
    sessionStorage.setItem('hasTestedAudioAndVideoDevices', 'true')
    setCanMoveForward(true)
  }

  const handleUnsupportedBrowser = () => {
    setCanMoveForward(false)
  }

  const handleCompleteDiagnostics = () => {
    if (canMoveForward) {
      if (profileContents?.userTypeId === 1) {
        relativeNavigate('/dashboard')
      } else {
        relativeNavigate('/payment')
      }
    }
  }

  useEffect(() => {
    const applicableSteps = [
      {
        id: 'get-started',
        label: t('getStartedSectionTitle'),
        component: <GetStarted onNext={handleNext} />,
      },
      {
        id: 'check-permissions',
        label: t('checkPermissionsSectionTitle'),
        component: <CheckPermissions onNext={handleNext} />,
      },
      {
        id: 'check-video',
        label: t('checkVideoSectionTitle'),
        component: <CheckVideo onNext={handleNext} />,
      },
      {
        id: 'check-audio',
        label: t('checkAudioSectionTitle'),
        component: <CheckAudio onNext={handleNext} />,
      },
      {
        id: 'check-connectivity',
        label: t('checkConnectivitySectionTitle'),
        component: <CheckConnectivity onNext={handleNext} />,
      },
      {
        id: 'check-browser',
        label: t('checkBrowserSectionTitle'),
        component: (
          <CheckBrowser
            onSupportedBrowser={handleSupportedBrowser}
            onUnsupportedBrowser={handleUnsupportedBrowser}
          />
        ),
      },
    ].filter((step) => {
      if (
        step.id === 'check-permissions' &&
        sessionStorage.getItem('hasTestedAudioAndVideoDevices') === 'true'
      ) {
        return false
      }

      return true
    })

    setSteps(applicableSteps)
  }, [])

  return (
    <Grid container className={classes.pageHeader}>
      <NavigateBack />
      <Grid item xs={12} className={classes.complaintSumContainer}>
        <Heading>{t('pageHeader')}</Heading>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.id}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.icon,
                    active: classes.activeIcon,
                    completed: classes.completedIcon,
                  },
                }}
              >
                {step.label}
              </StepLabel>
              <StepContent>{step.component}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid xs={12} container className={classes.pageFooter}>
        <WhiteButton
          onClick={handleCompleteDiagnostics}
          className={classes.footerButton}
          disabled={!canMoveForward}
        >
          {t('nextViewButtonText')}
          <ArrowForwardIosIcon />
        </WhiteButton>
      </Grid>
    </Grid>
  )
}

TwilioDiagnostics.displayName = 'TwilioDiagnostics'

TwilioDiagnostics.propTypes = {}

TwilioDiagnostics.defaultProps = {}

export default TwilioDiagnostics
export { TwilioDiagnostics }
